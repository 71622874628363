import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateRangeProps { }

const DateRange: React.FC<DateRangeProps> = () => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDatePlaceholder, setStartDatePlaceholder] = useState<string>('Start Date');
    const [endDatePlaceholder, setEndDatePlaceholder] = useState<string>('End Date');

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        setStartDatePlaceholder(date ? '' : 'Start Date');
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setEndDatePlaceholder(date ? '' : 'End Date');
    };

    return (
        <div className='d-flex gap-2 range_main_div'>
            <DatePicker
                selected={startDate}
                className="range_picker"
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                selectsStart
                placeholderText={startDatePlaceholder}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
            />
            <DatePicker
                selected={endDate}
                className="range_picker"
                onChange={handleEndDateChange}
                selectsEnd
                placeholderText={endDatePlaceholder}
                dateFormat="dd/MM/yyyy"
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
            />
        </div>
    );
};

export default DateRange;
