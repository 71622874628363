import React from 'react'
import Banner from './BannerSection/Banner';
import SearchBox from './SearchBox/SearchBox';
import PropertiesFilters from './PropertiesFilters/PropertiesFilters';

const Properties = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    return (
        <div>
            <Banner/>
            <SearchBox/>
            <PropertiesFilters />
        </div>
    )
}

export default Properties;