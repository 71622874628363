import React, { CSSProperties, ChangeEvent } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    searchInput: {
        fontFamily: 'Lato !important',
        color: '#808282 !important',
    },
}));

interface SearchbarProps {
    handleSearch: (searchTerm: string) => void;
    placeholder?: string;
    inputColor?: string;
    buttonColor?: string;
    customStyles?: CSSProperties;
}

const Searchbar: React.FC<SearchbarProps> = ({ handleSearch, placeholder, inputColor, buttonColor, customStyles }) => {
    const classes = useStyles();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;
        handleSearch(searchTerm);
    };

    return (
        <Paper
            component="form"
            sx={{ display: 'flex', alignItems: 'center', boxShadow: 'none', ...customStyles }}
        >
            <IconButton type="button" sx={{ color: buttonColor }} aria-label="search">
                <SearchIcon sx={{ width: '24px', height: '24px' }} />
            </IconButton>
            <InputBase
                classes={{ root: classes.searchInput }}
                sx={{ ml: 1, flex: 1, fontSize: '14px', fontWeight: '500' }}
                placeholder={placeholder || 'Search'}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleChange}
            />
        </Paper>
    );
};

export default Searchbar;
