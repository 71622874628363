// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/lato);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&family=Inter&family=Lato&family=Montserrat:wght@200;300;400;600&family=Outfit:wght@500&family=Poppins:wght@500&family=Roboto:wght@700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
	font-size: 18px;
	line-height: 24px;
	color: #010404;
	font-family: 'Lato', sans-serif !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAIA;CACC,eAAe;CACf,iBAAiB;CACjB,cAAc;CACd,0CAA0C;AAC3C","sourcesContent":["@import url('https://fonts.cdnfonts.com/css/lato');\n@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@500&family=Inter&family=Lato&family=Montserrat:wght@200;300;400;600&family=Outfit:wght@500&family=Poppins:wght@500&family=Roboto:wght@700&display=swap');\n\n\nbody{\n\tfont-size: 18px;\n\tline-height: 24px;\n\tcolor: #010404;\n\tfont-family: 'Lato', sans-serif !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
