import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';

export default function AutoSearch() {
    const cities = [
        'India',
        'Indonesia',
        'Canada',
        'United States',
        'United Kingdom',
        'Saudi Arabia',
        'South Africa',
        'Australia',
        'Germany',
        'New York',
        'Tokyo',
    ];

    return (
        <Stack spacing={2} sx={{ width: 200 }}>
            <Autocomplete
                freeSolo
                disableClearable
                options={cities.map((option) => option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className="custom-input"

                        sx={{
                            '& fieldset': {
                                border: 'none',
                            },
                            '& input': {
                                padding: '0px !important',
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            placeholder: 'Search city or address',
                            startAdornment: (
                                <>
                                    <SearchIcon sx={{ color: 'rgba(128, 130, 130, 0.7)' }} />
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                        }}
                    />
                )}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                    <li {...props} style={{ fontFamily: 'Lato', fontWeight: 'normal', color: "#808282" }}>
                        {option}
                    </li>
                )
                }
            />
        </Stack >
    );
}
