import React from "react";
import { Image, FormControl } from "react-bootstrap";
import send from "../../../../../images/customer/send.png";

const ChatSend: React.FC = () => {
  return (
    <div className="input-group chat_footer position-relative">
      <FormControl
        type="text"
        className="form-control"
        placeholder="Type your message here"
      />
      {/* add some style from manage icon img */}
      <Image src={send} alt="img" style={{ zIndex: "9999" }}  />
    </div>
  );
};

export default ChatSend;
