import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "../styles/landload-common.css";
import "../styles/responsive.css";
import Header from "./Header";
import { Context } from "../../context/Context";

type ChildrenContainer = Pick<JSX.IntrinsicElements["div"], "children">;

function Layout({ children }: ChildrenContainer) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

  const handleToggleClick = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  return (
    <Context.Provider value={{ sidebarCollapsed, handleToggleClick }}>
      <div className="page-flex">

        <Sidebar />
        <div className="main-wrapper">
          <Header />
          <main>{children}</main>
        </div>

      </div>
    </Context.Provider>
  );
}

export default Layout;
