import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Image,
  Form,
} from "react-bootstrap";
import card_user from "../../../../images/card-user.png";
import card_bed from "../../../../images/card-bed.png";
import dotimg from "../../../../images/dotimg.png";
import wifi from "../../../../images/wifi.png";
import card_location from "../../../../images/card-location.png";
import icon_p from "../../../../images/icon-p.png";
import Rating from "../../../../common/rating/Rating";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import PaginationComponent from "../../../../common/pagination/PaginationComponent";
import { propertiesCardData } from "../../../../common/userCard/UserCard";
import Button from "../../../../common/Button/Button";
import { useNavigate } from "react-router-dom";

const PropertiesCard = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<string>("firstRow"); // Initial state for the first set of cards

  const handleChnage = () => {
    navigate("/properties-details");
  };

  return (
    <Container>
      <div className="filter_content">
        <div className="filter_header pb-3 pb-lg-5">
          <h5>Showing 1 - 10 out of 300</h5>
          <div className="filter_header_btns">
            <div className="sort_drop">
              <Form.Label>Sort By:</Form.Label>
              <Form.Select
                className="form-select-dropdown select-dropdown-field"
                aria-label="Default select example"
              >
                <option selected>Newest</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>

            <Button
              type="button"
              variant="variant"
              className={`btn-common-icon ${
                selectedRow === "firstRow" ? "activeButton" : ""
              }`}
              onClick={() => setSelectedRow("firstRow")}
            >
              <MenuRoundedIcon style={{ width: "24px", height: "24px" }} />
            </Button>

            <Button
              type="button"
              variant="variant"
              className={`btn-common-icon ${
                selectedRow === "secondRow" ? "activeButton" : ""
              }`}
              onClick={() => setSelectedRow("secondRow")}
            >
              <GridViewRoundedIcon style={{ width: "24px", height: "24px" }} />
            </Button>
          </div>
        </div>

        {selectedRow === "firstRow" && (
          <Row className="row-card row-cols-lg-1 g-3 mb-3">
            {propertiesCardData.map((item, index) => (
              <Col>
                <Card className="home_card">
                  <div className="image_body">
                    <div className="pic_item position-relative">
                      <Carousel>
                        <Carousel.Item interval={1000}>
                          <Image
                            className="img-carousel"
                            src={item.img}
                            alt="img"
                          />
                        </Carousel.Item>
                        <Carousel.Item interval={1000}>
                          <Image
                            className="img-carousel"
                            src={item.img02}
                            alt="img"
                          />
                        </Carousel.Item>
                        <Carousel.Item interval={1000}>
                          <Image
                            className="img-carousel"
                            src={item.img03}
                            alt="img"
                          />
                        </Carousel.Item>
                      </Carousel>
                      <div className="image_header d-flex justify-content-between">
                        <span>
                          <Rating />
                        </span>
                        <div className="quantity_box d-flex justify-content-between">
                          <div className="box-quant">
                            <Image src={card_user} alt="" className="location_size"/>
                            <span className="span-tag-card">
                              {item.cardUser}
                            </span>
                          </div>
                          <Col xs="auto" className="media">
                            <img src={dotimg} alt="img" />
                          </Col>

                          <div className="box-quant">
                            <Image src={card_bed} alt="" className="card_bed_six" style={{height:"12px",width:"20px"}} />
                            <span className="span-tag-card">
                              {item.badNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Card.Body>
                    <div className="badge_btn">
                      <p>{item.cardtitle}</p>
                    </div>
                    <div className="card_heading_block">
                      <h2 className="h2_div_property_first">
                        {item.cardHeading}
                      </h2>
                      <span className="h2_div_property_first">
                        $ {item.cardRate}
                      </span>
                    </div>
                    <div className="card_location">
                      <span>
                        <Image src={card_location} alt="" />
                      </span>
                      <p className="p-tag-cards">{item.locationApt}</p>
                    </div>
                    <div className="card_desc">
                      <p>{item.discription}</p>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="icons_technical d-flex gap-2">
                          <span>
                            <Image src={wifi} alt="" />
                          </span>
                          <span>
                            <Image src={icon_p} alt="" />
                          </span>
                        </div>
                        <div className="card_btn">
                          <Button
                            type="button"
                            variant="primary"
                            className="btn btn_primary btn-more-info border-0"
                            onClick={handleChnage}
                          >
                            More Info
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>

      {/* box div */}
      {selectedRow === "secondRow" && (
        <div className="row-card-booking row row-cols-12 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 g-3">
          {propertiesCardData.map((item, index) => (
            <Col>
              <div key={index} className="card home_card ">
                <div className="image_body ">
                  <div className="pic_item position-relative">
                    <Carousel>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img}
                          alt="img"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img02}
                          alt="img"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img03}
                          alt="img"
                        />
                      </Carousel.Item>
                    </Carousel>

                    <div className="image_header d-flex justify-content-between">
                      <span>
                        <Rating />
                      </span>
                      <div className="quantity_box d-flex justify-content-between">
                        <div className="box-quant">
                          <Image src={card_user} alt="img" />
                          <span>{item.cardUser}</span>
                        </div>
                        <Col xs="auto" className="media">
                          <a href="/">
                            <svg
                              className="svgStyle"
                              width="9"
                              height="8"
                              viewBox="0 0 9 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.93 4.06C0.93 3.52 1.03 3.01333 1.23 2.54C1.43667 2.06667 1.71333 1.65333 2.06 1.3C2.41333 0.946666 2.82333 0.67 3.29 0.469999C3.75667 0.263333 4.25667 0.16 4.79 0.16C5.32333 0.16 5.82667 0.263333 6.3 0.469999C6.77333 0.67 7.18333 0.946666 7.53 1.3C7.88333 1.65333 8.16 2.06667 8.36 2.54C8.56667 3.01333 8.67 3.52 8.67 4.06C8.67 4.59333 8.56667 5.09333 8.36 5.56C8.16 6.02667 7.88333 6.43667 7.53 6.79C7.18333 7.14333 6.77333 7.42333 6.3 7.63C5.82667 7.83 5.32333 7.93 4.79 7.93C4.25667 7.93 3.75667 7.83 3.29 7.63C2.82333 7.42333 2.41333 7.14333 2.06 6.79C1.71333 6.43667 1.43667 6.02667 1.23 5.56C1.03 5.09333 0.93 4.59333 0.93 4.06Z"
                                fill="#808282"
                              />
                            </svg>
                          </a>
                        </Col>

                        <div className="box-quant">
                          <Image src={card_bed} alt="img" />
                          <span>{item.badNumber}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="badge_btn div_bottom">
                    <p>{item.cardtitle}</p>
                  </div>
                  <div className="card_heading_block div_bottom">
                    <h2 className="h2_div_property">{item.cardHeading}</h2>
                    <span className="h2_div_property">${item.cardRate}</span>
                  </div>

                  <div className="card_location">
                    <span>
                      <Image
                        src={card_location}
                        alt="img"
                        className="card_location_a border-bottom-0"
                      />
                    </span>
                    <p className="p-tag-cards">{item.locationApt}</p>
                  </div>

                  <div className="card_desc">
                    <p>{item.discription}</p>
                    <div className="card_bottom_icon">
                      <span>
                        <Image src={wifi} alt="img" />
                      </span>
                      <span>
                        <Image src={icon_p} alt="img" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card_btn">
                  <Button
                    type="button"
                    variant="variant"
                    className="btn btn_primary btn-common"
                    onClick={() => console.log("Button clicked")}
                  >
                    More Info
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </div>
      )}
      <div className="filter_pagination py-3 py-lg-5">
        <nav aria-label="...">
          <PaginationComponent />
        </nav>
      </div>
    </Container>
  );
};

export default PropertiesCard;
