import React, { useState } from "react";
import { Modal, Form, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import Datepicker from "../../common/datepicker/Datepicker";

interface GenerateVistorRequestModalProps {
  show: boolean;
  onHide: () => void;
}
const GenerateVistorRequestModal: React.FC<GenerateVistorRequestModalProps> = ({
  show,
  onHide,
}) => {
  const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
  const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(null);

  const handleClose = () => {
    onHide();
  };

  const handleCheckIn = (date: Date | null) => {
    setSelectedChcekInDate(date);
  };

  const handleCheckOut = (date: Date | null) => {
    setSelectedChcekOutDate(date);
  }
  return (
    <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
      <div className="close-icon">
        <Image
          src={close}
          onClick={handleClose}
          className="img-close"
          alt="img"
        />
      </div>
      <Modal.Header className="border-bottom-0">
        <Modal.Title className="mx-auto">
          <span className="header-modal">Generate Visitor Request</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="agent_modal_body">
        <Form>
          <div className="row">
            <Form.Group className="col-md-6 mb-3" controlId="title">
              <Form.Label className="label-modal">
                Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="John Doe"
                className="form-input-modal form-height"
              />
            </Form.Group>

            <Form.Group className="col-md-6 mb-3" controlId="title">
              <Form.Label className="label-modal">
                Type<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select aria-label="Default select example" className="form-input-modal form-height form-control">
                <option>select</option>
                <option value="1">One-Time</option>
                <option value="2">Two-Time</option>
                <option value="3">Three-Time</option>
              </Form.Select>

            </Form.Group>

            <Form.Group className="col-md-12 mb-3" controlId="title">
              <Form.Label className="label-modal">
                Type Code<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="OP948"
                className="form-input-modal form-height"
              />
            </Form.Group>
{/* remove facility manager column */}
            {/* <Form.Group className="col-md-6 mb-3" controlId="priority">
              <Form.Label className="label-modal">
                Facility Manager<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select aria-label="Default select example" className="form-input-modal form-height form-control">
                <option>John Doe</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group> */}

            <Form.Group className="col-md-6 mb-3" controlId="priority">
              <Form.Label className="label-modal">
                Check-In<span className="text-danger">*</span>
              </Form.Label>
              <Datepicker
                className="form-input-modal form-height form-control"
                selected={selectedChcekInDate}
                onChange={handleCheckIn}
                placeholderText="06 Jun, 2024"
              />
            </Form.Group>

            <Form.Group className="col-md-6 mb-3" controlId="priority">
              <Form.Label className="label-modal">
                Check-Out<span className="text-danger">*</span>
              </Form.Label>
              <Datepicker
                className="form-input-modal form-height form-control"
                selected={selectedChcekOutDate}
                onChange={handleCheckOut}
                placeholderText="06 Jun, 2024"
              />
            </Form.Group>

            <Form.Group className="col-md-12 mb-3" controlId="description">
              <Form.Label className="label-modal">
                Location<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="123 Main St, Anytown, USA"
                className="form-input-modal form-height"
              />
            </Form.Group>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="modal-delete border-top-0 justify-content-center">
        <Button
          variant="variant"
          className="modal_cancel_btn"
          type="submit"
          onClick={() => console.log("click")}
        >
          Generate
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateVistorRequestModal;
