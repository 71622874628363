import React from "react";
import { Container, Card, Row, Col, Carousel, Image } from "react-bootstrap";
import Test1 from "../../../../images/test-1.png";
import carduser from "../../../../images/card-user.png";
import cardbed from "../../../../images/card-bed.png";
import location from "../../../../images/card-location.png";
import wifi from "../../../../images/wifi.png";
import iconp from "../../../../images/icon-p.png";
import Rating from "../../../../common/rating/Rating";
import dotimg from "../../../../images/dotimg.png";
import Button from "../../../../common/Button/Button";

const cardData = {
  title: "2 Bedroom apartment",
  price: "$150",
  location: "Leki - Apartment",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.",
};

const HomeForYou: React.FC = () => {
  const generateCards = (count: number) => {
    const cards = [];
    for (let i = 0; i < count; i++) {
      cards.push(
        <Col>
          <Card className="home_card">
            <div className="image_body">
              <div className="pic_item position-relative">
                <Carousel>
                  <Carousel.Item interval={1000}>
                    <Image className="mx-auto w-100" src={Test1} alt="img" />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <Image className="mx-auto w-100" src={Test1} alt="img" />
                  </Carousel.Item>
                  <Carousel.Item interval={1000}>
                    <Image className="mx-auto w-100" src={Test1} alt="img" />
                  </Carousel.Item>
                </Carousel>
                <div className="image_header d-flex justify-content-between">
                  <span>
                    <Rating />
                  </span>
                  <div className="quantity_box d-flex justify-content-between dot_center">
                    <div className="box-quant">
                      <img
                        style={{ width: "16px", height: "16px" }}
                        src={carduser}
                        alt="Users"
                      />
                      <span className="img_color">10</span>
                    </div>
                    <Col xs="auto" className="media">
                      <img src={dotimg} alt="img" />
                    </Col>
                    {/* add style manage cardbed img dimension */}
                    <div className="box-quant">
                      <img
                        style={{ height: "12px", width: "20px" }}
                        src={cardbed}
                        alt="Beds"
                      />
                      <span>5</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              <div className="badge_btn">
                <p>Short Let</p>
              </div>
              <div className="card_heading_block">
                <h2>{cardData.title}</h2>
                <span>{cardData.price}</span>
              </div>
              <div className="card_location">
                <span >
                  <img src={location} alt="Location" className="location_size" />
                </span>
                <p className="p-tag-cards">{cardData.location}</p>
              </div>
              <div className="card_desc">
                <p>{cardData.description}</p>
                <div className="card_bottom_icon">
                  <span>
                    <img src={wifi} alt="Wi-Fi" />
                  </span>
                  <span>
                    <img src={iconp} alt="Icon" />
                  </span>
                </div>
              </div>
            </Card.Body>
            <div className="card_btn">
              <button className="w-100 btn btn_primary btn-common">
                More Info
              </button>
            </div>
          </Card>
        </Col>
      );
    }
    return cards;
  };

  return (
    <section className="home_sec py-3 py-lg-5">
      <Container>
        <div className="home_heading text-center py-3 py-lg-4">
          <h2 className="h2-tag-text">
            Find the Right <span>Home for you!</span>
          </h2>
          <p>Indulge in our verified, high-quality listings</p>
        </div>
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
          {generateCards(12)}
        </Row>
        <div className="view_all w-100 text-center py-3 py-lg-5 d-flex justify-content-center align-content-center">
          <Button
            type="button"
            variant="primary"
            className="btn_primary btn_view border-0"
            onClick={() => console.log("Button clicked")}
          >
            View All
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default HomeForYou;
