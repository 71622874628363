import React from "react";
import { Container } from "react-bootstrap";
import Support from "../../../../images/support.png";
import Bank from "../../../../images/bank.png";
import verify from "../../../../images/verify.png";
import quality from "../../../../images/quality.png";

interface Reason {
  icon: string;
  text: string;
  classText?: string;
  textUnique?: string;
}

const reasons: Reason[] = [
  {
    icon: Support,
    text: "Customer Support",
    textUnique: "24 / 7",
    classText: "classUnique",
  },
  {
    icon: Bank,
    textUnique: "Accept",
    text: "Local Cards And Bank Transfers",
    classText: "classUnique",
  },
  {
    icon: verify,
    textUnique: "Verified",
    text: "Listings",
    classText: "classUnique",
  },
  {
    icon: quality,
    textUnique: "Quality",
    text: "Homes",
    classText: "classUnique",
  },
];

const WhyUsSection: React.FC = () => {
  return (
    <Container>
      <section className="why_us py-3 my-md-2 my-lg-5">
        <div className="container">
          <div className="why_us_wrap">
            <h2>
              Why <span>Us?</span>
            </h2>
            <div className="row row-cols-12 row-cols-md-2 row-cols-lg-4 g-4 why_us_card">
              {reasons.map((reason, index) => (
                <div className="col" key={index}>
                  <div className="card card_us">
                    <div className="icon_sec card_one">
                      {reason.icon && (
                        <img
                          src={reason.icon}
                          alt={`Icon for reason ${index + 1}`}
                        />
                      )}
                    </div>
                    <p className="mb-0">
                      {index === 0 ? (
                        <>
                          {reason.text}
                          <span className={reason.classText}>
                            {reason.textUnique}
                          </span>
                        </>
                      ) : (
                        <>
                          <span className={reason.classText}>
                            {reason.textUnique}
                          </span>
                          {reason.text}
                        </>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default WhyUsSection;
