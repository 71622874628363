import React, { useState } from 'react'
import Layout from '../../../layout/layout'
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import { images } from '../../../../../common/constant/constant';
import added from "../../../../../images/customer/added.png";
import add from "../../../../../images/landload/add.png";
import addagent from "../../../../../images/landload/add-agent.png";
import Button from '../../../../../common/Button/Button';
import boost from "../../../../../images/boost.png";
import DeleteModal from '../../../../../modal/deleteModal/DeleteModal';
import BootsModal from '../../../../../modal/bootsModal/BootsModal';
import left from "../../../../../images/customer/left.png";
import { useNavigate } from 'react-router-dom';
import { TEXT_BLACK } from '../../../../../styles/colors';


const LandloadPropertyDetails: React.FC = (setIsEdit) => {
    const editValue = localStorage.getItem('edit');
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showBootsModal, setShowBootsModal] = useState<boolean>(false);
    const [inputFields, setInputFields] = useState<string[]>(['']); // State to store input fields


    // Function to add a new input field
    const addInputField = () => {
        setInputFields([...inputFields, '']);
    };
    return (
        <Layout>
            <main className="main users">
                <Container>
                    <div className='mb-4'>
                        <Image onClick={() => navigate('/landlord-manage-properties')} className='img-click' src={left} alt="img" />
                    </div>
                    <div className="add_property_sec pb-3">
                        <div className="property_wrap">
                            <div className='landload_property_details'>
                                <div>
                                    <Button
                                        className="btn-boots-details"
                                        type="button"
                                        onClick={() => setShowBootsModal(true)}
                                    >
                                        <Image src={boost} alt="Boost" /> Boost
                                    </Button>
                                </div>
                                <div>
                                    <h3 className='text-center text-add-text mb-5' style={{ color: TEXT_BLACK }}>{editValue == 'yes' ? "Property Details" : "Edit Property Details"}</h3>
                                </div>
                                <div className='d-flex gap-3 landload_button_group'>
                                    <Button
                                        className="btn-delete-details"
                                        type="button"
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        Delete
                                    </Button>

                                    <Button
                                        className="btn-save-details"
                                        type="button"
                                    >
                                        Save Changes
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col lg={3}>
                                    <section className="img__container">
                                        <h4>Upload Pictures</h4>
                                        <div className="upload_sec py-3">

                                            <div className="upload_sec py-3">
                                                {images.map((item, index) => (
                                                    <div key={index} className="upload_images">
                                                        <Image src={item.img} alt="img" />
                                                    </div>
                                                ))}
                                                <div className="property_upload">
                                                    <input type="file" id="myfile" name="myfile" accept="image/*" className="upload" />
                                                    <label htmlFor="myfile" className="upload--btn">Add<br /><Image src={added} /></label>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </Col>
                                <Col lg={9}>
                                    <div className="addproperty_form">
                                        <Form>
                                            <Row>
                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Title<span>*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Title" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Price<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Category<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Taxes<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Service Charge (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Other Fees (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>360 Virtual Link<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="https://" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Is Featured<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Features<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Features" />
                                                </Form.Group>

                                                {inputFields.map((_, index) => (
                                                    <React.Fragment key={index}>
                                                        <Form.Group as={Col} md={5} className='mb-3'>
                                                            <Form.Label>Amenity<span>*</span></Form.Label>
                                                            <h6 className="h6_span_text">Bathroom(s)</h6>
                                                            <Form.Control as="select">
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </Form.Control>
                                                        </Form.Group>

                                                        <Form.Group as={Col} md={6} className='mb-3'>
                                                            <Form.Label>Quantity<span>*</span></Form.Label>
                                                            <h6 className="h6_span_text">x2</h6>
                                                            <Form.Control type="email" placeholder="Quantity" />
                                                        </Form.Group>
                                                    </React.Fragment>
                                                ))}



                                                <Form.Group onClick={addInputField} as={Col} md={1} className='mb-3 p-0 m-auto'>
                                                    <Image className="plus_icon" src={add} alt="img" />
                                                </Form.Group>



                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Complete Address<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Full Address" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Country<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option value="1">India</option>
                                                        <option value="2">Japan</option>
                                                        <option value="3">Nepal</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>State<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="State" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>City<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="City" />
                                                </Form.Group>

                                                <div>
                                                    <div className="agent_add_block">
                                                        <h6 className='text_agent_label'>Assign to a Registered Agent</h6>
                                                        <a type="button" className="btn_agent_landload">
                                                            <Image src={addagent} alt="img" /> Add Agent
                                                        </a>
                                                    </div>
                                                </div>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </main>
            {showDeleteModal && (
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                />
            )}

            {showBootsModal && (
                <BootsModal
                    show={showBootsModal}
                    onHide={() => setShowBootsModal(false)}
                />
            )}
        </Layout>
    )
}

export default LandloadPropertyDetails;