import React from "react";
import hero from "../../../../images/hero-pic.png";
import SearchBoxes from "../../Properties/SearchBox/SearchBoxes";


const BannerSection: React.FC = () => {
  return (
    <section className="banner-section position-relative d-flex">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-12 d-lg-none p-0">
            <img src={hero} alt="" className="banner-img" />
          </div>
          <div className="col-lg-6">
            <div className="caption">
              <h5>Discover Your Dream Home</h5>
              <h1 className="bannertext">
                Find the perfect home
                <br />
                <span> suited to you.</span>
              </h1>
              <p>
                Discover your dream home with our comprehensive listings and
                expert guidance. From cozy apartments to luxurious villas, we've
                got you covered. Start your real estate journey with us today.
              </p>
              <div className="line"></div>
            </div>

            <div className="guest_serch_block">
              <div className="tab-content" id="pills-tabContent">
                <SearchBoxes />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none p-0 mb-2"></div>
        </div>
      </div>

      <div className="pattern"></div>
      <img
        src={hero}
        className="banner-img position-absolute d-none d-lg-block"
        alt="img"
      />
    </section>
  );
};

export default BannerSection;
