import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatSidebar from "./chatSidebar/ChatSidebar";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatContent from "./chatContent/ChatContent";
import ChatSend from "./chatSend/ChatSend";
import Layout from "../layout/layout";

const ChatAgent: React.FC = () => {
  return (
    <Layout>
      <main className="users tabel_page">
        <Container>
          <section className="agent_chat">
            <h5 className="Chat_head">Chat </h5>
            <Row className="overflow-hidden">
              <Col lg={3}>
                <ChatSidebar />
              </Col>

              <Col lg={9}>
                <div className="agent_chatbot">
                  <Row>
                    <Col>
                      <ChatHeader />
                      <ChatContent />
                    </Col>
                  </Row>

                  <div className="flex-grow-0 py-3 px-4">
                    <ChatSend />
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
};

export default ChatAgent;
