import React, { useState, useEffect } from "react";
import { Image, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/login/white-logo.png";
import email from "../../../images/login/email-login.png";
import password from "../../../images/login/password-login.png";
import line from "../../../images/login/or-pic.png";
import apple from "../../../images/login/apple.png";
import facebook from "../../../images/login/facebook.png";
import google from "../../../images/login/google.png";
import close from "../../../images/customer/hide.svg";
import open from "../../../images/customer/open.svg";
import Button from "../../../common/Button/Button";
import { accountDetails, loginDiscription } from "../../../common/constant/constant";

interface AccountDetail {
  title: string;
  discription: string;
  image: string;
  link: string;
}

interface LoginDescription {
  title: string;
  discription: string;
}

const FacilityLogin: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<string>('');

  useEffect(() => {
    // Retrieve the active link from storage when component mounts
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    // Store the active link in localStorage
    localStorage.setItem("activeLink", link);
  };

  const handleLogin = () => {
    navigate(activeLink); // Redirect to the active link
  };
  return (
    <section className="owner_facility_login">
      <div className="left-side min-vh-100">
        <div className="left_content">
          <div className="owner_logo py-3">
            <a href="/login">
              <Image src={logo} alt="img" />
            </a>
          </div>
          {loginDiscription?.map((item: LoginDescription, index: number) => (
            <div key={index} className="choose_account py-3">
              <label className="form-label">{item.title}</label>
              <p>{item.discription}</p>
            </div>
          ))}

          {accountDetails?.map((item: AccountDetail, index: number) => (
            <div key={index} className="account_choose_list py-3" onClick={() => handleLinkClick(item.link)} >
              <div className={`item_box ${activeLink === item.link ? "active" : ""}`}>
                <div className="name_account d-flex align-items-self-start">
                  <span>
                    <p title={item.title} >
                      <Image src={item.image} alt="img" />
                    </p>
                  </span>
                  <label className="form-label">
                    <p title={item.title} className="white">
                      {item.title}
                    </p>
                  </label>
                </div>
                <p>{item.discription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="right-side">
        <div className="login_form newlogin">
          <h3>Login to your account</h3>
          <Form>
            <Form.Group
              controlId="formBasicEmail"
              className="my-4 owner_input position-relative"
            >
              <Form.Control type="text" placeholder="Email address" />
              <span>
                <Image src={email} alt="login" />
              </span>
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="my-4 owner_input position-relative"
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Password" />
              <span>
                <Image src={password} alt="password" />
              </span>
              <Image
                src={showPassword ? open : close}
                className='show-password'
                onClick={() => setShowPassword(!showPassword)}
                alt="password"
              />
            </Form.Group>

            <Form.Group className="my-3 d-flex justify-content-between align-items-center">
              <Form.Check
                type="checkbox"
                label="Keep me logged in"
                id="gridCheck"
              />
              <a className="forgot_login" href="/forgot-password">
                Forgot Password
              </a>
            </Form.Group>

            <Button
              type="button" // Ensure it's type button
              className="w-100 btn btn-primary"
              onClick={handleLogin} // Call handleLogin function
            >
              Login
            </Button>
          </Form>

          <div className="agent_or py-3">
            <Image src={line} alt="img" />
            <div className="agent_login_option">
              <Image src={apple} alt="img" />
              <Image src={facebook} alt="img" />
              <Image src={google} alt="img" />
            </div>
          </div>
        </div>
        <p className="register_login">
          Don’t have an account? <a href="/facility-signup">Register</a>
        </p>
      </div>
    </section>
  );
};

export default FacilityLogin;
