import React from "react";
import { Modal, Image,Form } from "react-bootstrap";
import close from "../../images/customer/close.png";
import home from "../../images/customer/home-table.png";
import "../modal.css";
import Button from "../../common/Button/Button";

interface WorkRequestModalProps {
  show: boolean;
  onHide: () => void;
}
const WorkRequestModal: React.FC<WorkRequestModalProps> = ({
  show,
  onHide,
}) => {
  const handleClose = () => {
    onHide();
  };

  return (
    <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
      <div className="close-icon">
        <Image
          src={close}
          onClick={handleClose}
          className="img-close"
          alt="img"
        />
      </div>
      <Modal.Header className="border-bottom-0">
        <Modal.Title className="mx-auto">
          <span className="header-modal">Work Request</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="agent_modal_body">
        <div className="details__booking">
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Title</h6>
              <p className="booking-text-field m-0">Leaking toilet</p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Facility Manager</h6>
              <p className="booking-text-field m-0">Mason Sanchez</p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Priority</h6>
              <p className="booking-text-field m-0">High</p>
            </div>
          </div>
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Due Date</h6>
              <p className="booking-text-field m-0">28 Apr, 2023</p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Work Request No.</h6>
              <p className="booking-text-field m-0">67</p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Category</h6>
              <p className="booking-text-field m-0">Plumbing</p>
            </div>
          </div>

          <div className="row border-bottom py-3">
            <div className="col-lg-8 box-booking mb-2">
              <h6 className="booking-label">Description</h6>
              <p className="booking-text-field m-0">
                Leaking toilet in master bedroom
              </p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Status</h6>
              <Form.Select className=" booking-text-field m-0 border-0">
                <option className="booking-text-field m-0 border-0"> On Going</option>
                <option  className="booking-text-field m-0 border-0" value="1">One</option>
                <option className="booking-text-field m-0 border-0"  value="2">Two</option>
                <option className="booking-text-field m-0 border-0" value="3">Three</option>
              </Form.Select>
            </div>
          </div>

          <div className="row border-bottom py-3">
            <h6 className="booking-label">Gallery</h6>
            <div className="upload_sec py-3">
              <div className="upload_images">
                <Image src={home} alt="upload-1" />
              </div>
              <div className="upload_images">
                <Image src={home} alt="upload-2" />
              </div>
              <div className="upload_images">
                <Image src={home} alt="upload-3" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-delete border-top-0 justify-content-center">
        <Button
          variant="outline"
          className="modal_delete_btn_ff"
          type="submit"
          onClick={() => console.log("click")}
        >
          Delete
        </Button>
        <Button
          variant="variant"
          className="modal_cancel_btn_f save_changes"
          type="submit"
          onClick={() => console.log("click")}
        >
          Save changes
        </Button>
      
      </Modal.Footer>
    </Modal>
  );
};

export default WorkRequestModal;
