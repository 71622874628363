import { Modal, Image } from "react-bootstrap";
import "../modal.css";
import Button from "../../common/Button/Button";
import close from "../../images/customer/close.png";

interface BookingDetailsProps {
  show: boolean;
  onHide: () => void;
}
//  add new archive modal all archive button onClick function
const BookingArchiveDetails: React.FC<BookingDetailsProps> = ({ show, onHide }) => {
  const handleClose = () => {
    onHide();
  };

  return (
    <div>
      <Modal className="booking-details" show={show} onHide={handleClose}>
        <div className="close-icon">
          <Image
            src={close}
            onClick={handleClose}
            className="img-close"
            alt="img"
          />
        </div>

        <div className="modal-body agent_modal_body">
          <div className="u_sure text-center py-2">
            <h4> Archive Booking Details</h4>
          </div>
          <div className="details__booking">
            <div className="row border-bottom py-3">
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Username</h6>
                <p className="booking-text-field m-0">Admin</p>
              </div>
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Property</h6>
                <p className="booking-text-field m-0">The Oak Court</p>
              </div>
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Agent Code</h6>
                <p className="booking-text-field m-0">12345</p>
              </div>
            </div>

            <div className="row border-bottom py-3">
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Check In</h6>
                <p className="booking-text-field m-0">28 Apr, 2023</p>
              </div>
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Check Out</h6>
                <p className="booking-text-field m-0">05 May, 2023</p>
              </div>
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Status</h6>
                <p className="booking-text-field m-0">Pending</p>
              </div>
            </div>

            <div className="row border-bottom py-3">
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Actual Price</h6>
                <p className="booking-text-field m-0">₦ 30,000</p>
              </div>
              <div className="col-lg-4 box-booking">
                <h6 className="booking-label">Negotiable Price</h6>
                <p className="booking-text-field m-0">₦ 25,000</p>
              </div>
              <div className="col-lg-4 box-booking py-3">
                <Button
                  variant="variant"
                  className="btn_report_modal"
                  type="button"
                  onClick={() => console.log("click modal")}
                >
                  Archive Report
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookingArchiveDetails;
