import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import strip from "../../../../images/strip.png";

const StripHeader: React.FC = () => {
  return (
    <div>
      <div className="header-strip fixed-top" id="header-strip">
        <Container>
          <div className="wrap">
            <Row className="d-flex gap-3">
              <Col xs="auto" className="nav-link">
                <a href="/">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.3836 17.7518C21.6173 17.9863 21.7925 18.2725 21.8949 18.5873C21.9974 18.902 22.0243 19.2365 21.9735 19.5636C21.9227 19.8907 21.7955 20.2012 21.6023 20.47C21.4092 20.7388 21.1554 20.9583 20.8616 21.1108C19.1827 22.0082 17.2266 22.2375 15.3856 21.7528C10.4996 20.5228 3.47663 13.4998 2.24663 8.61384C1.76195 6.77289 1.99129 4.81676 2.88863 3.13784C3.04112 2.84404 3.26067 2.59029 3.52948 2.39712C3.79829 2.20396 4.10881 2.07681 4.4359 2.02598C4.76299 1.97515 5.09746 2.00206 5.41221 2.10454C5.72696 2.20702 6.01316 2.38218 6.24763 2.61584L8.33263 4.69984C8.6333 4.9975 8.83676 5.37925 8.9162 5.79482C8.99563 6.21039 8.94732 6.64027 8.77763 7.02784C8.59193 7.46268 8.32826 7.85988 7.99963 8.19984C5.61563 10.5838 13.4166 18.3848 15.7996 15.9998C16.1396 15.67 16.5372 15.4053 16.9726 15.2188C17.3601 15.0489 17.7901 15.0004 18.2057 15.0799C18.6214 15.1593 19.0031 15.3629 19.3006 15.6638L21.3836 17.7518Z"
                      fill="#808282"
                    />
                  </svg>
                  <span className="px-2">(327)037-8499</span>
                </a>
              </Col>

              <Col xs="auto" className="media">
                <a href="/">
                  <svg
                    className="svgStyle"
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.93 4.06C0.93 3.52 1.03 3.01333 1.23 2.54C1.43667 2.06667 1.71333 1.65333 2.06 1.3C2.41333 0.946666 2.82333 0.67 3.29 0.469999C3.75667 0.263333 4.25667 0.16 4.79 0.16C5.32333 0.16 5.82667 0.263333 6.3 0.469999C6.77333 0.67 7.18333 0.946666 7.53 1.3C7.88333 1.65333 8.16 2.06667 8.36 2.54C8.56667 3.01333 8.67 3.52 8.67 4.06C8.67 4.59333 8.56667 5.09333 8.36 5.56C8.16 6.02667 7.88333 6.43667 7.53 6.79C7.18333 7.14333 6.77333 7.42333 6.3 7.63C5.82667 7.83 5.32333 7.93 4.79 7.93C4.25667 7.93 3.75667 7.83 3.29 7.63C2.82333 7.42333 2.41333 7.14333 2.06 6.79C1.71333 6.43667 1.43667 6.02667 1.23 5.56C1.03 5.09333 0.93 4.59333 0.93 4.06Z"
                      fill="#808282"
                    />
                  </svg>
                </a>
              </Col>

              <Col xs="auto" className="nav-link">
                <a href="/">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2011 3.49365H2.79891C1.25288 3.49365 0 4.74653 0 6.29214V17.7075C0 19.2531 1.25288 20.506 2.79891 20.506H21.2011C22.7467 20.506 24 19.2531 24 17.7075V6.29214C24 4.74653 22.7468 3.49365 21.2011 3.49365ZM22.6636 18.4615L22.1308 19.0924L14.6699 13.0033L13.5144 14.1027C12.6581 14.8891 11.342 14.8891 10.4856 14.1027L9.33014 13.0033L1.86919 19.0924L1.33641 18.4615L8.34141 12.0623L1.29052 5.43582L1.70737 4.8835L12 12.6823L22.2926 4.8835L22.7091 5.43582L15.6586 12.0623L22.6636 18.4615Z"
                      fill="#808282"
                    />
                  </svg>
                  <span className="px-2">alice.wells@mail.com</span>
                </a>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col xs="auto" className="media">
                <Nav.Link href="/">
                  <svg
                    width="24"
                    height="24"
                    fill="#808282"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z" />
                  </svg>
                </Nav.Link>
              </Col>
              <Col xs="auto" className="media">
                <Nav.Link href="/">
                  <svg
                    width="24"
                    height="24"
                    fill="#808282"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.46 6C21.69 6.35 20.86 6.58 20 6.69C20.88 6.16 21.56 5.32 21.88 4.31C21.05 4.81 20.13 5.16 19.16 5.36C18.37 4.5 17.26 4 16 4C13.65 4 11.73 5.92 11.73 8.29C11.73 8.63 11.77 8.96 11.84 9.27C8.28004 9.09 5.11004 7.38 3.00004 4.79C2.63004 5.42 2.42004 6.16 2.42004 6.94C2.42004 8.43 3.17004 9.75 4.33004 10.5C3.62004 10.5 2.96004 10.3 2.38004 10V10.03C2.38004 12.11 3.86004 13.85 5.82004 14.24C5.19077 14.4122 4.53013 14.4362 3.89004 14.31C4.16165 15.1625 4.69358 15.9084 5.41106 16.4429C6.12854 16.9775 6.99549 17.2737 7.89004 17.29C6.37367 18.4904 4.49404 19.1393 2.56004 19.13C2.22004 19.13 1.88004 19.11 1.54004 19.07C3.44004 20.29 5.70004 21 8.12004 21C16 21 20.33 14.46 20.33 8.79C20.33 8.6 20.33 8.42 20.32 8.23C21.16 7.63 21.88 6.87 22.46 6Z" />
                  </svg>
                </Nav.Link>
              </Col>

              <Col xs="auto" className="media">
                <Nav.Link href="/">
                  <svg
                    width="24"
                    height="24"
                    fill="#808282"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.93994 5.00002C6.93968 5.53046 6.72871 6.03906 6.35345 6.41394C5.97819 6.78883 5.46937 6.99929 4.93894 6.99902C4.40851 6.99876 3.89991 6.78779 3.52502 6.41253C3.15014 6.03727 2.93968 5.52846 2.93994 4.99802C2.94021 4.46759 3.15117 3.95899 3.52644 3.5841C3.9017 3.20922 4.41051 2.99876 4.94094 2.99902C5.47137 2.99929 5.97998 3.21026 6.35486 3.58552C6.72975 3.96078 6.94021 4.46959 6.93994 5.00002ZM6.99994 8.48002H2.99994V21H6.99994V8.48002ZM13.3199 8.48002H9.33994V21H13.2799V14.43C13.2799 10.77 18.0499 10.43 18.0499 14.43V21H21.9999V13.07C21.9999 6.90002 14.9399 7.13002 13.2799 10.16L13.3199 8.48002Z"
                      fill="#808282"
                    />
                  </svg>
                </Nav.Link>
              </Col>

              <Col xs="auto" className="media">
                <Nav.Link href="/">
                  <svg
                    width="24"
                    height="24"
                    fill="#808282"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.8 2H16.2C19.4 2 22 4.6 22 7.8V16.2C22 17.7383 21.3889 19.2135 20.3012 20.3012C19.2135 21.3889 17.7383 22 16.2 22H7.8C4.6 22 2 19.4 2 16.2V7.8C2 6.26174 2.61107 4.78649 3.69878 3.69878C4.78649 2.61107 6.26174 2 7.8 2ZM7.6 4C6.64522 4 5.72955 4.37928 5.05442 5.05442C4.37928 5.72955 4 6.64522 4 7.6V16.4C4 18.39 5.61 20 7.6 20H16.4C17.3548 20 18.2705 19.6207 18.9456 18.9456C19.6207 18.2705 20 17.3548 20 16.4V7.6C20 5.61 18.39 4 16.4 4H7.6ZM17.25 5.5C17.5815 5.5 17.8995 5.6317 18.1339 5.86612C18.3683 6.10054 18.5 6.41848 18.5 6.75C18.5 7.08152 18.3683 7.39946 18.1339 7.63388C17.8995 7.8683 17.5815 8 17.25 8C16.9185 8 16.6005 7.8683 16.3661 7.63388C16.1317 7.39946 16 7.08152 16 6.75C16 6.41848 16.1317 6.10054 16.3661 5.86612C16.6005 5.6317 16.9185 5.5 17.25 5.5ZM12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7ZM12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9Z"
                      fill="#808282"
                    />
                  </svg>
                </Nav.Link>
              </Col>

              <Col xs="auto" className="media">
                <a href="/">
                  <svg
                    className="svgStyle"
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.93 4.06C0.93 3.52 1.03 3.01333 1.23 2.54C1.43667 2.06667 1.71333 1.65333 2.06 1.3C2.41333 0.946666 2.82333 0.67 3.29 0.469999C3.75667 0.263333 4.25667 0.16 4.79 0.16C5.32333 0.16 5.82667 0.263333 6.3 0.469999C6.77333 0.67 7.18333 0.946666 7.53 1.3C7.88333 1.65333 8.16 2.06667 8.36 2.54C8.56667 3.01333 8.67 3.52 8.67 4.06C8.67 4.59333 8.56667 5.09333 8.36 5.56C8.16 6.02667 7.88333 6.43667 7.53 6.79C7.18333 7.14333 6.77333 7.42333 6.3 7.63C5.82667 7.83 5.32333 7.93 4.79 7.93C4.25667 7.93 3.75667 7.83 3.29 7.63C2.82333 7.42333 2.41333 7.14333 2.06 6.79C1.71333 6.43667 1.43667 6.02667 1.23 5.56C1.03 5.09333 0.93 4.59333 0.93 4.06Z"
                      fill="#808282"
                    />
                  </svg>
                </a>
              </Col>

              <Col xs="auto" className="nav-link">
                <a href="/customer-login">Login</a>
              </Col>

              <Col xs="auto" className="nav-link">
                <a href="/">
                  <img src={strip} alt="img" />
                </a>
              </Col>

              <Col xs="auto" className="nav-link">
                <a href="/signup">Sign Up</a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StripHeader;
