import React, { useState } from "react";
import "../../App.css";
import Button from "../Button/Button";

const QuantityInput: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  const increment = () => setCount(prevCount => prevCount + 1);

  const decrement = () => setCount(prevCount => (prevCount > 0 ? prevCount - 1 : 0));

  return (
    <div className="d-flex gap-2 quantity-div">
      <Button className="quantity-box" type="button" onClick={decrement}>
        -
      </Button>
      <h1 className="h1-text-quantity">{count}</h1>
      <Button className="quantity-box" type="button" onClick={increment}>
        +
      </Button>
    </div>
  );
}

export default QuantityInput;
