import React from "react";
import { Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import onlineAgent from "../../../../images/online_agent.png";

const ChatLeftComponent: React.FC = () => {
  return (
    <div className="chat_profile">
      <div className="profile_header">
        <div className="booked_date">
          <label className="form-label">Check In</label>
          <h3>06 May, 2023</h3>
        </div>
        <div className="vertical-line"></div>
        <div className="booked_date">
          <label className="form-label">Check Out</label>
          <h3>08 May, 2023</h3>
        </div>
      </div>

      <div className="profile_content user_profile_content">
        <div className="dp_pic">
          <Image src={onlineAgent} alt="img" fluid />
          <h3>Amelia Greene</h3>
          <p>Online</p>
        </div>
      </div>
    </div>
  );
};

export default ChatLeftComponent;
