import { Modal, Form, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import home from "../../images/customer/home-table.png";
import added from "../../images/customer/added.png";
import "../modal.css";
import Button from "../../common/Button/Button";

interface GenerateWorkRequestModalProps {
  show: boolean;
  onHide: () => void;
}
const GenerateWorkRequestModalFacility: React.FC<GenerateWorkRequestModalProps> = ({
  show,
  onHide,
}) => {

  const handleClose = () => {
    onHide();
  };

  

  return (
    <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
      <div className="close-icon">
        <Image
          src={close}
          onClick={handleClose}
          className="img-close"
          alt="img"
        />
      </div>
      <Modal.Header className="border-bottom-0">
        <Modal.Title className="mx-auto">
          <span className="header-modal">Generate Work Request</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="agent_modal_body">
        <Form>
          <div className="row">
            <Form.Group className="col-md-12 mb-3" controlId="title">
              <Form.Label className="label-modal">
                Property<span className="text_red">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Property"
                className="form-input-modal form-height"
              />
            </Form.Group>

            <Form.Group className="col-md-6 mb-3" controlId="title">
              <Form.Label className="label-modal">
                Title<span className="text_red">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Title"
                className="form-input-modal form-height"
              />
            </Form.Group>

            <Form.Group className="col-md-6 mb-3" controlId="priority">
              <Form.Label className="label-modal">
                Priority<span className="text_red">*</span>
              </Form.Label>
              <Form.Select aria-label="Default select example" className="form-input-modal form-height form-control">
                <option>Select</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className="col-md-12 mb-3" controlId="description">
              <Form.Label className="label-modal">
                Description<span className="text_red">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Write description"
                className="form-input-modal form-height"
              />
            </Form.Group>
          </div>
          <Form.Group className="col-md-12 mb-3" controlId="uploadImages">
            <Form.Label className="label-modal">Upload Images</Form.Label>
            <div className="upload_sec py-3">
              <div className="upload_images">
                <Image src={home} alt="upload-1" />
              </div>
              <div className="upload_images">
                <Image src={home} alt="upload-2" />
              </div>
              <div className="upload_images">
                <Image src={home} alt="upload-3" />
              </div>
              <div className="property_upload">
                <Form.Label htmlFor="myfile" className="upload--btn">
                  Add
                  <br /> <Image src={added} alt="img-add" />
                </Form.Label>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="modal-delete border-top-0 justify-content-center">
        <Button
          variant="variant"
          className="modal_cancel_btn"
          type="submit"
          onClick={() => console.log("click")}
        >
          Create Work Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GenerateWorkRequestModalFacility;
