import React from "react";
import { Modal, Form, Image, Col } from "react-bootstrap";
import close from "../../images/customer/close.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import boots from "../../images/boost.png";

interface BootsModalProps {
    show: boolean;
    onHide: () => void;
}
const BootsModal: React.FC<BootsModalProps> = ({
    show,
    onHide,
}) => {
    const handleClose = () => {
        onHide();
    };
    return (
        <Modal show={show} onHide={handleClose} className="modal-boots" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>
            <Modal.Header className="border-bottom-0">
                <Modal.Title className="mx-auto">
                    <span className="header-modal">Boost Property</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="agent_modal_body">
                <Form>
                    <div className="row">
                        <Form.Group className="col-md-12 mb-3" controlId="title">
                            <Form.Label className="label-modal">
                                No. of Days<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="2"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>

                        <Col md={12} className="d-flex justify-content-between  mb-3">
                            <p className="boost_text">Token will be used:</p>
                            <h4>512</h4>
                        </Col>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer className="modal-delete border-top-0 justify-content-center">
                <Button
                    variant="variant"
                    className="modal_cancel_btn"
                    type="submit"
                    onClick={() => console.log("click")}
                >
                    <Image src={boots} alt="img" />
                    Boost
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BootsModal;
