import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import toggle from "../../../images/sidebar-icon/sidebar-toggle.png";
import { NavLink, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { sidebarCollapsed, handleToggleClick } = useContext(Context);
  return (
    <div>
      <aside className={`sidebar  ${sidebarCollapsed ? "visible" : "hidden"}`}>
        <div className="sidebar-start">
          <div className="sidebar-head">
            <a href="/login" className="logo-wrapper" title="Home">
              <span
                className={`icon logo ${sidebarCollapsed ? "visible" : "hidden"
                  }`}
                aria-hidden="true"
              ></span>
            </a>

            <span className="icon menu-toggle" aria-hidden="true">
              <Image src={toggle} onClick={handleToggleClick} alt="img" />
            </span>
          </div>

          <div className="container mt-5">
            <div className="side__text">
              <ul className="list-group">
                <li className="list-group-item" onClick={handleToggleClick}>
                  <div
                    className={`menu_heading dropdown-toggle d-flex gap-3 align-items-center ${location.pathname === "/facility-dashboard"
                      ? "active"
                      : ""
                      }`}
                  >
                    <NavLink to="/facility-dashboard" className="w-100">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.19 2H12.75V8V8.75V13.75H22V8.75V8V7.81C22 4.17 19.83 2 16.19 2Z"
                          fill="white"
                        />
                        <path
                          d="M2 10.25V15.25V15.75V16.19C2 19.83 4.17 22 7.81 22H11.25V15.75V15.25V10.25H2Z"
                          fill="white"
                        />
                        <path
                          d="M11.25 2V8.75H2V7.81C2 4.17 4.17 2 7.81 2H11.25Z"
                          fill="white"
                        />
                        <path
                          d="M22 15.25V16.19C22 19.83 19.83 22 16.19 22H12.75V15.25H22Z"
                          fill="white"
                        />
                      </svg>
                      <span className={`align-middle px-2 span__text ${sidebarCollapsed ? "visible" : "hidden"}`}>
                        Dashboard
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                >
                  <div
                    className={`menu_heading dropdown-toggle d-flex  align-items-center ${location.pathname === "/facility-management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={handleToggleClick}
                    >
                      <path
                        d="M14.25 15.375C14.25 16.8247 13.0747 18 11.625 18C10.1753 18 9 16.8247 9 15.375C9 13.9253 10.1753 12.75 11.625 12.75C13.0747 12.75 14.25 13.9253 14.25 15.375ZM12 12.0382V4.125C12 3.918 11.832 3.75 11.625 3.75C11.418 3.75 11.25 3.918 11.25 4.125V12.0382C11.3745 12.024 11.4967 12 11.625 12C11.7533 12 11.8755 12.024 12 12.0382ZM11.25 18.7118V19.875C11.25 20.082 11.418 20.25 11.625 20.25C11.832 20.25 12 20.082 12 19.875V18.7118C11.8755 18.726 11.7533 18.75 11.625 18.75C11.4967 18.75 11.3745 18.726 11.25 18.7118ZM18 14.2118V19.875C18 20.082 18.168 20.25 18.375 20.25C18.582 20.25 18.75 20.082 18.75 19.875V14.2118C18.6255 14.226 18.5033 14.25 18.375 14.25C18.2467 14.25 18.1245 14.226 18 14.2118ZM18.75 7.53825V4.125C18.75 3.918 18.582 3.75 18.375 3.75C18.168 3.75 18 3.918 18 4.125V7.53825C18.1245 7.524 18.2467 7.5 18.375 7.5C18.5033 7.5 18.6255 7.524 18.75 7.53825ZM5.25 5.28825V4.125C5.25 3.918 5.082 3.75 4.875 3.75C4.668 3.75 4.5 3.918 4.5 4.125V5.28825C4.6245 5.274 4.74675 5.25 4.875 5.25C5.00325 5.25 5.1255 5.274 5.25 5.28825ZM4.5 11.9618V19.875C4.5 20.082 4.668 20.25 4.875 20.25C5.082 20.25 5.25 20.082 5.25 19.875V11.9618C5.1255 11.976 5.00325 12 4.875 12C4.74675 12 4.6245 11.976 4.5 11.9618ZM4.875 6C3.42525 6 2.25 7.17525 2.25 8.625C2.25 10.0747 3.42525 11.25 4.875 11.25C6.32475 11.25 7.5 10.0747 7.5 8.625C7.5 7.17525 6.32475 6 4.875 6ZM18.375 8.25C16.9253 8.25 15.75 9.42525 15.75 10.875C15.75 12.3247 16.9253 13.5 18.375 13.5C19.8248 13.5 21 12.3247 21 10.875C21 9.42525 19.8248 8.25 18.375 8.25Z"
                        fill="white"
                      />
                    </svg>
                    <span className="align-middle px-2 span__text">
                      Facility Management
                    </span>
                  </div>

                  <div className="accordion_sub_menu collapse" id="collapseTwo">
                    <div className="card card-body">
                      <ul>
                        <NavLink to="/facility-work-request">
                          <li>
                            <a href="/facility-work-request">Work Request</a>
                          </li>
                        </NavLink>
                        <NavLink to="/facility-order-request">
                          <li>
                            <a href="/facility-order-request">Work Order</a>
                          </li>
                        </NavLink>
                        <NavLink to="/facility-completed">
                          <li>
                            <a href="/facility-completed">Completed</a>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
