import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoAgent() {
    const agentCode = [
        '210125',
        '230128',
        '240130',
        '210283',
        '210164',
        '240242',
        '290724',
        '287352',
        '209745',
        '356392',
        '409745',
    ];

    return (
        <Stack spacing={2} >
            <Autocomplete
                freeSolo
                disableClearable
                options={agentCode.map((option) => option)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className="custom-input"

                        sx={{
                            '& fieldset': {
                                border: 'none',
                            },
                            '& input': {
                                padding: '0px !important',
                                fontFamily: 'Lato',
                                fontSize: '20px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                                color: '#163873',
                            },
                        }}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            placeholder: '210125',
                        }}
                    />
                )}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                    <li {...props} style={{ fontFamily: 'Lato', fontWeight: 'normal', color: "#808282", boxShadow: "none" }}>
                        {option}
                    </li>
                )
                }
            />
        </Stack >
    );
}
