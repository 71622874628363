import React from 'react'
import MyBooking from '../MyBooking'
import RelatedProperties from './RelatedProperties'

export default function Booking() {
    return (
        <div>
            <MyBooking />
            <RelatedProperties />
        </div>
    )
}
