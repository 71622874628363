import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PRIMARY_BLUE,TEXT_GREY, TEXT_WHITE } from '../../styles/colors';

const PaginationComponent = () => {
    return (
        <Stack spacing={2}>
            <Pagination
                count={5}
                renderItem={(item) => (
                    <PaginationItem
                        slots={{ previous: ArrowBackIosNewIcon, next: ArrowForwardIosIcon }}
                        {...item}
                        sx={{
                            '&.Mui-selected': {
                                backgroundColor: PRIMARY_BLUE,
                                color: TEXT_WHITE,
                                fontFamily: 'Lato',
                                fontWeight: '500',
                                fontSize: '16px',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: PRIMARY_BLUE,
                                    borderRadius: '10px',
                                    fontFamily: 'Lato',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                },
                            },
                            '&:not(.Mui-selected)': {
                                color: TEXT_GREY,
                                fontFamily: 'Lato',
                                fontWeight: '500',
                                fontSize: '16px',
                                borderRadius: '10px',
                                '&:hover': {
                                    color: TEXT_GREY,
                                    borderRadius: '10px',
                                    fontFamily: 'Lato',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                },
                            },
                        }}
                    />
                )}
            />
        </Stack>
    );
};

export default PaginationComponent;
