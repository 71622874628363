import React, { useState } from "react";
import { Button, Container, Col, Row, Form } from "react-bootstrap";
import RangeSlider from "../../../../common/slider/RangeSlider";
import SearchButton from "../../../../common/searchIconButton/SearchButton";
import cancel from "../../../../images/cancel.png";
import Datepicker from "../../../../common/datepicker/Datepicker";
import AutoSearch from "../../../../common/autoSearch/AutoSearch";
import QuantityInput from "../../../../common/number/QuantityInput";

export const minValue = 16000;
export const maxValue = 26000;

const SearchBox: React.FC = () => {
  const [showMap, setShowMap] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
  const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(null);


  const handleTabSelect = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCheckIn = (date: Date | null) => {
    setSelectedChcekInDate(date);
  };

  const handleCheckOut = (date: Date | null) => {
    setSelectedChcekOutDate(date);
  }
  return (
    <div>
      <section id="properties_banner_search">
        <Container>
          <div className="banner_search_box ">
            <div className="properties_search_sec">
              <ul className="nav nav-underline gap-4">
                {[
                  "All",
                  "Short-Let",
                  "Medium-Lease",
                  "Long-Lease",
                  "Rent-Financing",
                ].map((tab) => (
                  <li className="nav-item" key={tab}>
                    <button
                      className={`nav-link${selectedTab === tab ? " active" : ""
                        }`}
                      onClick={() => handleTabSelect(tab)}
                    >
                      {tab.replace("-", " ")}
                    </button>
                  </li>
                ))}
              </ul>

              <div className="tab_top_btn">
                <div className="first_radio_btn">
                  <Form.Switch
                    id="flexSwitchCheckChecked"
                    label="Show Map"
                    style={{ color: "#010404" }}
                    checked={showMap}
                    onChange={() => setShowMap(!showMap)}
                  />
                </div>
                <div className="second_clear_btn">
                  <Button
                    type="button"
                    variant="variant"
                    className="btn btn_clear"
                    onClick={() => console.log("Button clicked")}
                  >
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={cancel}
                      alt="img"
                    />
                    Clear Search
                  </Button>
                </div>
              </div>
            </div>
            <Row>
              <Col sm={10}>
                <div className="content_tab">
                  <div className="items_tab">
                    <h4>Search</h4>
                    <AutoSearch />
                  </div>

                  <div className="items_tab">
                    <h4>Check In</h4>
                    <Datepicker
                      className="mb-0 text_height_p border-0"
                      selected={selectedChcekInDate}
                      onChange={handleCheckIn}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>
                  <div className="items_tab">
                    <h4>Check Out</h4>
                    <Datepicker
                      className="mb-0 text_height_p border-0"
                      selected={selectedChcekOutDate}
                      onChange={handleCheckOut}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>

                  <div className="items_tab">
                    <h4>No. of Guests</h4>
                    <QuantityInput />
                  </div>
                </div>
              </Col>

              <Col sx={2}>
                <SearchButton />
              </Col>
              <div>
                <div className="tab_range col-sm-10">
                  <h4>Price</h4>
                  <div className="value-label py-2">
                    <span>Min</span>
                    <span>Max</span>
                  </div>
                  <Row>
                    <Col>
                      <RangeSlider min={minValue} max={maxValue} />
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SearchBox;
