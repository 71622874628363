import React, { useState } from "react";
import { Image, Form } from "react-bootstrap";
import { chatUserData } from "../../../../common/userCard/UserCard";
import Searchbar from "../../../../common/searchbar/Searchbar";
import chatSidebars from "../../../../images/chatSidebars.png";
import onlin_agent from "../../../../images/online_agent.png";
interface ChatUser {
  userName: string;
  userMsg: string;
  userTime: string;
  img: string;
}

const ChatSidebar = () => {
  const [activeAgent, setActiveAgent] = useState<number>(1); // Default active agent index

  const handleAgentClick = (index: number) => {
    setActiveAgent(index);
  };

  // search event
  const handleCustomSearch = () => {
    console.log("search");
  };

  return (
    <div className="side_agent user_side_agent">
      <div className="agent_search sidebar_search_new" >
        {/* Searchbar common component used and color placeholder onClick also paased */}
        <Searchbar
          handleSearch={handleCustomSearch}
          placeholder="Search"
          inputColor="#808282"
          buttonColor="#B8B8B8"
          customStyles={{ border: "1px solid #E5E6E7", width: "100%", height: "40px" }}
        />

      </div>
      <div>
        <div className="d-flex align-items-center">
          <img src={chatSidebars} alt="img" />
          <Form.Select className="ml-auto agent_sidebar border-0 ">
            <option className=" agent_sidebar border-0">Agent</option>
            <option className=" agent_sidebar border-0" value="1">One</option>
            <option className=" agent_sidebar border-0" value="2">Two</option>
            <option className=" agent_sidebar border-0" value="3">Three</option>
          </Form.Select>
        </div>
      </div>
      <div className="agent_list py-3">


        {/* Data rendar UserCard I have import multipal userlist and showing this component */}
        {chatUserData.map((item: ChatUser, index: number) => (
          <div
            key={index}
            className={`agent_item_list ${index === activeAgent ? "active_agent" : ""
              } py-3`}
            onClick={() => handleAgentClick(index)}
          >
            {/* change img for add status active or not onlin_agent */}
            <img src={onlin_agent} className="img-fluid" alt="img" />
            <div className="agent_name">
              <div className="agent_text">
                <h5>{item.userName}</h5>
                <p> {item.userMsg}</p>
              </div>
            </div>
            <div className="agent_mode">
              <p>{item.userTime}</p>
              <Image src={item.img} alt="img" fluid style={{ width: "15px", height: "15px" }} />
            </div>
          </div>
        ))}

      </div>
    </div>
  );
};

export default ChatSidebar;
