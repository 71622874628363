import Layout from "../../layout/layout";
import { Col, Container, Form, Image, Row} from 'react-bootstrap';
import { images } from "../../../../common/constant/constant";
import added from "../../../../images/customer/added.png";
import left from "../../../../images/customer/left.png"
import { useNavigate } from 'react-router-dom';
import Button from "../../../../common/Button/Button";

const AddNewAgentProperties: React.FC = () => {
    const navigate = useNavigate();
    return (
        <Layout>
            <main className="main users">
                <Container>
                    <div className='mb-4'>
                        <Image onClick={() => navigate('/agent-property')} className='img-click' src={left} alt="img" />
                    </div>
                    {/* create new  button component is create property after QA test */}
                    <div className="add_property_sec pb-3">
                        <div className="property_wrap">
                            <div className="row">
                                <div className="col-sm-10">
                                    <h3 className="text-center property_details mb-5">Add New Property</h3>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        variant="variant"
                                        className="modal_cancel_btn"
                                        type="submit"
                                        onClick={() => console.log("click")}
                                    >
                                        Create Property
                                    </Button>
                                </div>
                            </div>

                            <Row>
                                <Col lg={3}>
                                    <section className="img__container">
                                        <h4>Upload Pictures</h4>
                                        <div className="upload_sec py-3">

                                            <div className="upload_sec py-3">
                                                {images.map((item, index) => (
                                                    <div key={index} className="upload_images">
                                                        <Image src={item.img} alt="img" />
                                                    </div>
                                                ))}
                                                <div className="property_upload">
                                                    <input type="file" id="myfile" name="myfile" accept="image/*" className="upload" />
                                                    <label htmlFor="myfile" className="upload--btn">Add<br /><Image src={added} /></label>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </Col>

                                <Col lg={9}>
                                    <div className="addproperty_form">
                                        <Form>
                                            <Row>
                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Title<span>*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Title" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Price<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Category<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>Select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Taxes<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Service Charge (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Other Fees (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>360 Virtual Link<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="https://" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Is Featured<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>Select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Features<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Features" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Amenity<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>Select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Quantity<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Quantity" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Complete Address<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Full Address" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Country<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>Select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>State<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="State" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>City<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="City" />
                                                </Form.Group>
                             {/* add sone column check In check Out and deposit  */}
                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Check In<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="28 Apr, 2023" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Check Out<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="05 May, 2023" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Caution Deposit<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Refundable" />
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container >
            </main >
        </Layout >
    )
}

export default AddNewAgentProperties;
