import React, { useState, useEffect } from "react";
import { Image, Form } from "react-bootstrap";
import logo from "../../../images/login/white-logo.png";
import user from "../../../images/login/user-login.png";
import email from "../../../images/login/email-login.png";
import password from "../../../images/login/password-login.png";
import line from "../../../images/login/or-pic.png";
import apple from "../../../images/login/apple.png";
import facebook from "../../../images/login/facebook.png";
import google from "../../../images/login/google.png";
import {
  accountDetails,
  loginDiscription,
} from "../../../common/constant/constant";
import Button from "../../../common/Button/Button";
import { useNavigate } from "react-router-dom";
import close from "../../../images/customer/hide.svg";
import open from "../../../images/customer/open.svg";

interface AccountDetail {
  title: string;
  discription: string;
  image: string;
  link: string;

}

interface LoginDescription {
  title: string;
  discription: string;
}

const FacilitySignUp: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [activeLink, setActiveLink] = useState<string>('');

  useEffect(() => {
    // Retrieve the active link from storage when component mounts
    const storedActiveLink = localStorage.getItem("activeLink");
    if (storedActiveLink) {
      setActiveLink(storedActiveLink);
    }
  }, []);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    // Store the active link in localStorage
    localStorage.setItem("activeLink", link);
  };


  return (
    <section className="owner_facility_login">
      <div className="left-side min-vh-100 ">
        <div className="left_content">
          <div className="owner_logo py-3">
            <a href="/login">
              <Image src={logo} alt="img" />
            </a>
          </div>
          {loginDiscription?.map((item: LoginDescription, index: number) => (
            <div key={index} className="choose_account py-3">
              <label className="form-label">{item.title}</label>
              <p>{item.discription}</p>
            </div>
          ))}

          {accountDetails?.map((item: AccountDetail, index: number) => (
            <div
              key={index}
              className="account_choose_list py-3"
              onClick={() => handleLinkClick(item.link)}
            >
              <div
                className={`item_box ${activeLink === item.link ? "active" : ""}`}
              >
                <div className="name_account d-flex align-items-self-start">
                  <span>
                    <Image src={item.image} alt="img" />
                  </span>
                  <label className="form-label">{item.title}</label>
                </div>
                <p>{item.discription}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="right-side">
        <div className="login_form">
          <h3>Create account</h3>
          <Form>
            <Form.Group
              controlId="formBasicEmail"
              className="my-4 owner_input position-relative"
            >
              <Form.Control type="text" placeholder="First Name" />
              <span>
                <Image src={user} alt="login" />
              </span>
            </Form.Group>

            <Form.Group
              controlId="formBasicEmail"
              className="my-4 owner_input position-relative"
            >
              <Form.Control type="text" placeholder="Last Name" />
              <span>
                <Image src={user} alt="login" />
              </span>
            </Form.Group>

            <Form.Group
              controlId="formBasicEmail"
              className="my-4 owner_input position-relative"
            >
              <Form.Control type="text" placeholder="Email address" />
              <span>
                <Image src={email} alt="login" />
              </span>
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="my-4 owner_input position-relative"
            >
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Create Password"
              />
              <span>
                <Image src={password} alt="password" />
              </span>
              <Image
                src={showPassword ? open : close}
                className='show-password'
                onClick={() => setShowPassword(!showPassword)}
                alt="password"
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              className="my-4 owner_input position-relative"
            >
              <Form.Control
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password"
              />
              <span>
                <Image src={password} alt="password" />
              </span>
              <Image
                src={showConfirmPassword ? open : close}
                className='show-password'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                alt="password"
              />
            </Form.Group>

            <Form.Group className="my-3 d-flex justify-content-between align-items-center">
              <Form.Check className="mb-3">
                <Form.Check.Input
                  className="border-checkbox"
                  type="checkbox"
                  id="gridCheck"
                />
                <Form.Check.Label htmlFor="gridCheck">
                  I agree to be bound by these{" "}
                  <a href="/"> Terms & Condition </a>
                  and <br />
                  <a href="/">User Agreement.</a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <Button
              type="submit"
              className="w-100 btn btn-primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </Form>

          <div className="agent_or py-3">
            <Image src={line} alt="img" />
            <div className="agent_login_option">
              <Image src={apple} alt="img" />
              <Image src={facebook} alt="img" />
              <Image src={google} alt="img" />
            </div>
          </div>
        </div>
        <p className="register_login">
          Already have an account? <a href="/login">Login </a>
        </p>
      </div>
    </section>
  );
};

export default FacilitySignUp;
