import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Image,
  Form,
  Button,
} from "react-bootstrap";
import Layout from "../../layout/layout";
import profile from "../../../../images/customer/profile.png"

const FacilityProfile: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("profile");

  const handleTabSelect = (selectedTab: string) => {
    setActiveTab(selectedTab);
  };

  return (
    <Layout>
      <main className="main users tabel_page">
        <Container>
          <Row>
            <Col lg={2} className="border-right">
              <div className="profile__tab">
                <Nav
                  className="flex-column"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <Nav.Item>
                    <Nav.Link
                      className={`nav-link-profile ${
                        activeTab === "profile" && "active"
                      }`}
                      eventKey="profile"
                      onClick={() => handleTabSelect("profile")}
                    >
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className={`nav-link-profile ${
                        activeTab === "settings" && "active"
                      }`}
                      eventKey="settings"
                      onClick={() => handleTabSelect("settings")}
                    >
                      Settings
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col lg={10}>
              <Tab.Content id="v-pills-tabContent">
                <Tab.Pane
                  eventKey="profile"
                  className={`show ${activeTab === "profile" && "active"}`}
                >
                  <div className="owner_profile_wrap">
                    <div className="profile__content">
                      <div className="pic_upload">
                        <Image src={profile} rounded />
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Full Name
                        </Form.Label>
                        <Form.Control
                          className="profile_content_inputfield"
                          type="text"
                          placeholder="Olivia"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="olivia@mail.com"
                          className="profile_content_inputfield"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Phone Number
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          className="profile_content_inputfield"
                          placeholder="(406) 555-0120"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="1901 Thornridge Cir. Shiloh, Hawaii 81063"
                          className="profile_content_inputfield"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Button variant="primary">Save Changes</Button>
                      </Form.Group>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane
                  eventKey="settings"
                  className={`show ${activeTab === "settings" && "active"}`}
                >
                  <div className="owner_profile_wrap">
                    <div className="profile__content">
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Current Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Current Password"
                          className="profile_content_inputfield"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          className="profile_content_inputfield"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="profile__content_label">
                          Confirm Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm Password"
                          className="profile_content_inputfield"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Button variant="primary">Save Changes</Button>
                      </Form.Group>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  );
};

export default FacilityProfile;
