import Router from "./router/Router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
