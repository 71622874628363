import React from "react";
import logo from "../../../images/my-homes-logo.png";
import {
  Image,
  Container,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import facebook from "../../../images/icons/facebook.png";
import twitter from "../../../images/icons/twitter.png";
import instagram from "../../../images/icons/instagram.png";
import linkedin from "../../../images/icons/linkedin.png";
import {
  footerfaqitem,
  footerhelpitem,
  footerlinkitem,
  footersupportitem,
} from "../../../common/constant/constant";
import ScrollTopButton from "../../../common/scrollTopButton/ScrollTopButton";

interface FooterLinkItem {
  title: string;
  link: string;
}

const Footer: React.FC = () => {
  const renderLinks = (footerLinks: FooterLinkItem[]) => {
    return footerLinks.map((item: FooterLinkItem) => (
      <li key={item.title}>
        <a href={item.link}>{item.title}</a>
      </li>
    ));
  };

  return (
    <div>
      <footer id="footer_sec" className="footer_sec">
        <Container>
          <div className="footer_content py-3 py-lg-5">
            <div className="logo_icon pt-5 text-center">
              <span>
                <a href="/">
                  <Image src={logo} alt="logo" />
                </a>
              </span>
              <div className="footer_icon mt-3 d-flex justify-content-center align-items-center gap-3 py-3">
                <a href="/">
                  <Image src={facebook} alt="img" />
                </a>
                <a href="/">
                  <Image src={twitter} alt="img" />
                </a>
                <a href="/">
                  <Image src={linkedin} alt="img" />
                </a>
                <a href="/">
                  <Image src={instagram} alt="img" />
                </a>
              </div>
              <p>
                Be the first one to know about discounts, offers, and events
                weekly in your <br /> mailbox. Unsubscribe whenever you like
                with one click.
              </p>
            </div>
            <div className="footer_Search d-flex align-items-center py-3 py-lg-5 justify-content-center">
              <InputGroup className="input_group_footer">
                <FormControl
                  type="text"
                  placeholder="Email address"
                  className="form-color"
                />
                <Button variant="primary">Subscribe</Button>
              </InputGroup>
            </div>

            <Row>
              <Col lg={3} md={3} xs={6}>
                <div className="footer_link">
                  <h3>Quick Links</h3>
                  <ul>
                    {footerlinkitem.map((item: FooterLinkItem) => (
                      <li>
                        <a href={item.link}>{item.title}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={3} xs={6}>
                <div className="footer_link">
                  <h3>Support</h3>
                  <ul>{renderLinks(footersupportitem)}</ul>
                </div>
              </Col>
              <Col lg={3} md={3} xs={6}>
                <div className="footer_link">
                  <h3>FAQ</h3>
                  <ul>{renderLinks(footerfaqitem)}</ul>
                </div>
              </Col>
              <Col lg={3} md={3} xs={6}>
                <div className="footer_link">
                  <h3>Help</h3>
                  <ul>{renderLinks(footerhelpitem)}</ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <div className="footer_bottom pt-3 text-center">
          <span>My Homes NG © 2023 myhomesng.com, All Rights Reserved</span>
        </div>
      </footer>
      <ScrollTopButton />
    </div>
  );
};

export default Footer;
