import React from "react";
import PropertyBanner from "./BannerSection/PropertyBanner";
import Booking from "./BookingSection/Booking";
import ContactUs from "../HomeBanner/ContactUs/ContactUs";
const PropertyDetails = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <div>
      <PropertyBanner />
      <Booking />
      <ContactUs />
    </div>
  );
};

export default PropertyDetails;
