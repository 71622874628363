import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { makeStyles } from '@mui/styles';
import { Row } from 'react-bootstrap';

interface RangeSliderProps {
    min: number;
    max: number;
}

const useStyles = makeStyles({
    root: {
        color: '#52af77 !important',
    },
    thumb: {
        backgroundColor: '#FFFF !important',
    },
    track: {
        color: '#163873 !important',
        minHeight: '6px !important'
    },
    rail: {
        height: '6px !important',
        backgroundColor: 'var(--Stroke, #E9ECEF) !important',
    },

});

function valuetext(value: number) {
    return `${value}`;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ min, max }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState<number[]>([min, max]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number[]);
    };

    return (
        <Box>
            <Slider 
                classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    track: classes.track,
                    rail: classes.rail,
                }}
                getAriaLabel={() => 'Temperature range'}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                min={min}
                max={max}
            />
            <div className="tab_range">
                <Row>
                    <div className="values">
                        <div className="min-value">${value[0]}</div>
                        <div className="max-value">${value[1]}</div>
                    </div>
                </Row>
            </div>

        </Box>
    );
}

export default RangeSlider;
