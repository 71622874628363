import React from "react";
import { Modal, Image } from "react-bootstrap";
import "../../modal/modal.css";
import close from "../../images/customer/close.png";
import Button from "../../common/Button/Button";

interface BookingDetailsProps {
  show: boolean;
  onHide: () => void;
}

const AgentBookingDetails: React.FC<BookingDetailsProps> = ({ show, onHide }) => {
  const handleClose = () => {
    onHide();
  };

  return (
    <Modal className="booking-details" show={show} onHide={handleClose}>
      <div className="close-icon">
        <Image
          src={close}
          onClick={handleClose}
          className="img-close"
          alt="img"
        />
      </div>

      <Modal.Body className="agent_modal_body">
        <div className="u_sure text-center py-2">
          <h4>Booking Details</h4>
        </div>
        <div className="details__booking">
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking mb-3">
              <h6>Username</h6>
              <p>Admin</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Property</h6>
              <p>The Oak Court</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Agent Code</h6>
              <p>12345</p>
            </div>
          </div>
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking mb-3">
              <h6>Check In</h6>
              <p>28 Apr, 2023</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Check Out</h6>
              <p>05 May, 2023</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Status</h6>
              <p>Pending</p>
            </div>
          </div>
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking mb-3">
              <h6>Actual Price</h6>
              <p>₦ 30,000</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Negotiable Price</h6>
              <p>₦ 25,000</p>
            </div>
            <div className="col-lg-4 box-booking mb-3">
              <h6>Booked Price</h6>
              <p>₦ 3,000</p>
            </div>
          </div>
          <div className="row border-bottom py-3">
            <div className="col-lg-4 box-booking mb-3">
              <h6>Booking Id</h6>
              <p>#123</p>
            </div>
            <div className="col-lg-4 box-booking mb-3 ">
              <h6>Total</h6>
              <p>₦ 25,000</p>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center btns_booking py-4 comman_button_group">
            <Button type="button" className="btn_negotiate">Approve Negotiable Price</Button>
            <Button type="button" className="btn_actual">Approve Actual Price</Button>
            <Button type="button" className="btn_reject">Reject</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AgentBookingDetails;
