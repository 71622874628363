import React from "react";
import { Image } from "react-bootstrap";
import user from "../../../../../images/customer/user.png";
import user2 from "../../../../../images/customer/user02.png";
import bothImg from "../../../../../images/customer/bothImg.png";
const ChatContent = () => {
  return (
    <div className="position-relative">
      <div className="chat-messages p-4">
        <div className="chat-message-right pb-4">
          <div className="you_right">
            <h3 className=" mb-1">You</h3>
            <div className="right_text">
              <Image src={user2} className="img-fluid" alt="agent-online" />
              <div className="text-muted small text-nowrap mt-2">2:33 am</div>
            </div>
          </div>
          <div className="right_msg py-2 px-3 mr-3">
            Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te
            vix.
          </div>
          <div className="right_msg py-2 px-3 mr-2">
            <div className="right_msg py-2 px-3 mr-2">
              Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset
              te vix. Lorem ipsum dolor sit amet, vis erat denique in, dicunt
              prodesset te vix.
            </div>
          </div>
        </div>

        <div className="chat-message-left pb-4">
          <div className="cht_image_grp">
            <Image src={user} className="img-fluid" alt="agent-online" />
            <div className="Amelia_text py-2">
              <div className="cht_time">
                <h3>Amelia Greene</h3>
                <div className="text-muted small text-nowrap">2:34 am</div>
              </div>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Molestias assumenda error magni eveniet dignissimos aliquam
                obcaecati facilis ea,
              </p>
            </div>
          </div>
        </div>

        <div className="chat-message-right pb-4">
          <div className="you_right">
            <h3 className=" mb-1">You</h3>
            <div className="right_text">
              <Image src={user2} className="img-fluid" alt="agent-online" />
              <div className="text-muted small text-nowrap mt-2">2:33 am</div>
            </div>
          </div>
          <div className="right_msg py-2 px-3 mr-2">
            Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te
            vix.
          </div>
          <div className="right_msg py-2 px-3 mr-2">
            <div className="right_msg py-2 px-3 mr-2">
              Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset
              te vix. Lorem ipsum dolor sit amet, vis erat denique in, dicunt
              prodesset te vix.
            </div>
          </div>
        </div>

        <div className="chat-message-left pb-4">
          <div>
            <Image src={user} className="img-fluid" alt="agent-online" />
          </div>
          <div className="Amelia_text py-2">
            <div className="cht_time">
              <h3>Amelia Greene</h3>
              <div className="text-muted small text-nowrap">2:34 am</div>
            </div>
            <p>
            I have a good idea, how about we see the green <br/>scenery in my grandmother's hometown, so we can <br/>fresh there
            </p>
            <Image src={bothImg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatContent;
