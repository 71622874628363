import { Link } from 'react-router-dom';
import React from 'react';

const NotFound: React.FC = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
    return (
        <div>
            <div className="d-flex align-items-center justify-content-center vh-100 found-div">
                <div className="text-center">
                    <h1 className="display-1 fw-bold">404</h1>
                    <p className="fs-3">
                        <span className="text-danger">
                            <b>Oops!</b>
                        </span>{" "}
                        Page not found.
                    </p>
                    <p className="lead">The page you're looking for doesn't exist.</p>
                    <Link to="/" className="btn btn-primary">
                        Go Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
