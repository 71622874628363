import Header from "./Header";
import Sidebar from "./Sidebar";
import "../styles/customer-common.css";
import "../styles/customer-responsive.css";
import "../styles/customer-sidebar.css";
import "../styles/responsive.css"; // import css component
import { Context } from "../../context/Context";
import { useState } from "react";
type ChildrenContainer = Pick<JSX.IntrinsicElements["div"], "children">;

function Layout({ children }: ChildrenContainer) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

  const handleToggleClick = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  return (
    <Context.Provider value={{ sidebarCollapsed, handleToggleClick }}>
      <div className="page-flex">
        <Sidebar />
        <div className="main-wrapper">
          <Header />
          <main>{children}</main>
        </div>
      </div>
    </Context.Provider>
  );
}

export default Layout;
