import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, sans-serif',
    body2: {
      fontWeight: 400,

    },
  },
});

const customCalendarStyle = `
  .MuiDateRangeCalendar-root div:not([class]) {
    display: none;
    color: none
  }
  
`;

const WeekPicker: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="responsive-container">
          <style>{customCalendarStyle}</style>
          <DemoContainer components={['DateRangeCalendar']}>
            <DateRangeCalendar calendars={3} />
          </DemoContainer>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default WeekPicker;
