import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Image } from "react-bootstrap";
import user from "../../../images/sidebar-icon/user.png";
import user2 from "../../../images/customer/noti.png";
import notification from "../../../images/sidebar-icon/notification.png";
import { Context } from "../../context/Context";

const Header: React.FC = () => {
  const { handleToggleClick } = useContext(Context);
  return (
    <Navbar className="main-nav--bg px-4" expand="lg">
      <div className="container-fluid">
        <div className="main-nav mob_header">

          <Navbar.Collapse
            id="basic-navbar-nav"
            className="active_header"
          >
            <Navbar.Toggle onClick={handleToggleClick} aria-controls="basic-navbar-nav" />
            <Nav className="search-wrapper">
              <h3>Welcome, Jane</h3>
            </Nav>
            <Nav className="notification-wrapper">
              <NavDropdown
                title={
                  <div
                    className="gray-circle-btn dropdown-btn"
                    title="To messages"
                  >
                    <Image src={notification} alt="img" />
                  </div>
                }
              >
                <NavDropdown.Item className="dropdown-menu-header">
                  <div className="notification-dropdown-icon info">
                    <Image src={user2} alt="img" />
                  </div>
                  <div className="notification-dropdown-text">
                    <span className="notification-dropdown__title">
                      System just updated
                    </span>
                    <span className="notification-dropdown__subtitle">
                      The system has been successfully upgraded. Read more here.
                    </span>
                  </div>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/">
                  Go to Notifications page
                </NavDropdown.Item>
              </NavDropdown>

              {/* User Section */}
              <div className="">
                <NavDropdown
                  title={
                    <div
                      className="nav-user-btn dropdown-btn nav-dropdown-header"
                      title="My profile"
                    >
                      <span className="user-profile">
                        <Image src={user} alt="User name" />
                      </span>
                    </div>
                  }
                  id="nav-user-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/landlord-profile">
                    <i data-feather="user" aria-hidden="true"></i>
                    <i className="bi bi-person"></i> <span>Profile</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item href="##">
                    <i data-feather="settings" aria-hidden="true"></i>
                    <i className="bi bi-gear"></i> <span>Account settings</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="danger" href="/login">
                    <i data-feather="log-out" aria-hidden="true"></i>
                    <i className="bi bi-box-arrow-right text-danger"></i>{" "}
                    <span className="text-danger">Log out</span>
                  </NavDropdown.Item>

                </NavDropdown>
              </div>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
