import { useState } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import logo from "../../../../images/my-homes-logo.png";
import close from "../../../../images/close.png";
import { NavLink, useNavigate } from "react-router-dom";
import { navmenuitems } from "../../../../common/constant/constant";
import Button from "../../../../common/Button/Button";

export default function NavHeader() {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);


  const handleToggleBtnClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <Navbar expand="lg" fixed="top" id="header">
        <Container>
          <div>
            <img
              onClick={() => navigate("/")}
              src={logo}
              className="logo header-logo"
              alt="Logo"
            />
          </div>
          <Navbar.Toggle
            onClick={handleToggleBtnClick}
            className="toggle-class"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <div className="flex-lg-column">
            <Nav className="justify-content-center align-items-center d-none gap-5 d-lg-flex">
              {navmenuitems.map((item, index) => (
                <Nav.Item key={index}>
                  <NavLink className="nav-text" to={{ pathname: item.link }}>
                    {item.title}
                  </NavLink>
                </Nav.Item>
              ))}
            </Nav>

            {sidebarOpen && <div
              className={`nav-mb d-lg-none small-screen-css `}
            >
              <div className="px-3 pb-4 d-flex align-items-center justify-content-between">
                <Navbar.Brand href="/">
                  <img src={logo} className="logo" alt="Logo" />
                </Navbar.Brand>

                <span className="close" onClick={handleToggleBtnClick}>
                  <img src={close} alt="Close" />
                </span>
              </div>
              <Nav className="ms-auto justify-content-end px-3">
                {navmenuitems.map((item, index) => (
                  <Nav.Item className="mb-4" key={index}>
                    <NavLink
                      className="nav-text"
                      to={{ pathname: item.link }}
                      onClick={handleToggleBtnClick}
                    >
                      {item.title}
                    </NavLink>
                  </Nav.Item>
                ))}
                <div>
                  <Button
                    type="button"
                    variant="prumary"
                    className="btn btn_primary btn-common-header"
                    onClick={() => console.log("Button clicked")}
                  >
                    Get Started
                  </Button>
                </div>
              </Nav>
            </div>}
          </div>

          <div className="d-none d-lg-block">
            <Button
              type="button"
              variant="prumary"
              className="btn btn_primary btn-common-header"
              onClick={() => console.log("Button clicked")}
            >
              Get Started
            </Button>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}
