import React from 'react';

interface ButtonProps {
  type: "button" | "submit" | "reset";
  className?: string;
  onClick?: () => void;
  variant?: string;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({ type, className, onClick, variant, children }) => {
  const variantClass = variant ? `btn-${variant}` : '';
  const combinedClassName = `${variantClass} ${className || ''}`;

  return (
    <button type={type} className={combinedClassName} onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;
