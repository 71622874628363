import React from "react";
import { Image } from "react-bootstrap";
import one from "../../../../../images/customer/one.png";
import two from "../../../../../images/customer/two.png";
import Searchbar from "../../../../../common/searchbar/Searchbar";

interface ChatSidebarProps { }

const ChatSidebar: React.FC<ChatSidebarProps> = () => {
  const handleCustomSearch = () =>{
    console.log("search")
  }
  return (
    <div>
      <div className="side_agent">
      <Searchbar
            handleSearch={handleCustomSearch}
            placeholder="Search"
            inputColor="#808282"
            buttonColor="#B8B8B8"
            customStyles={{ border: "1px solid #E5E6E7", width: "100%" }}
          />
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #251</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
            <Image src={one} alt="img" />
          </div>
        </div>
        <div className="agent_item_list active_agent py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Work Order #452</h5>
              <p> Typing...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
            <Image src={two} alt="img" />
          </div>
        </div>
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #54</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
          </div>
        </div>
        {/* add sone chat user for sidebar chat */}
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #54</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
          </div>
        </div>
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #54</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
          </div>
        </div>
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #54</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
          </div>
        </div>
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #251</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
            <Image src={one} alt="img" />
          </div>
        </div>
        <div className="agent_item_list py-3">
          <div className="agent_name">
            <div className="agent_text">
              <h5>Booking #251</h5>
              <p> Hello! I'm interested in purchasing yo...</p>
            </div>
          </div>
          <div className="agent_mode">
            <p>09.15</p>
            <Image src={one} alt="img"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatSidebar;
