import order from "../../images/customer/order.png";
import work from "../../images/customer/work.png";
import workline from "../../images/customer/work-line.png";
import orderline from "../../images/customer/order-line.png";
import Icon1 from "../../images/customer/icon1.png";
import Icon2 from "../../images/customer/icon 2.png";
import graph from "../../images/customer/graph 2.png";
import home from "../../images/customer/home-table.png";
import homeicon from "../../images/login/home.png";
import agent from "../../images/login/agent.png";
import facility from "../../images/login/facility.png";
import properties from "../../images/agent/properties.png";
import propertiesline from "../../images/agent/properties-line.png";
import booking from "../../images/agent/booking.png";
import bookingline from "../../images/agent/booking-line.png";
import token from "../../images/agent/tooken.png";
import tookens from "../../images/agent/tookens.png";
import image1 from "../../images/agent/image1.png";
import image2 from "../../images/agent/image2.png";
import image3 from "../../images/agent/image3.png";
import image4 from "../../images/agent/image4.png";
import image5 from "../../images/agent/image5.png";
import user from "../../images/agent/user.png";
import workImg from "../../images/landload/work.png";
import worklineImg from "../../images/landload/work-line.png";
import orderImg from "../../images/landload/orderImg.png";
import orderlineImg from "../../images/landload/order-line.png";
import propertiesImg from "../../images/landload/propertiesImg.png";
import propertieslineImg from "../../images/landload/propertieslineImg.png";
import tenantsImg from "../../images/landload/tenants.png";
import tenantslineImg from "../../images/landload/tenantslineImg.png";
import teamImg from "../../images/landload/team.png";
import teamlineImg from "../../images/landload/teamlineImg.png";
import green from "../../images/landload/green.png";
import black from "../../images/customer/black-dot.png";
import avtar1 from "../../images/landload/avtar1.png";
import avtar2 from "../../images/landload/avtar2.png";
import avtar3 from "../../images/landload/avtar3.png";
import avtar4 from "../../images/landload/avtar4.png";
import avtar5 from "../../images/landload/avtar5.png";
import React from "react";



interface NavHeaderItem {
  title: string;
  link: string;
  icon: string;
}

interface AccountDetailsProps {
  image: any;
  title: string;
  discription: string;
  link: any;
}

export const navheaderitems: NavHeaderItem[] = [
  {
    title: "(327)037-8499",
    link: "/",
    icon: "bi bi-telephone-fill",
  },
  {
    title: "alice.wells@mail.com",
    link: "/not-found",
    icon: "bi bi-envelope-fill",
  },
];

export const navmenuitems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Properties",
    link: "/properties",
  },
  {
    title: "Bookings",
    link: "/booking",
  },
  {
    title: "Chat",
    link: "/chat",
  },
];

export const footerlinkitem = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Properties",
    link: "/",
  },
  {
    title: "Login",
    link: "/",
  },
  {
    title: "Register",
    link: "/",
  },
];

export const footersupportitem = [
  {
    title: "Contact Us",
    link: "/",
  },
  {
    title: "Support",
    link: "/",
  },
  {
    title: "Help",
    link: "/",
  },
];

export const footerfaqitem = [
  {
    title: "Account",
    link: "/",
  },
  {
    title: "Terms & Conditions",
    link: "/",
  },
  {
    title: "Privacy Policy",
    link: "/",
  },
];

export const footerhelpitem = [
  {
    title: "Terms & Conditions",
    link: "/",
  },
  {
    title: "Privacy Policy",
    link: "/",
  },
  {
    title: "Cookies Policy",
    link: "/",
  },
];

export const cardData = [
  {
    cardImg: order,
    cardTitle: "My Bookings",
    cardNumber: 2,
    img: orderline,
    number: 0,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: work,
    cardTitle: "Work Requests",
    cardNumber: 69,
    img: workline,
    number: "10 +",
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
];



interface AgentCardDataProps {
  cardImg: any,
  cardTitle: string,
  cardNumber: number,
  img: any,
  number: any,
  discription: string,
}

export const facilityCardData = [
  {
    cardImg: Icon2,
    cardTitle: "Work Requests",
    cardNumber: 69,
    img: workline,
    number: 10,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: Icon1,
    cardTitle: "Work Orders",
    cardNumber: 26,
    img: graph,
    number: 2,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
]


export const TableData = [
  {
    id: "1",
    picture: home,
    username: "Admin",
    property: "The Oak Court",
    checkIn: "28 Apr, 2023",
    checkOut: "05 May, 2023",
    actualPrice: "₦ 30,000",
    negotiablePrice: "₦ 25,000",
  },
  {
    id: "2",
    picture: home,
    username: "Admin",
    property: "The Oak Court",
    checkIn: "28 Apr, 2023",
    checkOut: "05 May, 2023",
    actualPrice: "₦ 30,000",
    negotiablePrice: "₦ 25,000",
  },
  {
    id: "3",
    picture: home,
    username: "Admin",
    property: "The Oak Court",
    checkIn: "28 Apr, 2023",
    checkOut: "05 May, 2023",
    actualPrice: "₦ 30,000",
    negotiablePrice: "₦ 25,000",
  },
];

export const workOrderData = [
  {
    picture: home,
    username: "Admin",
    requestBy:"Oak",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    category: "Plumbing",
    discription: "Leaking toilet in master bedroom",
  },
  {
    picture: home,
    username: "Admin",
    requestBy:"Oak",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    category: "Plumbing",
    discription: "Leaking toilet in master bedroom",
  },
  {
    picture: home,
    username: "Admin",
    requestBy:"Oak",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    category: "Plumbing",
    discription: "Leaking toilet in master bedroom",
  },
];

interface LoginDiscriptionProps {
  title: string;
  discription: string;
}

export const loginDiscription: LoginDiscriptionProps[] = [
  {
    title: "Choose Account",
    discription:
      "Our platform offers multiple account options to give you the flexibility to choose the features you need. Whether you're a buyer, seller, agent, or facility manager, we have an account type to suit your needs. With each account, you can access a range of features and tools to help you navigate the real estate market with ease. Simply select the account type that best fits your needs and start exploring!",
  },
];

export const accountDetails: AccountDetailsProps[] = [
  {
    image: homeicon,
    title: "Home Owner / Landlord",
    discription:
      " Create a profile, view dashboard, manage & assign properties to agents, bookings, and work orders.",
      link: "/landlord-dashboard",
  },
  {
    image: agent,
    title: "Agent",
    discription:
      " Create a profile, view the dashboard, and manage properties, bookings, and generate work orders.",
    link: "/agent-dashboard",
  },
  {
    image: facility,
    title: "Facility Manager",
    discription:
      " Create a profile, view the dashboard, and manage work orders.",
    link: "/facility-dashboard",
  },
];

export const facilityOrderData = [
  {
    picture: home,
    username: "C1234",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    dueDate: "08 May, 2023",
    category: "Plumbing",
  },
  {
    picture: home,
    username: "C1235",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    dueDate: "08 May, 2023",
    category: "Plumbing",
  },
  {
    picture: home,
    username: "C1236",
    property: "The Oak Court",
    work: "WR12",
    issueData: "05 May, 2023",
    dueDate: "08 May, 2023",
    category: "Plumbing",
  },
];

// agent Data 
export const agentCardData= [
  {
    cardImg: properties,
    cardTitle: "Assigned Properties",
    cardNumber: 12,
    img: propertiesline,
    number: 3,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: booking,
    cardTitle: "Bookings",
    cardNumber: 26,
    img: bookingline,
    number: 2,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },

]

export const agentDashboardCard = [
  {
    cardTitle: "Token Balance",
    img: token,
  }
]

export const PropertyList = [
  {
    picture: home,
    title: 'Brume Villa',
    country: 'Nigeria',
    state: 'Lagos',
    city: 'Eti Osa',
    category: 'Short Let',
    amenities: 'Bathrooms (x2)',
    features: 'Radiator, WiFi',
    price: '₦ 30,000',
  },
  {
    picture: home,
    title: 'Brume Villa',
    country: 'Nigeria',
    state: 'Lagos',
    city: 'Eti Osa',
    category: 'Short Let',
    amenities: 'Bathrooms (x2)',
    features: 'Radiator, WiFi',
    price: '₦ 30,000',
  },
  {
    picture: home,
    title: 'Brume Villa',
    country: 'Nigeria',
    state: 'Lagos',
    city: 'Eti Osa',
    category: 'Short Let',
    amenities: 'Bathrooms (x2)',
    features: 'Radiator, WiFi',
    price: '₦ 30,000',
  },
]

export const PropertyListDetails = [
  {
    label: 'Title',
    value: 'Brume Villa'
  }
]

export const agentBookingActive = [
  {
    bookedId:'1',
    picture: home,
    bookedPrice:'₦ 3,000',
    bookedBy: 'Admin',
    property: 'Nigeria',
    agentCode: 'Lagos',
    checkIn: 'Eti Osa',
    actualPrice: '₦ 30,000',
    negotiablePrice: '₦ 50,000',
    price: '₦ 30,000',
  },
  {
    bookedId:'2',
    picture: home,
    bookedPrice:'₦ 3,000',
    bookedBy: 'Admin',
    property: 'Nigeria',
    agentCode: 'Lagos',
    checkIn: 'Eti Osa',
    actualPrice: '₦ 30,000',
    negotiablePrice: '₦ 50,000',
    price: '₦ 30,000',
  },
  {
    bookedId:'2',
    picture: home,
    bookedPrice:'₦ 3,000',
    bookedBy: 'Admin',
    property: 'Nigeria',
    agentCode: 'Lagos',
    checkIn: 'Eti Osa',
    actualPrice: '₦ 30,000',
    negotiablePrice: '₦ 50,000',
    price: '₦ 30,000',
  },
]
export const tokenData = [
  {
    title: "Total Tokens",
    number: '10,500',
    img: tookens,
  }
]
export const walletdata = [
  {
    reward: "Reward Earn",
    activity: "Activity",
    rewardData: "28 Apr, 2023",
    activeData: "Weekly Tokens",
    number: "+ 200"
  },
  {
    reward: "Reward Earn",
    activity: "Activity",
    rewardData: "28 Apr, 2023",
    activeData: "Weekly Tokens",
    number: "+ 200"
  },
  {
    reward: "Token Spent",
    activity: "Activity",
    rewardData: "28 Apr, 2023",
    activeData: "Property Boost",
    number: "- 500"
  }
]

export const images = [
  {
    img: image1
  },
  {
    img: image2
  },
  {
    img: image3
  },
  {
    img: image4
  },
  {
    img: image5
  }
]

export const agentwalletdata = {
  data: [
    {
      title: "Title",
      label: "Brume Villa",
    },
    {
      title: "Category",
      label: "Short Let"
    },
    {
      title: "Price",
      label: "₦ 30,000"
    }
  ],
  datatwo: [
    {
      title: "Taxes",
      label: "₦ 1,500",
    },
    {
      title: "Service Charges (Flat)",
      label: "₦ 500"
    },
    {
      title: "Other Fees (Flat)",
      label: "₦ 100"
    }
  ],
  datathree: [
    {
      title: "Service Charges (Flat)",
      label: "https://virtuallink.com/brume-villa"
    },
    {
      title: "Is Featured",
      label: "Yes"
    },
    {
      title: "Features",
      label: "Radiator, WiFi, Room Services"
    }
  ],
  datafour: [
    {
      title: "Amenities",
      labels: [
        { name: "Bathroom(s)" },
        { name: "Room(s)" },
        { name: "Guest(s)" }
      ]
    },
    {
      title: "Quantity",
      labels: [
        { name: "x2" },
        { name: "x2" },
        { name: "x2" }
      ]
    }
  ],
  datafive: [
    {
      title: "Country",
      label: "Nigeria"
    },
    {
      title: "State",
      label: "Lagos",

    },
    {
      title: "City",
      label: "₦ 30,000"
    }
  ],
  datasix: [
    {
      image: user,
      title: "Olivia Stark",
      label: "12345"
    }
  ]
};

// Landload data
export const landloadDashboardData = [
  {
    cardImg: workImg,
    cardTitle: "Work Requests",
    cardNumber: 69,
    img: worklineImg,
    number: '10+',
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: orderImg,
    cardTitle: "Work Orders",
    cardNumber: 26,
    img: orderlineImg,
    number: '2+',
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: propertiesImg,
    cardTitle: "Properties",
    cardNumber: 6,
    img: propertieslineImg,
    number: '1+',
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: tenantsImg,
    cardTitle: "Tenants",
    cardNumber: 12,
    img: tenantslineImg,
    number: 0,
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  },
  {
    cardImg: teamImg,
    cardTitle: "Team",
    cardNumber: 13,
    img: teamlineImg,
    number: '1+',
    discription: (
      <React.Fragment>
        more <br /> from last week
      </React.Fragment>
    ),
  }

];

export const landloadData = [
  {
    image: green,
    title: "Work Requests"
  },
  {
    image: black,
    title: "Work Order"
  },
  {
    image: black,
    title: "Assets"
  },
  {
    image: black,
    title: "Tenants"
  },
  {
    image: black,
    title: "Team"
  }
]

export const agentmodalData = [
  {
    image: avtar1,
    name: "Daniel Lee",
    id: '12345'
  },
  {
    image: avtar2,
    name: "Ava Chen",
    id: '12345'
  },
  {
    image: avtar3,
    name: "Grace Kim",
    id: '12345'
  },
  {
    image: avtar4,
    name: "Christopher Davis",
    id: '12345'
  },
  {
    image: avtar5,
    name: "Mia Anderson",
    id: '12345'
  },

]

export const visitorData = [
  {
    name: "Ethan Baker",
    type: "One-Time",
    typeCode: "12345",
    issueDate: "28 Apr, 2023",
    issueTime: "08:00 AM",
    address: "123 Main St, Anytown, USA",
    facilityManager: "Daniel Lee"
  },
  {
    name: "Ethan Baker",
    type: "One-Time",
    typeCode: "12345",
    issueDate: "28 Apr, 2023",
    issueTime: "08:00 AM",
    address: "123 Main St, Anytown, USA",
    facilityManager: "Daniel Lee"
  },
  {
    name: "Ethan Baker",
    type: "One-Time",
    typeCode: "12345",
    issueDate: "28 Apr, 2023",
    issueTime: "08:00 AM",
    address: "123 Main St, Anytown, USA",
    facilityManager: "Daniel Lee"
  }
]