import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Container, Row, Col, Image } from "react-bootstrap";
import house01 from "../../../../images/houser11.png";
import house02 from "../../../../images/houser12.png";
import house03 from "../../../../images/houser13.png";
import house04 from "../../../../images/houser14.png";
import house07 from "../../../../images/houser15.png";

const image = [
  { img: house07, id: "1" },
  { img: house02, id: "2" },
  { img: house03, id: "3" },
  { img: house04, id: "4" },
  { img: house02, id: "5" },
  { img: house01, id: "6" },

];

const PropertyBanner: React.FC = () => {
  return (
    <section id="detail_banner" className="detail_banner pb-3 pb-lg-5">
      <Container>
        <Row className="g-4">
          <Col lg={9}>
            <div className="bg-detail">
              <Carousel fade>
                {image.map((item, index) => (
                  <Carousel.Item key={index}>
                    <Image
                      src={item.img}
                      className="details-banner-img"
                      alt={`Image ${index + 1}`}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </Col>
          <Col lg={3}>
            <Row className="row-cols-1 row-cols-md-2 g-4">
              {image.map((item, index) => (
                <Col key={index}>
                  <div
                    className="side_detail_pic"
                  >
                    <img src={item.img} alt={`img ${index + 1}`} />
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PropertyBanner;
