import React, { useState } from "react";
import { Modal, Form, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import home from "../../images/customer/home-table.png";
import added from "../../images/customer/added.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import DatePicker from "react-datepicker";



interface CreateWorkModalProps {
    show: boolean;
    onHide: () => void;
    isEdit: boolean;
}
const CreateWorkModal: React.FC<CreateWorkModalProps> = ({ show, onHide, isEdit }) => {
    const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);

    const handleClose = () => {
        onHide();
    };

    const handleCheckInDate = (date: Date | null) => {
        setSelectedChcekInDate(date);
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>
            <Modal.Header className="border-bottom-0">
                <Modal.Title className="mx-auto">
                    <span className="header-modal">{isEdit ? "View Work Order" : "Create Work Order"}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="agent_modal_body">
                <Form>
                    <div className="row">
                        <Form.Group className="col-md-6 mb-3" controlId="title">
                            <Form.Label className="label-modal">
                                Title<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Title"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="priority">
                            <Form.Label className="label-modal">
                                Category<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control as="select" className="form-input-modal form-height">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="title">
                            <Form.Label className="label-modal d-block ">
                                Due Date<span className="text-danger">*</span>
                            </Form.Label>
                            <DatePicker
                                selected={selectedChcekInDate}
                                onChange={handleCheckInDate}
                                dateFormat="dd/MM/yyyy"
                                className="form-input-modal form-height form-control "
                                placeholderText="29 Apr, 2023"
                                minDate={new Date()}
                            />
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="priority">
                            <Form.Label className="label-modal">
                                Priority<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control as="select" className="form-input-modal form-height">
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="col-md-12 mb-3" controlId="description">
                            <Form.Label className="label-modal">
                                Description<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Write description"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="col-md-12 mb-3" controlId="uploadImages">
                        <Form.Label className="label-modal">Upload Images</Form.Label>
                        <div className="upload_sec py-3">
                            <div className="upload_images">
                                <Image src={home} alt="upload-1" />
                            </div>
                            <div className="upload_images">
                                <Image src={home} alt="upload-2" />
                            </div>
                            <div className="upload_images">
                                <Image src={home} alt="upload-3" />
                            </div>
                            <div className="property_upload">
                                <Form.Label htmlFor="myfile" className="upload--btn">
                                    Add
                                    <br /> <Image src={added} alt="img-add" />
                                </Form.Label>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="modal-delete border-top-0 justify-content-center">
                <Button
                    variant="variant"
                    className="modal_cancel_btn"
                    type="submit"
                    onClick={() => console.log("click")}
                >
                    Create
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateWorkModal;
