import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../components/userpenal/Header/Header";
import Footer from "../components/userpenal/Footer/Footer";
import NotFound from "../components/userpenal/notFound/NotFound";
import NavHeader from "../components/userpenal/Header/navHeader/NavHeader";
import Properties from "../components/userpenal/Properties/Properties";
import HomeBanner from "../components/userpenal/HomeBanner/HomeBanner";
import Booking from "../components/userpenal/myBooking/relatedProperties/Booking";
import AllBooking from "../components/userpenal/allBooking/AllBooking";
import ChatComponent from "../components/userpenal/chat/ChatComponent";
import PropertyDetails from "../components/userpenal/PropertyDetails/PropertyDetails";
import Login from "../components/customerpenal/auth/login/Login";
import CustomerDashboard from "../components/customerpenal/dashboard/CustomerDashboard";
import FacilityManagement from "../components/customerpenal/facilityManagement/FacilityManagement";
import ChatCustomer from "../components/customerpenal/chat/ChatCustomer";
import ProfileComponent from "../components/customerpenal/Profile/ProfileComponent";
import SignupCustomer from "../components/customerpenal/auth/signup/SignupCustomer";
import ActiveCustomer from "../components/customerpenal/customerBooking/Active/ActiveCustomer";
import HistoryCustomer from "../components/customerpenal/customerBooking/History/HistoryCustomer";
import ArchiveComponent from "../components/customerpenal/customerBooking/Archive/ArchiveComponent";
import FacilityLogin from "../components/facilitymanagerpenal/auth/FacilityLogin";
import FacilitySignUp from "../components/facilitymanagerpenal/auth/FacilitySignUp";
import FacilityDashboard from "../components/facilitymanagerpenal/component/dashboard/FacilityDashboard";
import FacilityProfile from "../components/facilitymanagerpenal/component/facilityProfile/FacilityProfile";
import WorkRequest from "../components/facilitymanagerpenal/component/facilityManagement/workRequest/WorkRequest";
import OrderRequest from "../components/facilitymanagerpenal/component/facilityManagement/orderRequest/OrderRequest";
import CompletedComponent from "../components/facilitymanagerpenal/component/facilityManagement/completedComponent/CompletedComponent";
import WorkOderRequest from "../components/facilitymanagerpenal/component/facilityManagement/workOrderRequest/WorkOderRequest";
import FacilityChat from "../components/facilitymanagerpenal/component/facilityChat/FacilityChat";
import ForgotPassword from "../components/customerpenal/auth/forgotPassword/ForgotPassword";
import ResetPassword from "../components/customerpenal/auth/resetPassword/ResetPassword";
import AgentDashboard from "../components/agentpanel/component/dashboard/AgentDashboard";

import AssignedPropertyDetails from "../components/agentpanel/component/assignedPropertyDetails/AssignedPropertyDetails";
import HistoryAgent from "../components/agentpanel/component/agentBooking/History/HistoryAgent";
import ArchiveAgent from "../components/agentpanel/component/agentBooking/Archive/ArchiveAgent";
import ActiveAgent from "../components/agentpanel/component/agentBooking/Active/ActiveAgent";
import AgentFacilityManagement from "../components/agentpanel/component/agentFacilityManagement/AgentFacilityManagement";
import AgentWorkCompleted from "../components/agentpanel/component/agentFacilityManagement/agentWorkCompleted/AgentWorkCompleted";
import WalletComponent from "../components/agentpanel/component/walletComponent/WalletComponent";
import AgentProfile from "../components/agentpanel/agentProfile/AgentProfile";
import LandloadDashboard from "../components/landloadownerpenal/component/landloadDashboard/LandloadDashboard";
import LandloadManageProperties from "../components/landloadownerpenal/component/landloadManageProperties/LandloadManageProperties";
import AddNewProperties from "../components/landloadownerpenal/component/landloadManageProperties/addNewProperties/AddNewProperties";
import ActiveLandload from "../components/landloadownerpenal/component/landloadBookings/active/ActiveLandload";
import HistoryLandload from "../components/landloadownerpenal/component/landloadBookings/history/HistoryLandload";
import ArchiveLandload from "../components/landloadownerpenal/component/landloadBookings/archive/ArchiveLandload";
import WorkRequestLandload from "../components/landloadownerpenal/component/landloadFacilityManagement/workRequestLandload/WorkRequestLandload";
import WorkOrderLandload from "../components/landloadownerpenal/component/landloadFacilityManagement/workOrderLandload/WorkOrderLandload";
import WorkOderComponent from "../components/landloadownerpenal/component/landloadFacilityManagement/workOrderDetailsLandload/WorkOderComponent";
import WorkCompletedLandload from "../components/landloadownerpenal/component/landloadFacilityManagement/workCompletedLandload/WorkCompletedLandload";
import LandloadWallet from "../components/landloadownerpenal/component/landloadWallet/LandloadWallet";
import LandloadProfile from "../components/landloadownerpenal/component/landloadProfile/LandloadProfile";
import LandloadPropertyDetails from "../components/landloadownerpenal/component/landloadManageProperties/propertyDetails/LandloadPropertyDetails";
import LandloadVisitorsManagement from "../components/landloadownerpenal/component/landloadVisitorsManagement/LandloadVisitorsManagement";
import AssignedPropertiesAgent from "../components/agentpanel/component/ assignedProperty/AssignedPropertiesAgent";
import AddNewAgentProperties from "../components/agentpanel/component/addNewAgentProperties/AddNewAgentProperties";
import ChatAgent from "../components/agentpanel/agentChat/ChatAgent";




const Router = () => {
  const path = window?.location?.pathname;
  return (
    <BrowserRouter>
      {path === "/customer-login" ||
        path === "/signup" ||
        path === "/forgot-password" ||
        path === "/reset-password" ||
        path === "/dashboard" ||
        path === "/customer-booking" ||
        path === "/active" ||
        path === "/history" ||
        path === "/work-request" ||
        path === "/archive-component" ||
        path === "/profile" ||
        path === "/customer-chat" ||
        path === "/login" ||
        path === "/facility-signup" ||
        path === "/facility-dashboard" ||
        path === "/facility-profile" ||
        path === "/facility-work-request" ||
        path === "/facility-order-request" ||
        path === "/facility-completed" ||
        path === "/facility-work-oder-request" ||
        path === "/facility-chat" ||
        path === "/agent-dashboard" ||
        path === "/agent-property" ||
        path === "/active-agent" ||
        path === "/history-agent" ||
        path === "/archive-agent" ||
        path === "/agent-work-request" ||
        path === "/agent-property-details" ||
        path === "/agent-add-new-properties" ||
        path === "/agent-work-completed" ||
        path === "/agent-wallet" ||
        path === "/agent-profile" ||
        path === "/agent-chat" ||
        path === "/landlord-dashboard" ||
        path === "/landlord-manage-properties" ||
        path === "/landlord-add-new-properties" ||
        path === "/landlord-property-details" ||
        path === "/active-landlord" ||
        path === "/history-landlord" ||
        path === "/archive-landlord" ||
        path === "/landlord-work-request" ||
        path === "/landlord-work-order" ||
        path === "/landlord-work-order-component" ||
        path === "/landlord-work-completed" ||
        path === "/landlord-visitors-management" ||
        path === "/landlord-wallet" ||
        path === "/landlord-profile" ? null : (
        <>
          <Header />
          <NavHeader />
        </>
      )}

      <Routes>
        {/* User Router */}
        <Route path="/" element={<HomeBanner />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/my-booking" element={<Booking />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/properties-details" element={<PropertyDetails />} />
        <Route path="/booking" element={<AllBooking />} />
        <Route path="/chat" element={<ChatComponent />} />

        {/* Customer Routes */}
        <Route path="/customer-login" element={<Login />} />
        <Route path="/signup" element={<SignupCustomer />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/dashboard" element={<CustomerDashboard />} />
        <Route path="/work-request" element={<FacilityManagement />} />
        <Route path="/active" element={<ActiveCustomer />} />
        <Route path="/history" element={<HistoryCustomer />} />
        <Route path="/archive-component" element={<ArchiveComponent />} />
        <Route path="/profile" element={<ProfileComponent />} />
        <Route path="/customer-chat" element={<ChatCustomer />} />

        {/* Facility Routes */}
        <Route path="/login" element={<FacilityLogin />} />
        <Route path="/facility-signup" element={<FacilitySignUp />} />
        <Route path="/facility-dashboard" element={<FacilityDashboard />} />
        <Route path="/facility-profile" element={<FacilityProfile />} />
        <Route path="/facility-work-request" element={<WorkRequest />} />
        <Route path="/facility-order-request" element={<OrderRequest />} />
        <Route path="/facility-completed" element={<CompletedComponent />} />
        <Route path="/facility-work-oder-request" element={<WorkOderRequest />} />
        <Route path="/facility-chat" element={<FacilityChat />} />

        {/* Agent Routes */}
        <Route path="/agent-dashboard" element={<AgentDashboard />} />
        <Route path="/agent-property" element={<AssignedPropertiesAgent />} />
        <Route path="/agent-property-details" element={<AssignedPropertyDetails />} />
        <Route path="/agent-add-new-properties" element={<AddNewAgentProperties />} />
        <Route path="/active-agent" element={<ActiveAgent />} />
        <Route path="/history-agent" element={<HistoryAgent />} />
        <Route path="/archive-agent" element={<ArchiveAgent />} />
        <Route path="/agent-work-request" element={<AgentFacilityManagement />} />
        <Route path="/agent-work-completed" element={<AgentWorkCompleted />} />
        <Route path="/agent-wallet" element={<WalletComponent />} />
        <Route path="/agent-profile" element={<AgentProfile />} />
        <Route path="/agent-chat" element={<ChatAgent />} />

        {/* Landload Router */}
        <Route path="/landlord-dashboard" element={<LandloadDashboard />} />
        <Route path="/landlord-manage-properties" element={<LandloadManageProperties setIsEdit={false} />} />
        <Route path="/landlord-add-new-properties" element={<AddNewProperties />} />
        <Route path="/landlord-property-details" element={<LandloadPropertyDetails />} />
        <Route path="/active-landlord" element={<ActiveLandload />} />
        <Route path="/history-landlord" element={<HistoryLandload />} />
        <Route path="/archive-landlord" element={<ArchiveLandload />} />
        <Route path="/landlord-work-request" element={<WorkRequestLandload />} />
        <Route path="/landlord-work-order" element={<WorkOrderLandload />} />
        <Route path="/landlord-work-order-component" element={<WorkOderComponent />} />
        <Route path="/landlord-work-completed" element={<WorkCompletedLandload />} />
        <Route path="/landlord-visitors-management" element={<LandloadVisitorsManagement />} />
        <Route path="/landlord-wallet" element={<LandloadWallet />} />
        <Route path="/landlord-profile" element={<LandloadProfile />} />
      </Routes>

      {path === "/customer-login" ||
        path === "/signup" ||
        path === "/forgot-password" ||
        path === "/reset-password" ||
        path === "/dashboard" ||
        path === "/customer-booking" ||
        path === "/work-request" ||
        path === "/active" ||
        path === "/history" ||
        path === "/archive-component" ||
        path === "/profile" ||
        path === "/customer-chat" ||
        path === "/login" ||
        path === "/facility-signup" ||
        path === "/facility-dashboard" ||
        path === "/facility-profile" ||
        path === "/facility-work-request" ||
        path === "/facility-order-request" ||
        path === "/facility-completed" ||
        path === "/facility-work-oder-request" ||
        path === "/facility-chat" ||
        path === "/agent-dashboard" ||
        path === "/agent-property" ||
        path === "/active-agent" ||
        path === "/history-agent" ||
        path === "/archive-agent" ||
        path === "/agent-work-request" ||
        path === "/agent-property-details" ||
        path === "/agent-add-new-properties" ||
        path === "/agent-work-completed" ||
        path === "/agent-wallet" ||
        path === "/agent-profile" ||
        path === "/agent-chat" ||
        path === "/landlord-dashboard" ||
        path === "/landlord-manage-properties" ||
        path === "/landlord-add-new-properties" ||
        path === "/landlord-property-details" ||
        path === "/active-landlord" ||
        path === "/history-landlord" ||
        path === "/archive-landlord" ||
        path === "/landlord-work-request" ||
        path === "/landlord-work-order" ||
        path === "/landlord-work-order-component" ||
        path === "/landlord-work-completed" ||
        path === "/landlord-visitors-management" ||
        path === "/landlord-wallet" ||
        path === "/landlord-profile" ? null : (
        <Footer />
      )}
    </BrowserRouter>
  );
};

export default Router;
