import React, { useState } from "react";
import { Container, Navbar, Nav, Form, Image, Col } from "react-bootstrap";
import logo from "../../../../images/login/white-logo.png";
import user from "../../../../images/login/user-login.png";
import email from "../../../../images/login/email-login.png";
import close from "../../../../images/customer/hide.svg";
import open from "../../../../images/customer/open.svg";
import password from "../../../../images/login/password-login.png";
import Button from "../../../../common/Button/Button";

const SignupCustomer: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);


  return (
    <div>
      <section className="owner_login">
        <Col lg={6} className="left-side customer_left position-relative">
          <div className="left_content">
            <div className="owner_logo py-3">
              <a href="/">
                <Image src={logo} alt="img" />
              </a>
            </div>

            <div className="welcome_text login-paragraph">
              <p className="login-p-tag">
                <span>Welcome,</span> We are glad
                <br />
                to see you again!
              </p>
            </div>
          </div>
        </Col>

        <Col lg={6} className="right-side customer_right position-relative">
          <Navbar className="bg-none" expand="lg">
            <Container fluid className="navbar-collapse admin_toggle ">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Nav.Link className="header_nav_link" href="/">Home</Nav.Link>
                  <Nav.Link className="header_nav_link" href="/">About Us</Nav.Link>
                  <Nav.Link className="header_nav_link" href="/">Contact Us</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <div className="login_form">
            <h3>Create account</h3>
            <Form.Group className="my-4 owner_input position-relative">
              <Form.Control type="text" placeholder="Full Name" />
              <span>
                <Image src={user} alt="img" />
              </span>
            </Form.Group>

            <Form.Group className="my-4 owner_input position-relative">
              <Form.Control type="text" placeholder="Email address" />
              <span>
                <Image src={email} alt="img" />
              </span>
            </Form.Group>

            <Form.Group className="my-4 owner_input position-relative">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Password" />
              <span>
                <Image src={password} alt="img" />
              </span>
              <Image
                src={showPassword ? open : close}
                className='show-password'
                onClick={() => setShowPassword(!showPassword)}
                alt="password"
              />
            </Form.Group>

            <Form.Group className="my-4 owner_input position-relative">
              <Form.Control
                className='form-control-forgot'
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Confirm Password" />
              <span className='password-icon'>
                <Image src={password} alt="img" />
              </span>
              <Image
                src={showConfirmPassword ? open : close}
                className='show-password'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                alt="password"
              />
            </Form.Group>

            <Form.Group className="my-3 d-flex justify-content-between align-items-center">
              <Form.Check className="mb-3">
                <Form.Check.Input
                  className="border-checkbox"
                  type="checkbox"
                  id="gridCheck"
                />
                <Form.Check.Label htmlFor="gridCheck">
                  I agree to be bound by these{" "}
                  <a href="/"> Terms & Condition </a>
                  and <br />
                  <a href="/">User Agreement.</a>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>

            <div className="owner_btn">
              <Button type="submit" className="w-100 btn btn-primary">
                Register
              </Button>
            </div>
          </div>
          <p className="register_login">
            Already have an account? <a href="/customer-login">Login</a>
          </p>
        </Col>
      </section>
    </div>
  );
};

export default SignupCustomer;
