import test_1 from '../../images/test-1.png';
import test_2 from '../../images/test-2.png';
import test_3 from '../../images/test-3.png';
import notification from '../../images/noti-1.png';

export interface ChatUser {
    userName: string;
    userMsg: string;
    userTime: string;
    img: string;
}

export const cardData = [
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        checkIn: '06 May, 2023',
        checkOut: '08 May, 2023',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        checkIn: '06 May, 2023',
        checkOut: '08 May, 2023',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        checkIn: '06 May, 2023',
        checkOut: '08 May, 2023',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        checkIn: '06 May, 2023',
        checkOut: '08 May, 2023',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    }
]

export const chatUserData: ChatUser[] = [
    {
        userName: 'Booking #251',
        userMsg: 'Hello! I am interested in purchasing yo...',
        userTime: '09.15',
        img: notification,
    },

    // {
    //     userName: 'Work Order #452',
    //     userMsg: 'Typing...',
    //     userTime: '09.15',
    //     img: notification,
    // },

    {
        userName: 'Booking #54',
        userMsg: 'Hello! I am interested in purchasing yo...',
        userTime: '09.15',
        img: notification,
    },
    {
        userName: 'Booking #57',
        userMsg: 'Hello! I am interested in purchasing yo...',
        userTime: '09.15',
        img: notification,
    }
]


export const propertiesCardData = [
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
    {
        img: test_1,
        img02: test_2,
        img03: test_3,
        cardUser: '10',
        badNumber: '5',
        cardtitle: 'Short Let',
        cardHeading: '2 Bedroom apartment',
        cardRate: '150',
        locationApt: 'Leki - Apartment',
        discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum.',
    },
]