import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ChatHeader: React.FC = () => {
  return (
    <Container fluid>
      <Row className="d-block">
        <Col>
          <div className="chat_header">
            <div className="position-relative agent_logo">
              <div className="flex-grow-1 pl-3 chat_agent">
                <h5>Work Order #452</h5>
              </div>
            </div>

            <div className="chat_bedroom">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="40"
                  height="40"
                  rx="8"
                  fill="#229988"
                  fillOpacity="0.1"
                />
                <path
                  d="M24 10H16C12 10 10 12 10 16V29C10 29.55 10.45 30 11 30H24C28 30 30 28 30 24V16C30 12 28 10 24 10ZM23.5 20.75H20.75V23.5C20.75 23.91 20.41 24.25 20 24.25C19.59 24.25 19.25 23.91 19.25 23.5V20.75H16.5C16.09 20.75 15.75 20.41 15.75 20C15.75 19.59 16.09 19.25 16.5 19.25H19.25V16.5C19.25 16.09 19.59 15.75 20 15.75C20.41 15.75 20.75 16.09 20.75 16.5V19.25H23.5C23.91 19.25 24.25 19.59 24.25 20C24.25 20.41 23.91 20.75 23.5 20.75Z"
                  fill="#163873"
                />
              </svg>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatHeader;
