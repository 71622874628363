import React from "react";
import banner from "../../../../images/inner_banner.png";
const Banner = () => {
  return (
    <div>
      <section id="Properties_banner" className="inner_banner">
        <img src={banner} alt="img" />
      </section>
    </div>
  );
};

export default Banner;
