import React from "react";
import contactIcon from "../../../../images/contact-icon.png";
import whatsappIcon from "../../../../images/whatsapp-icon.png";
import messageIcon from "../../../../images/message-icon.png";
import { Container } from "react-bootstrap";
import Button from "../../../../common/Button/Button";

const ContactUs: React.FC = () => {
  return (
    <Container>
      <section className="contact_us py-3 py-lg-5">
        <div className="container">
          <div className="contact_wrap">
            <div className="contact_heading text-center">
              <h2 className="h2-tag-text">
                Contact <span>Us</span>
              </h2>
            </div>

            <div className="contact_content pt-4">
              <h4 className="send">Send Message</h4>
              <div className="row g-4">
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    className="form-control form-field-input"
                    placeholder="Name"
                    aria-label="Name"
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    className="form-control form-field-input"
                    placeholder="Email"
                    aria-label="Email"
                  />
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <textarea
                      className="form-control form-color"
                      placeholder="Write Message"
                      id="exampleFormControlTextarea1"
                      rows={3}
                    ></textarea>
                  </div>
                </div>
                <div className="view_all w-100 text-center py-3 d-flex justify-content-center align-content-center">
                  <Button
                    type="button"
                    variant="primary"
                    className="btn btn_primary btn_view border-0"
                    onClick={() => console.log("Button clicked")}
                  >
                    Submit
                  </Button>
                </div>
                <div className="col-lg-12 py-5">
                  <div className="or_seprate">
                    <span>or</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex justify-content-start contact_block">
                  <div className="contact_icon">
                    <img src={contactIcon} alt="WhatsApp" />
                  </div>
                  <div className="contact_desc">
                    <h4>Phone</h4>
                    <p>(327) 037-8499</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-start contact_block">
                  <div className="contact_icon">
                    <img src={messageIcon} alt="WhatsApp" />
                  </div>
                  <div className="contact_desc">
                    <h4>Email</h4>
                    <p>alice.wells@mail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex justify-content-start contact_block">
                  <div className="contact_icon">
                    <img src={whatsappIcon} alt="WhatsApp" />
                  </div>
                  <div className="contact_desc">
                    <h4>WhatsApp</h4>
                    <p>
                      <a href="/">Contact via WhatsApp chat</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default ContactUs;
