import React from "react";
import { Row, Col, Card, Form, Image } from "react-bootstrap";
import Layout from "../../layout/layout";
import { landloadDashboardData, landloadData } from "../../../../common/constant/constant";
import graph from "../../../../images/customer/graph.png";
import { TEXT_BLACK, TEXT_GREY } from "../../../../styles/colors";
import DateRange from "../../../../common/dateRangePicker/DateRange";

const LandloadDashboard: React.FC = () => {
    return (
        <div>
            <Layout>
                <main className="main users px-4">
                        <Row className="admin_dashboard">
                            <Col className="dash_user_sec py-3 pb-3 pb-lg-5">
                                {landloadDashboardData.map((item, index) => (
                                    <Card key={index} className="card_single">
                                        <Card.Body className="card_block p-0">
                                            <div className="icon_sec">
                                                <Image src={item.cardImg} alt="img" />
                                                <p>{item.cardTitle}</p>
                                            </div>
                                            <h3>{item.cardNumber}</h3>
                                        </Card.Body>
                                        <div className="card_pic">
                                            <Image src={item.img} alt="img" />
                                            <p>
                                                <span>{item.number}</span> {item.discription}
                                            </p>
                                        </div>
                                    </Card>
                                ))}
                            </Col>
                        </Row>
                    <div className="dash_graph px-3">
                        <div className="d-flex justify-content-between dashboard-menu">
                            <div>
                                <h6 className="text-h6 text-tablet">Analytics</h6>
                            </div>
                            <div className="d-flex gap-2 dashboard-menu-title">
                                {landloadData.map((item, index) => (
                                    <p key={index} className="text-p text-tablet" style={{ color: TEXT_BLACK }}>
                                        {item.title}{" "}
                                        <span>
                                            <Image src={item.image} alt="img" />
                                        </span>
                                    </p>
                                ))}
                            </div>
                            <div className="d-flex gap-3 range_landloard">
                                <div>
                                    <DateRange />
                                </div>
                                <Form.Select
                                    aria-label="Default select example"
                                    className="rounded-pill float-end drop-down-text drop-down-min select-text select_dropdown_landload"
                                    style={{ color: TEXT_GREY }}
                                >
                                    <option value="1">Weekly</option>
                                    <option value="2">Monthly</option>
                                    <option value="3">Annually</option>
                                </Form.Select>
                            </div>
                        </div>

                        <Image className="w-100" src={graph} alt="img" />
                        {/* add banner */}
                             </div>
                              <div className="inner_banner_dash my-3 mx-3 ">
                            <p>
                          Ad <br />Banner
                     </p>
                  </div>
                </main>
            </Layout>
        </div>
    );
};

export default LandloadDashboard;
