import React from "react";
import { Form } from "react-bootstrap";
import agent3 from "../../../../images/agent-3.png";
import onlin_agent from "../../../../images/online_agent.png";
import send from "../../../../images/send.png";
import bothImg from "../../../../images/customer/bothImg.png";
const ChatSection: React.FC = () => {
  return (
    <>
      <div className="position-relative">
        <div className="chat-messages p-4">
          <div className="chat-message-right pb-4">
            <div className="you_right">
              <div>
                <h3 className=" mb-0">You</h3>
                <div className="text-muted text_time small text-nowrap mt-0 pt-1">2:33 am</div>
              </div>

              <div className="right_text">
                <img src={agent3} className="img-fluid" alt="img" />

              </div>
            </div>
            <div className="right_msg py-2 px-3 mr-3">
              Ok thanks for the progress update.
            </div>
            <div className="right_msg py-2 px-3 mr-3">
              <div className="right_msg py-2 px-3 mr-3">
                Wow, that’s okay, really, where are we going on <br />vacation?
              </div>
            </div>
          </div>

          <div className="chat-message-left pb-4">
            <div>
              <img src={onlin_agent} className="img-fluid" alt="img" />
            </div>
            <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
              <div className="cht_time">
                <h3>Amelia Greene</h3>
                <div className="text-muted small text-nowrap">2:34 am</div>
              </div>
              <p>
                Hi, this is progress update for this week’s<br />
                <br />Don’t you feel bored? Let’s go on vacation tomorrow
              </p>
            </div>
          </div>

          <div className="chat-message-right pb-4">
            <div className="you_right">
              <div>
                <h3 className=" mb-0">You</h3>
                <div className="text-muted text_time small text-nowrap mt-0 pt-1">2:33 am</div>
              </div>

              <div className="right_text">
                <img src={agent3} className="img-fluid" alt="img" />

              </div>
            </div>
            <div className="right_msg py-2 px-3 mr-3">
              Ok thanks for the progress update
            </div>
            <div className="right_msg py-2 px-3 mr-3">
              <div className="right_msg py-2 px-3 mr-3">
                Wow, that’s okay, really, where are we going on <br /> vacation?
              </div>
            </div>
          </div>

          <div className="chat-message-left pb-4">
            <div>
              <img src={onlin_agent} className="img-fluid" alt="img" />
            </div>
            <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
              <div className="cht_time">
                <h3>Amelia Greene</h3>
                <div className="text-muted small text-nowrap">2:34 am</div>
              </div>
              <p>
                I have a good idea, how about we see the green <br />scenery in my grandmother's hometown, so we can <br />fresh there
              </p>
              <img src={bothImg} alt="img" className="pt-3" />
            </div>
          </div>
        </div>
      </div>
      <div className="input-group chat_footer position-relative" style={{ height: "46px" }}>
        <Form.Control
          style={{ padding: "15px 100px 15px 15px", height: "46px", }}
          type="text"
          placeholder="Type your message here"
        />
        <img
          style={{ zIndex: "9999", top: "5px" }}
          className="position-absolute"
          src={send}
          alt="img"
        />
      </div>
    </>
  );
};

export default ChatSection;
