import React, { useState } from "react";
import { Modal, Form, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import home from "../../images/customer/home-table.png";
import added from "../../images/customer/added.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import Datepicker from "../../common/datepicker/Datepicker";

interface EditDetailsModalProps {
    show: boolean;
    onHide: () => void;
}
const EditDetails: React.FC<EditDetailsModalProps> = ({
    show,
    onHide,
}) => {
    const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
    const [selectedChcekOutDate, setSelectedCheckOutDate] = useState<Date | null>(null);

    const handleClose = () => {
        onHide();
    };

    const handleCheckIn = (date: Date | null) => {
        setSelectedChcekInDate(date);
    };

    const handleCheckOut = (date: Date | null) => {
        setSelectedCheckOutDate(date);
    }

    return (
        <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>
            <Modal.Header className="border-bottom-0">
                <Modal.Title className="mx-auto">
                    <span className="header-modal">Edit Details</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="agent_modal_body">
                <Form>
                    <div className="row">
                        <Form.Group className="col-md-6 mb-3" controlId="property">
                            <Form.Label className="label-modal">
                                Username<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Admin"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="property">
                            <Form.Label className="label-modal">
                                Property<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Property"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="title">
                            <Form.Label className="label-modal">
                                Check In<span className="text-danger">*</span>
                            </Form.Label>
                            <Datepicker
                                className="form-input-modal form-height form-control"
                                selected={selectedChcekInDate}
                                onChange={handleCheckIn}
                                placeholderText="06 Jun, 2024"
                            />

                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="priority">
                            <Form.Label className="label-modal">
                                Check Out<span className="text-danger">*</span>
                            </Form.Label>
                            <Datepicker
                                className="form-input-modal form-height form-control"
                                selected={selectedChcekOutDate}
                                onChange={handleCheckOut}
                                placeholderText="28 Apr, 2023"
                            />

                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="description">
                            <Form.Label className="label-modal">
                                Actual Price<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="₦ 30,000"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>

                        <Form.Group className="col-md-6 mb-3" controlId="description">
                            <Form.Label className="label-modal">
                                Negotiable Price    <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="₦ 25,000"
                                className="form-input-modal form-height"
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className="col-md-12 mb-3" controlId="uploadImages">
                        <Form.Label className="label-modal">Upload Images</Form.Label>
                        <div className="upload_sec py-3">
                            <div className="upload_images">
                                <Image src={home} alt="upload-1" />
                            </div>
                            <div className="upload_images">
                                <Image src={home} alt="upload-2" />
                            </div>
                            <div className="upload_images">
                                <Image src={home} alt="upload-3" />
                            </div>
                            <div className="property_upload">
                                <Form.Label htmlFor="myfile" className="upload--btn">
                                    Add
                                    <br /> <Image src={added} alt="img-add" />
                                </Form.Label>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="modal-delete border-top-0 justify-content-center">
                <Button
                    variant="variant"
                    className="modal_cancel_btn"
                    type="submit"
                    onClick={() => console.log("click")}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDetails;
