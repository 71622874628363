import React from "react";
import ContactUs from "./ContactUs/ContactUs";
import HomeForYou from "./HomeCard/HomeForYou";
import QualitySection from "./QualityListing/QualityListing";
import WhyUsSection from "./WhyUsSection/WhyUsSection";
import BannerSection from "./Banner/BannerSection";

export default function HomeBanner() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <div>
      <BannerSection />
      <WhyUsSection />
      <QualitySection />
      <HomeForYou />
      <ContactUs />
    </div>
  );
}
