import React from "react";
import { Modal, ListGroup, Image, FormControl } from "react-bootstrap";
import close from "../../images/customer/close.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import { agentmodalData } from "../../common/constant/constant";

interface AsignModalProps {
    show: boolean;
    onHide: () => void;
}
const AsignModal: React.FC<AsignModalProps> = ({
    show,
    onHide,
}) => {
    const handleClose = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-agent" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>
            <Modal.Header className="border-bottom-0">
                <Modal.Title className="mx-auto">
                    <span className="header-modal">Assign Property to a Registered Agent</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="agent_modal_body">
                <div className="search_agent mb-4">
                    <FormControl
                        type="email"
                        id="inputEmail5"
                        placeholder="Search agent"
                        className="search_modal_agent"
                    />
                </div>
                <div className="agents_list">
                    <ListGroup as="ul">
                        {agentmodalData.map((item, index) => (
                            <ListGroup.Item key={index} as="li" className="mb-1 border-0">
                                <div className="agent_list_block">
                                    <Image src={item.image} alt="img" />
                                    <div className="agent_name">
                                        <h5 className="mb-0 h5_text_modal">{item.name}</h5>
                                        <p className="mb-0 p_text_modal">{item.id}</p>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}

                    </ListGroup>
                </div>

            </Modal.Body>
            <Modal.Footer className="modal-delete border-top-0 justify-content-center">
                <Button
                    variant="variant"
                    className="modal_cancel_btn assign_modal_btn"
                    type="submit"
                    onClick={() => console.log("click")}
                >
                    Assign Property
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AsignModal;
