import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import toggle from "../../../images/sidebar-icon/sidebar-toggle.png";
import { NavLink, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { sidebarCollapsed, handleToggleClick } = useContext(Context);

  return (
    <div>
      <aside className={`sidebar  ${sidebarCollapsed ? "visible" : "hidden"}`}>
        <div className="sidebar-start">
          <div className="sidebar-head">
            <a href="/customer-login" className="logo-wrapper" title="Home">
              <span className="icon logo" aria-hidden="true"></span>
            </a>

            <span className="icon menu-toggle" aria-hidden="true">
              <Image src={toggle} onClick={handleToggleClick} alt="img" />
            </span>
          </div>

          <div className="container mt-5">
            <div className="side__text">
              <ul className="list-group">
                <li className="list-group-item" onClick={handleToggleClick}>
                  <div
                    className={`menu_heading dropdown-toggle d-flex gap-3 align-items-center ${location.pathname === "/dashboard" ? "active" : ""}`}
                  >
                    <NavLink to="/dashboard" className="w-100">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.19 2H12.75V8V8.75V13.75H22V8.75V8V7.81C22 4.17 19.83 2 16.19 2Z"
                          fill="white"
                        />
                        <path
                          d="M2 10.25V15.25V15.75V16.19C2 19.83 4.17 22 7.81 22H11.25V15.75V15.25V10.25H2Z"
                          fill="white"
                        />
                        <path
                          d="M11.25 2V8.75H2V7.81C2 4.17 4.17 2 7.81 2H11.25Z"
                          fill="white"
                        />
                        <path
                          d="M22 15.25V16.19C22 19.83 19.83 22 16.19 22H12.75V15.25H22Z"
                          fill="white"
                        />
                      </svg>
                      <span className="align-middle px-2 span__text">
                        Dashboard
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                >
                  <div className={`menu_heading dropdown-toggle d-flex align-items-center ${location.pathname === ("/customer-booking") ? "active" : ""}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={handleToggleClick}
                    >
                      <path
                        d="M13.0043 15.165L16.2469 16.5249C16.7188 16.733 17.0287 17.2049 17.0333 17.7184L16.8807 22.7373H11.7924C11.7924 22.7373 9.956 19.7305 9.7894 19.5178C9.67841 19.3605 9.64137 19.0645 9.64137 19.0645C9.58587 18.8008 9.58128 16.2937 9.58128 16.2937C9.58128 15.8774 9.93284 15.5305 10.3445 15.5305C10.7608 15.5305 11.1077 15.8821 11.1077 16.2937V18.1024C11.1077 18.2042 11.1864 18.2874 11.2928 18.2874C11.3159 18.2874 11.3437 18.2828 11.3622 18.2735C11.4269 18.2412 11.4778 18.1764 11.4778 18.0978V11.9872C11.501 11.6125 11.8294 11.3027 12.2226 11.3027H12.2411C12.6759 11.3027 13.0043 11.6542 13.0043 12.0659V15.165ZM5.54319 9.87738L3.46887 7.5401L7.02819 4.3542L6.06575 3.47482L0.195312 8.72932L1.08781 9.73498L1.99287 8.91785V14.664H5.94378V9.51579L5.54319 9.87738ZM18.6428 7.69179L15.5277 4.85632V1.56598H13.7142V3.18841L11.6066 1.2627L4.52553 7.60085L5.60206 8.81388L6.69378 7.82829V14.6641H10.7277V11.9871V11.964L10.7292 11.9409C10.7772 11.1624 11.4331 10.5525 12.2225 10.5525H12.241C13.0753 10.5525 13.7542 11.2313 13.7542 12.0657L13.7567 14.6636L16.4663 14.6664L16.4637 7.88885L17.5379 8.87445L18.6428 7.69179ZM21.2182 6.45401V3.72616H19.7146V5.0712L17.9673 3.47473L16.5338 4.75788L19.7095 7.64857L17.5785 9.9297L17.2137 9.59501V14.6641H21.9942V8.9682L22.8847 9.78532L23.8006 8.80479L21.2182 6.45401Z"
                        fill="white"
                      />
                    </svg>
                    <span className="align-middle px-2 span__text">
                      Manage Bookings
                    </span>
                  </div>

                  <div className="accordion_sub_menu collapse" id="collapseOne">
                    <div className="card card-body">
                      <ul >
                        <NavLink to="/active" className="w-100" >
                          <li  >
                            <a href="">Active</a>
                          </li>
                        </NavLink>
                        <NavLink to="/history"  >
                          <li >
                            <a href="">History</a>
                          </li>
                        </NavLink>
                        <NavLink to="/archive-component"  >
                          <li >
                            <a href="">Archive</a>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                >
                  <div
                    className={`menu_heading dropdown-toggle d-flex align-items-center ${location.pathname === "/facility-management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={handleToggleClick}
                    >
                      <path
                        d="M14.25 15.375C14.25 16.8247 13.0747 18 11.625 18C10.1753 18 9 16.8247 9 15.375C9 13.9253 10.1753 12.75 11.625 12.75C13.0747 12.75 14.25 13.9253 14.25 15.375ZM12 12.0382V4.125C12 3.918 11.832 3.75 11.625 3.75C11.418 3.75 11.25 3.918 11.25 4.125V12.0382C11.3745 12.024 11.4967 12 11.625 12C11.7533 12 11.8755 12.024 12 12.0382ZM11.25 18.7118V19.875C11.25 20.082 11.418 20.25 11.625 20.25C11.832 20.25 12 20.082 12 19.875V18.7118C11.8755 18.726 11.7533 18.75 11.625 18.75C11.4967 18.75 11.3745 18.726 11.25 18.7118ZM18 14.2118V19.875C18 20.082 18.168 20.25 18.375 20.25C18.582 20.25 18.75 20.082 18.75 19.875V14.2118C18.6255 14.226 18.5033 14.25 18.375 14.25C18.2467 14.25 18.1245 14.226 18 14.2118ZM18.75 7.53825V4.125C18.75 3.918 18.582 3.75 18.375 3.75C18.168 3.75 18 3.918 18 4.125V7.53825C18.1245 7.524 18.2467 7.5 18.375 7.5C18.5033 7.5 18.6255 7.524 18.75 7.53825ZM5.25 5.28825V4.125C5.25 3.918 5.082 3.75 4.875 3.75C4.668 3.75 4.5 3.918 4.5 4.125V5.28825C4.6245 5.274 4.74675 5.25 4.875 5.25C5.00325 5.25 5.1255 5.274 5.25 5.28825ZM4.5 11.9618V19.875C4.5 20.082 4.668 20.25 4.875 20.25C5.082 20.25 5.25 20.082 5.25 19.875V11.9618C5.1255 11.976 5.00325 12 4.875 12C4.74675 12 4.6245 11.976 4.5 11.9618ZM4.875 6C3.42525 6 2.25 7.17525 2.25 8.625C2.25 10.0747 3.42525 11.25 4.875 11.25C6.32475 11.25 7.5 10.0747 7.5 8.625C7.5 7.17525 6.32475 6 4.875 6ZM18.375 8.25C16.9253 8.25 15.75 9.42525 15.75 10.875C15.75 12.3247 16.9253 13.5 18.375 13.5C19.8248 13.5 21 12.3247 21 10.875C21 9.42525 19.8248 8.25 18.375 8.25Z"
                        fill="white"
                      />
                    </svg>
                    <span className="align-middle px-2 span__text">
                      Facility Management
                    </span>
                  </div>

                  <div className="accordion_sub_menu collapse" id="collapseTwo">
                    <div className="card card-body">
                      <ul>
                        <NavLink to="/work-request">
                          <li>
                            <a href="/work-request">Work Request</a>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
