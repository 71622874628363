import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import onlin_agent from "../../../../images/online_agent.png";
const ChatHeader: React.FC = () => {
  return (
    <div className="d-block">
      {/* add new className for manage chat header user_chat */}
      <div className="chat_header user_h3 user_chat">
      <div className="chat-message-left pb-4  py-4">
            <div>
              <img src={onlin_agent} className="img-fluid" alt="img" />
            </div>
            <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
              <div className="cht_time">
                <h3>Amelia Greene</h3>
                <div className="text-muted small text-nowrap h2_apartment ">Online</div>
              </div>
            </div>
          </div>
          <div className="flex-shrink-1 Amelia_text py-2 px-3 ml-3">
              <div className="cht_time">
                <h3 className="user_h3">2 Bedroom Apartment</h3>
                <div className="text-muted small text-nowrap h2_apartment">Your Order#<span className="num_apartment">123456987</span> </div>
              </div>
            </div>
      </div>
    </div>
  );
};

export default ChatHeader;
