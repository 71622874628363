import React from 'react'
import Layout from '../../../layout/layout';
import { Image, Form } from 'react-bootstrap';
import home from "../../../../../images/customer/home-table.png"
import link from "../../../../../images/customer/link.png";
import facebook from "../../../../../images/customer/facebook.png";
import whatsapp from "../../../../../images/customer/whatsapp.png";
import message from "../../../../../images/customer/message.png";
import added from "../../../../../images/customer/added.png";
import left from "../../../../../images/customer/left.png";
import Button from '../../../../../common/Button/Button';
import { useNavigate } from 'react-router-dom';
import "../../../../customerpenal/styles/customer-common.css";
const WorkOderRequest: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Layout>
                <main className="main users">
                    <div className="container">
                        <Image onClick={() => navigate('/facility-order-request')} className='img-click' src={left} alt="img" />
                        <div className="add_property_sec py-3">
                            <div className="property_wrap order_request_wrap">
                                <div className="heading_add py-3">
                                    <div className="heading_content request_order">
                                        <h3>Work Order Request</h3>
                                    </div>
                                </div>
                                <div className="work__order__request_wrap">
                                    <ul>
                                        <li>
                                            <div className="work_order_list">
                                                <label className="form-label">Title</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="Leaking toilet" />
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Work Order No.</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="26" />
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Priority</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="High" />
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Due Date</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="28 Apr, 2023" />
                                            </div>
                                        </li>
                                        <li>

                                            <div className="work_order_list">
                                                <label className="form-label">Work Request No.</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="67" />
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Category</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="Plumbing" />
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Status</label>
                                                <Form.Select className=" booking-text-field m-0 going border-0">
                                                    <option className="booking-text-field m-0 going border-0"> On Going</option>
                                                    <option className="booking-text-field m-0 going border-0" value="1">One</option>
                                                    <option className="booking-text-field m-0 going border-0" value="2">Two</option>
                                                    <option className="booking-text-field m-0 going border-0" value="3">Three</option>
                                                </Form.Select>
                                            </div>
                                            <div className="work_order_list">

                                            </div>
                                        </li>
                                        <li>
                                            <div className="work_order_list">
                                                <label className="form-label">Description</label>
                                                <input type="email" className="form-control placeholder_text" id="" placeholder="Leaking toilet in master bedroom" />
                                            </div>

                                        </li>
                                        <li>
                                            <div className="work_order_list">
                                                <label className="form-label">Gallery</label>
                                                <div className="upload_sec py-3">
                                                    <div className="upload_images">
                                                        <Image src={home} alt="img" />
                                                    </div>
                                                    <div className="upload_images">
                                                        <Image src={home} alt="img" />
                                                    </div>
                                                    <div className="upload_images">
                                                        <Image src={home} alt="img" />
                                                    </div>


                                                </div>
                                            </div>
                                            <div className="work_order_list">
                                                <label className="form-label">Share Documents</label>
                                                <div className="social__icons d-flex gap-2 pt-3">
                                                    <a href="#"><Image src={link} alt="img" /></a>
                                                    <a href="#"><Image src={facebook} alt="img" /></a>
                                                    <a href="#"><Image src={whatsapp} alt="img" /></a>
                                                    <a href="#"><Image src={message} alt="img" /></a>
                                                </div>
                                            </div>
                                            <div className="work_order_list">

                                            </div>

                                        </li>
                                    </ul>
                                    <div className="work_order_list updates_order pt-3">
                                        <label className="form-label update-upload" >Add Updates</label>
                                        <input type="email" className="form-control" placeholder="Flow of activities" />
                                    </div>
                                    <div className="work_order_list updates_order pt-5">
                                        <label className="form-label update-upload">Upload Images</label>
                                        <div className="upload_sec_order py-3">
                                            <div className="upload_images">
                                                <Image src={home} alt="img" />
                                            </div>
                                            <div className="upload_images">
                                                <Image src={home} alt="img" />
                                            </div>
                                            <div className="upload_images">
                                                <Image src={home} alt="img" />
                                            </div>
                                            <div className="property_upload">
                                                <input type="file" id="myfile" name="myfile" accept="image/*" className="upload" />
                                                <label className="upload--btn">Add<br /> <Image src={added} alt="img" /></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn_order btn_order_a">
                                        <Button type='button' variant='outline' className='modal_delete_btn_f' onClick={() => console.log("/")}>Delete</Button>
                                        <Button type='submit' variant='variant' className="btn btn-primary">Save Changes</Button>
                                        <Button type='button' variant='variant' className="btn btn-primary btn-close-order">Close Work Order</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </div>
    )
}

export default WorkOderRequest;
