import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import location from "../../../../images/location.svg";
import RoomService02 from "../../../../images/room-service-2.png";
import AirRoom from "../../../../images/air-room-1.png";
import perking03 from "../../../../images/parking-3.png";
import Wifi4 from "../../../../images/wifi-4.png";
import Online from "../../../../images/online.png";
import Map from "../../../../images/map.png";
import Star from "../../../../images/star.png";
import Button from "../../../../common/Button/Button";
import WeekPicker from "../Calender/WeekPicker";
import Datepicker from "../../../../common/datepicker/Datepicker";
import AutoAgent from "../../../../common/autoAgent/AutoAgent";

const image = [
  {
    img: AirRoom,
    text: "Air Conditioning Room",
  },
  {
    img: RoomService02,
    text: "Room Service",
  },
  {
    img: perking03,
    text: "Parking",
  },
  {
    img: Wifi4,
    text: "WiFi Service",
  },
];

const Booking = () => {
  const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
  const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(null);


  const handleCheckIn = (date: Date | null) => {
    setSelectedChcekInDate(date);
  };

  const handleCheckOut = (date: Date | null) => {
    setSelectedChcekOutDate(date);
  }
  return (
    <section className="booking_sec">
      <Container>
        <Row className="g-4 g-lg-5">
          <Col lg={8}>
            <div className="booking_larg py-3">
              <div className="card_bedroom">
                <div className="card-body">
                  <div className="badge_btn">
                    <p>Short Let</p>
                  </div>
                  <div className="card_heading_block">
                    <h2 className="font_size-apartment">2 Bedroom apartment</h2>
                  </div>

                  <div className="card_location">
                    <span>
                      <img src={location} alt="Card Location" className="loc" />
                    </span>
                    <p>Leki - Apartment</p>
                  </div>

                  <div className="card_desc">
                    <h4>Description</h4>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                      dignissim, metus nec fringilla accumsan, risus sem
                      sollicitudin lacus, ut interdum tellus elit sed risus.
                      Maecenas eget condimentum velit, sit amet feugiat lectus.
                      Class aptent taciti sociosqu ad litora torquent per
                      conubia nostra, per inceptos himenaeos. Praesent auctor
                      purus luctus enim egestas, ac scelerisque ante pulvinar.
                      Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu
                      tempor urna. Curabitur vel bibendum lorem. Morbi convallis
                      convallis diam sit amet lacinia. Aliquam in elementum
                      tellus.
                    </p>
                  </div>
                  <div className="features_bedroom">
                    <h4 className="h4-feature">Features</h4>
                    <div className="feature_icon_sec feature-list-cs py-3">
                      {image.map((item, index) => (
                        <div className="feature_icon" key={index}>
                          <img
                            className="feature_image_icon"
                            src={item.img}
                            alt="img"
                          />
                          <p className="p-feature-tag mb-0 mb-0_1">
                            {item.text}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="availability_sec py-3 ">
                    <div
                      className="available_block py-5"
                      style={{ alignItems: "center" }}
                    >
                      <h4 className="h4-available">Availability Calendar</h4>
                      <small className="small_feature">
                        Available <img src={Online} alt="Online" />
                      </small>
                    </div>
                    <div className="availability_calender">
                      <WeekPicker />
                    </div>
                  </div>

                  <div className="map_tour">
                    <div className="map_btn_sec py-4">
                      <Button
                        type="button"
                        variant="variant"
                        className="btn btn_primary btn-booking-tour"
                        onClick={() => console.log("Button clicked")}
                      >
                        360° Virtual Tour
                      </Button>
                    </div>
                    <div className="map_pic">
                      <img src={Map} alt="Map" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={4}>
            <div className="card booking_card py-3">
              <div className="booking_card_header">
                <div className="booking_dollar d-flex align-items-center justify-content-between">
                  <p>
                    $ 250<span> /Mon</span>
                  </p>
                  <p>
                    <img className="star_min" src={Star} alt="Star" />{" "}
                    <span className="span-number span_color_text">
                      {" "}
                      4.6 (456)
                    </span>
                  </p>
                </div>
                <div className="booking_card_content d-flex align-items-center justify-content-between py-3">
                  <div className="input_group">
                    <Form.Label htmlFor="checkIn">Check In</Form.Label>
                    <Datepicker
                      className="mb-0 form-select border-0"
                      selected={selectedChcekInDate}
                      onChange={handleCheckIn}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>
                  <div className="input_group">
                    <Form.Label htmlFor="checkOut">Check Out</Form.Label>
                    <Datepicker
                      className="mb-0 form-select border-0"
                      selected={selectedChcekOutDate}
                      onChange={handleCheckOut}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>
                </div>
                <div className="booking_card_content py-3">
                  <div className="agent_code">
                    <p>
                      Agent Code <span>(Optional)</span>
                    </p>
                    {/* <h3>210125</h3> */}
                    <AutoAgent />
                  </div>
                </div>
                <div className="booking_card_content border-_bottom d-flex align-items-center justify-content-between py-3">
                  <div className="input_group">
                    <Form.Label htmlFor="cleaningFee">Cleaning Fee</Form.Label>
                    <h6>$ 10</h6>
                  </div>
                  <div className="input_group">
                    <Form.Label htmlFor="taxesFee">Taxes & Fee</Form.Label>
                    <h6>$ 10</h6>
                  </div>
                </div>
                <div className="booking_card_content d-flex align-items-center justify-content-between py-3">
                  <div className="total" style={{ width: "48%" }}>
                    <Form.Label htmlFor="total">Total</Form.Label>
                  </div>
                  <div className="total-a" style={{ width: "48%" }}>
                    <Form.Label htmlFor="totalAmount">$ 270</Form.Label>
                  </div>
                </div>
                <div className="booking_card_content py-3">
                  <div className="reserve">
                    <Button
                      type="button"
                      variant="variant"
                      className="btn btn_primary"
                      onClick={() => console.log("Button clicked")}
                    >
                      Reserve
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Booking;
