import React from "react";
import NavHeader from "./navHeader/NavHeader";
import StripHeader from "./stripHeader/StripHeader";
import "../styles/common.css";
import "../styles/style-header.css";
import "../styles/styles.css";
import "../styles/style-footer.css";
import "../styles/responsive.css";

const Header: React.FC = () => {
  return (
    <div>
      <StripHeader />
      <NavHeader />
    </div>
  );
};

export default Header;
