import React from "react";
import { Modal, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import home from "../../images/customer/home-table.png";
import link from "../../images/customer/link.png";
import facebook from "../../images/customer/facebook.png";
import whatsapp from "../../images/customer/whatsapp.png";
import message from "../../images/customer/message.png";
import "../modal.css";
import Button from "../../common/Button/Button";

interface AgentWorkRequestModalProps {
    show: boolean;
    onHide: () => void;
}
const AgentWorkRequestModal: React.FC<AgentWorkRequestModalProps> = ({
    show,
    onHide,
}) => {
    const handleClose = () => {
        onHide();
    };

    return (
        <Modal show={show} onHide={handleClose} className="modal-genrate" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>
            <Modal.Header className="border-bottom-0">
                <Modal.Title className="mx-auto">
                    <span className="header-modal">Work Request</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="agent_modal_body">
                <div className="details__booking">
                    <div className="row border-bottom py-2">
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Title</h6>
                            <p className="booking-text-field">Leaking toilet</p>
                        </div>
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Facility Manager</h6>
                            <p className="booking-text-field">Mason Sanchez</p>
                        </div>
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Priority</h6>
                            <p className="booking-text-field">High</p>
                        </div>
                    </div>
                    <div className="row border-bottom py-2">
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Due Date</h6>
                            <p className="booking-text-field">28 Apr, 2023</p>
                        </div>
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Work Request No.</h6>
                            <p className="booking-text-field">67</p>
                        </div>
                        <div className="col-lg-4 box-booking mb-2">
                            <h6 className="booking-label">Category</h6>
                            <p className="booking-text-field">Plumbing</p>
                        </div>
                    </div>
                     <div className="row border-bottom py-3">
            <div className="col-lg-8 box-booking mb-2">
              <h6 className="booking-label">Description</h6>
              <p className="booking-text-field m-0">
                Leaking toilet in master bedroom
              </p>
            </div>
            <div className="col-lg-4 box-booking">
              <h6 className="booking-label">Status</h6>
              <p className="booking-text-field m-0">On Going</p>
            </div>
          </div>

                    <div className="row border-bottom py-2 d-flex ">
                        <div className="col-lg-12 d-flex justify-content-between">
                            <div>
                                <h6 className="booking-label">Gallery</h6>
                                <div className="upload_sec py-3">
                                    <div className="upload_images">
                                        <Image src={home} alt="upload-1" />
                                    </div>
                                    <div className="upload_images">
                                        <Image src={home} alt="upload-2" />
                                    </div>
                                    <div className="upload_images">
                                        <Image src={home} alt="upload-3" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h6 className="booking-label">Share Documents</h6>
                                <div className="upload_sec py-3">
                                    <div className="upload_images">
                                        <Image src={link} alt="upload-1" />
                                    </div>
                                    <div className="upload_images">
                                        <Image src={facebook} alt="upload-2" />
                                    </div>
                                    <div className="upload_images">
                                        <Image src={whatsapp} alt="upload-1" />
                                    </div>
                                    <div className="upload_images">
                                        <Image src={message} alt="upload-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </Modal.Body>
            <Modal.Footer className="modal-delete border-top-0 justify-content-center">
                <Button
                    variant="outline"
                    className="modal_delete_btn"
                    type="button"
                    onClick={() => console.log("click")}
                >
                    Delete
                </Button>
                <Button
                    variant="outline"
                    className="save_changes_btn"
                    type="submit"
                    onClick={() => console.log("click")}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AgentWorkRequestModal;
