import React, { useContext } from "react";
import { Image } from "react-bootstrap";
import toggle from "../../../images/sidebar-icon/sidebar-toggle.png";
import { NavLink, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { sidebarCollapsed, handleToggleClick } = useContext(Context);


  return (
    <div>
      <aside className={`sidebar  ${sidebarCollapsed ? "visible" : "hidden"}`}>
        <div className="sidebar-start">
          <div className="sidebar-head">
            <a href="/login" className="logo-wrapper" title="Home">
              <span
                className={`icon logo ${sidebarCollapsed ? "visible" : "hidden"
                  }`}
                aria-hidden="true"
              ></span>
            </a>

            <span className="icon menu-toggle" aria-hidden="true">
              <Image src={toggle} onClick={handleToggleClick} alt="img" />
            </span>
          </div>

          <div className="container mt-5">
            <div className="side__text">
              <ul className="list-group">
                <li className="list-group-item">
                  <div
                    className={`menu_heading dropdown-toggle d-flex gap-3 align-items-center ${location.pathname === "/agent-dashboard"
                      ? "active"
                      : ""
                      }`}
                  >
                    <NavLink to="/agent-dashboard" className="w-100" onClick={handleToggleClick}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"

                      >
                        <path
                          d="M16.19 2H12.75V8V8.75V13.75H22V8.75V8V7.81C22 4.17 19.83 2 16.19 2Z"
                          fill="white"
                        />
                        <path
                          d="M2 10.25V15.25V15.75V16.19C2 19.83 4.17 22 7.81 22H11.25V15.75V15.25V10.25H2Z"
                          fill="white"
                        />
                        <path
                          d="M11.25 2V8.75H2V7.81C2 4.17 4.17 2 7.81 2H11.25Z"
                          fill="white"
                        />
                        <path
                          d="M22 15.25V16.19C22 19.83 19.83 22 16.19 22H12.75V15.25H22Z"
                          fill="white"
                        />
                      </svg>
                      <span className={`align-middle px-2 span__text ${sidebarCollapsed ? "visible" : "hidden"}`}>
                        Dashboard
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className="list-group-item"

                >
                  <div
                    className={`menu_heading dropdown-toggle d-flex gap-3 align-items-center ${location.pathname === "/agent-property"
                      ? "active"
                      : ""
                      }`}
                    onClick={handleToggleClick}
                  >
                    <NavLink to="/agent-property" className="w-100">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_369_9992)">
                          <path
                            d="M17.7121 14.8025C17.5826 14.7485 17.4487 14.718 17.3192 14.6678V9.06574H19.0838L16.8206 7.27604V4.80045H15.1078V5.92101L12.3879 3.76819L5.69417 9.06574H7.45723V13.7688C5.0471 14.141 2.56085 14.8627 0 15.8934C14.8764 14.8992 2.18556 20.4685 9.15177 19.5094C18.7428 18.1894 24 20.2318 24 20.2318C24 20.2318 23.1032 17.0498 17.7121 14.8025ZM13.4178 13.6515C12.6208 13.5434 11.8116 13.5 10.991 13.4939V10.8296H13.4178V13.6515Z"
                            fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_369_9992">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="align-middle px-2 span__text">
                        Assigned Properties
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                >
                  <div
                    className={`menu_heading dropdown-toggle d-flex align-items-center ${location.pathname.startsWith("/customer-booking")
                      ? "active"
                      : ""
                      }`}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleToggleClick}>
                      <path
                        d="M13.0062 15.165L16.2489 16.5249C16.7207 16.733 17.0306 17.2049 17.0352 17.7184L16.8826 22.7373H11.7943C11.7943 22.7373 9.95795 19.7305 9.79136 19.5178C9.68036 19.3605 9.64333 19.0645 9.64333 19.0645C9.58783 18.8008 9.58323 16.2937 9.58323 16.2937C9.58323 15.8774 9.9348 15.5305 10.3465 15.5305C10.7628 15.5305 11.1097 15.8821 11.1097 16.2937V18.1024C11.1097 18.2042 11.1883 18.2874 11.2947 18.2874C11.3179 18.2874 11.3456 18.2828 11.3641 18.2735C11.4289 18.2412 11.4798 18.1764 11.4798 18.0978V11.9872C11.503 11.6125 11.8314 11.3027 12.2245 11.3027H12.243C12.6778 11.3027 13.0062 11.6542 13.0062 12.0659V15.165ZM5.54514 9.87738L3.47083 7.5401L7.03014 4.3542L6.0677 3.47482L0.197266 8.72932L1.08977 9.73498L1.99483 8.91785V14.664H5.94573V9.51579L5.54514 9.87738ZM18.6447 7.69179L15.5297 4.85632V1.56598H13.7161V3.18841L11.6085 1.2627L4.52748 7.60085L5.60402 8.81388L6.69573 7.82829V14.6641H10.7297V11.9871V11.964L10.7311 11.9409C10.7792 11.1624 11.4351 10.5525 12.2245 10.5525H12.2429C13.0773 10.5525 13.7561 11.2313 13.7561 12.0657L13.7587 14.6636L16.4682 14.6664L16.4657 7.88885L17.5399 8.87445L18.6447 7.69179ZM21.2201 6.45401V3.72616H19.7166V5.0712L17.9693 3.47473L16.5357 4.75788L19.7114 7.64857L17.5805 9.9297L17.2157 9.59501V14.6641H21.9961V8.9682L22.8866 9.78532L23.8026 8.80479L21.2201 6.45401Z"
                        fill="white" />
                    </svg>
                    <span className="align-middle px-2 span__text">
                      Manage Bookings
                    </span>
                  </div>

                  <div className="accordion_sub_menu collapse" id="collapseOne">
                    <div className="card card-body">
                      <ul>
                        <NavLink to="/active-agent" className="w-100">
                          <li>
                            <a href="/active-agent">Active</a>
                          </li>
                        </NavLink>
                        <NavLink to="/history-agent">
                          <li>
                            <a href="/history-agent">History</a>
                          </li>
                        </NavLink>
                        <NavLink to="/archive-agent">
                          <li>
                            <a href="#">Archive</a>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </li>

                <li
                  className="list-group-item"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                >
                  <div
                    className={`menu_heading dropdown-toggle d-flex align-items-center ${location.pathname === "/facility-management"
                      ? "active"
                      : ""
                      }`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={handleToggleClick}
                    >
                      <path
                        d="M14.25 15.375C14.25 16.8247 13.0747 18 11.625 18C10.1753 18 9 16.8247 9 15.375C9 13.9253 10.1753 12.75 11.625 12.75C13.0747 12.75 14.25 13.9253 14.25 15.375ZM12 12.0382V4.125C12 3.918 11.832 3.75 11.625 3.75C11.418 3.75 11.25 3.918 11.25 4.125V12.0382C11.3745 12.024 11.4967 12 11.625 12C11.7533 12 11.8755 12.024 12 12.0382ZM11.25 18.7118V19.875C11.25 20.082 11.418 20.25 11.625 20.25C11.832 20.25 12 20.082 12 19.875V18.7118C11.8755 18.726 11.7533 18.75 11.625 18.75C11.4967 18.75 11.3745 18.726 11.25 18.7118ZM18 14.2118V19.875C18 20.082 18.168 20.25 18.375 20.25C18.582 20.25 18.75 20.082 18.75 19.875V14.2118C18.6255 14.226 18.5033 14.25 18.375 14.25C18.2467 14.25 18.1245 14.226 18 14.2118ZM18.75 7.53825V4.125C18.75 3.918 18.582 3.75 18.375 3.75C18.168 3.75 18 3.918 18 4.125V7.53825C18.1245 7.524 18.2467 7.5 18.375 7.5C18.5033 7.5 18.6255 7.524 18.75 7.53825ZM5.25 5.28825V4.125C5.25 3.918 5.082 3.75 4.875 3.75C4.668 3.75 4.5 3.918 4.5 4.125V5.28825C4.6245 5.274 4.74675 5.25 4.875 5.25C5.00325 5.25 5.1255 5.274 5.25 5.28825ZM4.5 11.9618V19.875C4.5 20.082 4.668 20.25 4.875 20.25C5.082 20.25 5.25 20.082 5.25 19.875V11.9618C5.1255 11.976 5.00325 12 4.875 12C4.74675 12 4.6245 11.976 4.5 11.9618ZM4.875 6C3.42525 6 2.25 7.17525 2.25 8.625C2.25 10.0747 3.42525 11.25 4.875 11.25C6.32475 11.25 7.5 10.0747 7.5 8.625C7.5 7.17525 6.32475 6 4.875 6ZM18.375 8.25C16.9253 8.25 15.75 9.42525 15.75 10.875C15.75 12.3247 16.9253 13.5 18.375 13.5C19.8248 13.5 21 12.3247 21 10.875C21 9.42525 19.8248 8.25 18.375 8.25Z"
                        fill="white"
                      />
                    </svg>
                    <span className="align-middle px-2 span__text">
                      Facility Management
                    </span>
                  </div>

                  <div className="accordion_sub_menu collapse" id="collapseTwo">
                    <div className="card card-body">
                      <ul>
                        <NavLink to="/agent-work-request">
                          <li>
                            <a href="#">Work Request</a>
                          </li>
                        </NavLink>
                        <NavLink to="/agent-work-completed">
                          <li>
                            <a href="#">Completed</a>
                          </li>
                        </NavLink>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="list-group-item" onClick={handleToggleClick}>
                  <div
                    className={`menu_heading dropdown-toggle d-flex gap-3 align-items-center ${location.pathname === "/agent-wallet" ? "active" : ""}`}
                  >
                    <NavLink to="/agent-wallet" className="w-100">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M20.97 16.08C20.73 18.75 18.8 20.5 16 20.5H7C4.24 20.5 2 18.26 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.51 16.75 3.55C19.14 3.83 20.76 5.5 20.97 7.92C21 8.21 20.76 8.45 20.47 8.45H18.92C17.96 8.45 17.07 8.82 16.43 9.48C15.67 10.22 15.29 11.26 15.38 12.3C15.54 14.12 17.14 15.55 19.04 15.55H20.47C20.76 15.55 21 15.79 20.97 16.08Z"
                          fill="white" />
                        <path
                          d="M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z"
                          fill="white" />
                      </svg>
                      <span className={`align-middle px-2 span__text ${sidebarCollapsed ? "visible" : "hidden"}`}>
                        Wallet
                      </span>
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
