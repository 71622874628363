import React, { useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Image,
  Form,
  Carousel,
} from "react-bootstrap";
import card_location from "../../../../images/card-location.png";
import wifi from "../../../../images/wifi.png";
import icon_p from "../../../../images/icon-p.png";
import pending from "../../../../images/pending.png";
import Accepted from "../../../../images/Accepted.png";
import chatIcon from "../../../../images/chatIcon.png";
import Button from "../../../../common/Button/Button";
import { cardData } from "../../../../common/userCard/UserCard";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../../../common/pagination/PaginationComponent";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

export default function BookingListing() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<string>("firstRow"); // Initial state for the first set of cards
  const cardWithChatButtonIndices = [0, 2, 4];
  const pendinButtonIndices = [0, 2, 4];
  const acceptedButtonIndices = [1, 3, 5];

  return (
    <div>
      <section className="properties_filter listing_booking">
        <Container>
          <Row>
            <Col className="mb-5" lg={12}>
              <div className="filter_content">
                <h4 className="h4_title_booking">All Bookings</h4>
                <div className="filter_header pb-3 pb-lg-3">
                  <h5>Showing 1 - 10 out of 300</h5>
                  <div className="filter_header_btns">
                    <div className="sort_drop">
                      <Form.Label className="mb-0">Sort By:</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="selected-option"
                      >
                        <option selected>Newest</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>

                    <Button
                      type="button"
                      variant="variant"
                      className={`btn-common-icon ${
                        selectedRow === "firstRow" ? "activeButton" : ""
                      }`}
                      onClick={() => setSelectedRow("firstRow")}
                    >
                      <MenuRoundedIcon
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Button>

                    <Button
                      type="button"
                      variant="variant"
                      className={`btn-common-icon ${
                        selectedRow === "secondRow" ? "activeButton" : ""
                      }`}
                      onClick={() => setSelectedRow("secondRow")}
                    >
                      <GridViewRoundedIcon
                        style={{ width: "24px", height: "24px" }}
                      />
                    </Button>
                  </div>
                </div>

                {selectedRow === "firstRow" && (
                  <Row lg={1} g={3}>
                    {cardData.map((item, index) => (
                      <Col className="mb-3" key={index}>
                        <Card className="home_card home_card_booking">
                          <div className="image_body">
                            <div className="pic_item position-relative">
                              <Carousel>
                                <Carousel.Item interval={1000}>
                                  <Image
                                    className="img-carousel-booking"
                                    src={item.img}
                                    alt="img"
                                  />
                                </Carousel.Item>
                                <Carousel.Item interval={1000}>
                                  <Image
                                    className="img-carousel-booking"
                                    src={item.img02}
                                    alt="img"
                                  />
                                </Carousel.Item>
                                <Carousel.Item interval={1000}>
                                  <Image
                                    className="img-carousel-booking"
                                    src={item.img03}
                                    alt="img"
                                  />
                                </Carousel.Item>
                              </Carousel>
                            </div>
                          </div>

                          <Card.Body>
                            <div className="badge_btn">
                              <p>{item.cardtitle}</p>
                            </div>
                            <div className="card_heading_block card_header_small">
                              <h2>{item.cardHeading}</h2>
                              <div className="online_condiiton">
                                <span>$ {item.cardRate}</span>
                                {pendinButtonIndices.includes(index) && (
                                  <Button
                                    type="button"
                                    variant="variant"
                                    className="btn_pending border-0"
                                    onClick={() =>
                                      console.log("Button clicked")
                                    }
                                  >
                                    <Image src={pending} alt="img" />
                                    Pending
                                  </Button>
                                )}

                                {acceptedButtonIndices.includes(index) && (
                                  <Button
                                    type="button"
                                    variant="variant"
                                    className="btn_accepted border-0"
                                    onClick={() =>
                                      console.log("Button clicked")
                                    }
                                  >
                                    <Image src={Accepted} alt="img" />
                                    Accepted
                                  </Button>
                                )}
                              </div>
                            </div>
                            <div className="card_checkin">
                              <span>
                                <Image
                                  style={{ marginTop: "-4px" }}
                                  src={card_location}
                                  alt="img"
                                />
                              </span>
                              <p className="p-tag-cards mb-1">
                                {item.locationApt}
                              </p>
                            </div>

                            <div className="card_checkin">
                              <span>
                                <p className="mb-0 check-tag-p">Check In:</p>
                              </span>
                              <p className="p-tag-check mb-0">{item.checkIn}</p>
                            </div>
                            <div className="card_location mb-none">
                              <span>
                                <p className="check-tag-p mb-1">Check Out:</p>
                              </span>
                              <p className="p-tag-check mb-1">
                                {item.checkOut}
                              </p>
                            </div>
                            <div className="card_desc">
                              <p>{item.discription}</p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="icons_technical">
                                  <span>
                                    <Image src={wifi} alt="img" />
                                  </span>
                                  <span>
                                    <Image src={icon_p} alt="img" />
                                  </span>
                                </div>

                                <div className="btn_sec_card">
                                  {cardWithChatButtonIndices.includes(
                                    index
                                  ) && (
                                    <Button
                                      type="button"
                                      variant="outline"
                                      className="btn btn_secondary btn-chat-info d-flex gap-2"
                                      onClick={() => navigate("/chat")}
                                    >
                                      <Image src={chatIcon} alt="img" />
                                      Chat
                                    </Button>
                                  )}

                                  <Button
                                    type="button"
                                    variant="primary"
                                    className="btn btn_primary btn-more-info border-0"
                                    onClick={() => navigate("/my-booking")}
                                  >
                                    More Info
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                )}
              </div>

              {/* box div */}
              {selectedRow === "secondRow" && (
                <div className="row-card-booking row row-cols-12 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
                  {cardData.map((item, index) => (
                    <Col>
                      <div key={index} className="card home_card ">
                        <div className="image_body ">
                          <div className="pic_item position-relative">
                            <Carousel>
                              <Carousel.Item interval={1000}>
                                <Image
                                  className="mx-auto w-100"
                                  src={item.img}
                                  alt="img"
                                />
                              </Carousel.Item>
                              <Carousel.Item interval={1000}>
                                <Image
                                  className="mx-auto w-100"
                                  src={item.img02}
                                  alt="img"
                                />
                              </Carousel.Item>
                              <Carousel.Item interval={1000}>
                                <Image
                                  className="mx-auto w-100"
                                  src={item.img03}
                                  alt="img"
                                />
                              </Carousel.Item>
                            </Carousel>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="badge_btn">
                            <p>{item.cardtitle}</p>
                          </div>
                          <div className="card_heading_block">
                            <h2>{item.cardHeading}</h2>
                            <span>${item.cardRate}</span>
                          </div>

                          <div className="card_location">
                            <span>
                              <Image
                                src={card_location}
                                alt="img"
                                className="card_location_a border-bottom-0"
                              />
                            </span>
                            <p className="p-tag-cards">{item.locationApt}</p>
                          </div>

                          <div className="card_desc">
                            <p>{item.discription}</p>
                            <div className="card_bottom_icon">
                              <span>
                                <Image src={wifi} alt="img" />
                              </span>
                              <span>
                                <Image src={icon_p} alt="img" />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="card_btn">
                          <Button
                            type="button"
                            variant="variant"
                            className="btn btn_primary btn-common"
                            onClick={() => console.log("Button clicked")}
                          >
                            More Info
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </div>
              )}
            </Col>

            <div className="filter_pagination py-3 py-lg-5">
              <nav aria-label="...">
                <PaginationComponent />
              </nav>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}
