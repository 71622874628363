import React from 'react';
import { Container, Form, Image, } from 'react-bootstrap';
import email from "../../../../images/login/email-login.png";
import Button from '../../../../common/Button/Button';
import { useNavigate } from 'react-router-dom';


const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/customer-login')
    }
    return (
        <Container>
            <div className='d-flex p-4 back-div' onClick={handleBack}>
                <i className="bi bi-arrow-left"></i>
                <span className='px-2'>Back</span>
            </div>
            <div className='forgot-pass-div'>
                <h6 className='forgot-p text-center'>Forgot Password</h6>
                <div>
                    <p className='forgot-para'>
                        Enter the email address associated with your account and we'll send you a link to reset your password.
                        Don't have access to that email address anymore? Contact our <a className="text-decoration-underline" href="">Support team</a> for assistance.
                    </p>
                </div>
                <div>
                    <Form.Group className="my-4 owner_input position-relative">
                        <Form.Control className='form-control-forgot' type="text" placeholder="Email address" />
                        <span className='password-icon'>
                            <Image src={email} alt="img" />
                        </span>
                    </Form.Group>
                </div>
                <div className="owner_btn btn-width mx-auto ">
                    <Button
                        type="submit"
                        className="w-100 btn btn-primary"
                        onClick={() => navigate("/reset-password")}
                    >
                        Continue
                    </Button>
                </div>
            </div>
            <p className="register_login text-center ">
                Not you? <a href="/login">Login</a>
            </p>

        </Container>
    )
}

export default ForgotPassword;
