
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { PRIMARY_BLUE, TEXT_WHITE } from '../../styles/colors';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: PRIMARY_BLUE,
  color: TEXT_WHITE,
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: PRIMARY_BLUE,
    color: TEXT_WHITE,
    borderRadius: '12px',
  },
}));

export default function SearchButton() {
  return (
    <Box sx={{ float: 'right' }}>
      <StyledIconButton sx={{width:'60px', height:'60px'}} size="large" aria-label="search" color="inherit">
        <SearchIcon  sx={{width:"34px", height:"34px"}}/>
      </StyledIconButton>
    </Box>
  );
}