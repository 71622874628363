import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import SearchButton from "../../../../common/searchIconButton/SearchButton";
import Datepicker from "../../../../common/datepicker/Datepicker";
import AutoSearch from "../../../../common/autoSearch/AutoSearch";
import QuantityInput from "../../../../common/number/QuantityInput";

export const minValue = 16000;
export const maxValue = 26000;

const SearchBoxes: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
  const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(null);


  const handleTabSelect = (tab: string) => {
    setSelectedTab(tab);
  };

  const handleCheckIn = (date: Date | null) => {
    setSelectedChcekInDate(date);
  };

  const handleCheckOut = (date: Date | null) => {
    setSelectedChcekOutDate(date);
  }
  return (
    <div>
      <section id="properties_banner_search">
        <Container>
          <div className="banner_search_box ">
            <div className="properties_search_sec">
              <ul className="nav nav-underline gap-4">
                {[
                  "All",
                  "Short-Let",
                  "Medium-Lease",
                  "Long-Lease",
                  "Rent-Financing",
                ].map((tab) => (
                  <li className="nav-item" key={tab}>
                    <button
                      className={`nav-link${selectedTab === tab ? " active" : ""
                        }`}
                      onClick={() => handleTabSelect(tab)}
                    >
                      {tab.replace("-", " ")}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <Row>
              <Col sm={10}>
                <div className="content_tab">
                  <div className="items_tab">
                    <h4>Search</h4>
                    <AutoSearch />
                  </div>

                  <div className="items_tab">
                    <h4>Check In</h4>
                    <Datepicker
                      className="mb-0 text_height_p border-0"
                      selected={selectedChcekInDate}
                      onChange={handleCheckIn}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>
                  <div className="items_tab">
                    <h4>Check Out</h4>
                    <Datepicker
                      className="mb-0 text_height_p border-0"
                      selected={selectedChcekOutDate}
                      onChange={handleCheckOut}
                      placeholderText="06 Jun, 2024"
                    />
                  </div>

                  <div className="items_tab">
                    <h4>No. of Guests</h4>
                    <QuantityInput />
                  </div>
                </div>
              </Col>

              <Col sx={2}>
                <SearchButton />
              </Col>
              
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default SearchBoxes;
