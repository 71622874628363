// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/lato);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quantity-box {
  min-width: 40px;
  height: 40px;
  border-radius: 10px;
  color: #010404;
  border: none;
}

.quantity-box:hover {
  background: #163873 !important;
  color: #fff;
}

.h1-text-quantity {
  border: 1px solid #e9ecef;
  min-width: 55px;
  height: 40px;
  border-radius: 10px;
  font-weight: 500 !important;
  font-size: 16px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.range_picker {
  width: 100px;
  border: 1px solid var(--Stroke, #e5e6e7);
  border-radius: 30px;
  font-size: 14px;
  min-height: 40px;
  padding: 0px 10px;
  color: #5c677d;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,wCAAwC;EACxC,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["@import url(\"https://fonts.cdnfonts.com/css/lato\");\n\n.quantity-box {\n  min-width: 40px;\n  height: 40px;\n  border-radius: 10px;\n  color: #010404;\n  border: none;\n}\n\n.quantity-box:hover {\n  background: #163873 !important;\n  color: #fff;\n}\n\n.h1-text-quantity {\n  border: 1px solid #e9ecef;\n  min-width: 55px;\n  height: 40px;\n  border-radius: 10px;\n  font-weight: 500 !important;\n  font-size: 16px;\n  display: flex;\n  text-align: center;\n  align-items: center;\n  justify-content: center;\n}\n\n.range_picker {\n  width: 100px;\n  border: 1px solid var(--Stroke, #e5e6e7);\n  border-radius: 30px;\n  font-size: 14px;\n  min-height: 40px;\n  padding: 0px 10px;\n  color: #5c677d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
