import { Form, Modal, Image } from "react-bootstrap";
import report from "../../images/customer/report-modal.png";
import "../modal.css";
import Button from "../../common/Button/Button";
import close from "../../images/customer/close.png";

interface ReportModalProps {
  show: boolean;
  onHide: () => void;
}

const ReportModal: React.FC<ReportModalProps> = ({ show, onHide }) => {
  const handleClose = () => {
    onHide();
  };

  return (
    <div>
      <Modal className="modal-report" show={show} onHide={handleClose}>
        <div className="close-icon">
          <Image
            src={close}
            onClick={handleClose}
            className="img-close"
            alt="img"
          />
        </div>

        <Modal.Header className="border-bottom-0">
          <Modal.Title className="mx-auto">
            <span className="header-modal">Report an Issue</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-modal">Issue</Form.Label>
              <Form.Control
                type="email"
                placeholder="Issue you’re facing"
                autoFocus
                className="form-input-modal form-height"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="label-modal">
                What’s the Problem
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Explain your issue briefly..."
                className="form-input-modal"
                rows={5}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-top-0 justify-content-center">
          <Button
            variant="variant"
            type="submit"
            className="btn-report-modal"
            onClick={handleClose}
          >
            <Image src={report} alt="img" /> Report
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReportModal;
