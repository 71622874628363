import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DatepickerProps extends ReactDatePickerProps {
    placeholderText?: string;
    className?: string;
}

const Datepicker: React.FC<DatepickerProps> = ({
    onChange,
    selected,
    placeholderText,
    className,
    ...restProps
}) => {
    return (
        <DatePicker
            selected={selected}
            onChange={onChange}
            dateFormat="dd/MM/yyyy"
            placeholderText={placeholderText}
            className={className}
            minDate={new Date()}
            {...restProps}
        />
    );
};

export default Datepicker;
