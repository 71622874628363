import React, { useState } from 'react';
import Layout from '../../../layout/layout'
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import { images } from '../../../../../common/constant/constant';
import added from "../../../../../images/customer/added.png";
import left from "../../../../../images/customer/left.png";
import { useNavigate } from 'react-router-dom';
import { TEXT_BLACK } from '../../../../../styles/colors';
import Button from '../../../../../common/Button/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar from '../../../../../images/calendar.png';


const AddNewProperties: React.FC = () => {
    const navigate = useNavigate();
    const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(null);
    const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(null);


    const handleCheckInDate = (date: Date | null) => {
        setSelectedChcekInDate(date);
    };

    const handleCheckOutDate = (date: Date | null) => {
        setSelectedChcekOutDate(date);
    };
    return (
        <Layout>
            <main className="main users">
                <Container>
                    <div className='mb-4'>
                        <Image onClick={() => navigate('/landlord-manage-properties')} className='img-click' src={left} alt="img" />
                    </div>
                    <div className="add_property_sec pb-3">
                        <div className="property_wrap">
                            <div className='d-flex align-items-center mb-5 landload_add_new_properties'>
                                <h3 className='text-center text-add-text m-auto mb-3' style={{ color: TEXT_BLACK }}>Add New Property</h3>
                                <span>
                                    <Button className='create_btn' type="button" onClick={() => console.log('/')}>Create Property</Button>
                                </span>
                            </div>

                            <Row>
                                <Col lg={3}>
                                    <section className="img__container">
                                        <h4>Upload Pictures</h4>
                                        <div className="upload_sec py-3">

                                            <div className="upload_sec py-3">
                                                {images.map((item, index) => (
                                                    <div key={index} className="upload_images">
                                                        <Image src={item.img} alt="img" />
                                                    </div>
                                                ))}
                                                <div className="property_upload">
                                                    <input type="file" id="myfile" name="myfile" accept="image/*" className="upload" />
                                                    <label htmlFor="myfile" className="upload--btn">Add<br /><Image src={added} /></label>
                                                </div>
                                            </div>

                                        </div>
                                    </section>
                                </Col>

                                <Col lg={9}>
                                    <div className="addproperty_form">
                                        <Form>
                                            <Row>
                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Title<span>*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Title" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Price<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Category<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Taxes<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Service Charge (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="₦" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Other Fees (Flat)<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="S" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>360 Virtual Link<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="https://" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Is Featured<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Features<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Features" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Amenity<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={6} className='mb-3'>
                                                    <Form.Label>Quantity<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Quantity" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={12} className='mb-3'>
                                                    <Form.Label>Complete Address<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Full Address" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Country<span>*</span></Form.Label>
                                                    <Form.Control as="select">
                                                        <option>select</option>
                                                    </Form.Control>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>State<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="State" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>City<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="City" />
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3 position-relative'>
                                                    <Form.Label>Check In<span>*</span></Form.Label>
                                                    <DatePicker
                                                        selected={selectedChcekInDate}
                                                        onChange={handleCheckInDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control"
                                                        placeholderText="05 May 2024"
                                                        minDate={new Date()}
                                                    />
                                                    <span className='date_icon'>
                                                        <img src={calendar} alt="img" />
                                                    </span>
                                                </Form.Group>

                                                <Form.Group as={Col} md={4} className='mb-3 position-relative'>
                                                    <Form.Label>Check Out<span>*</span></Form.Label>
                                                    <DatePicker
                                                        selected={selectedChcekOutDate}
                                                        onChange={handleCheckOutDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="form-control"
                                                        placeholderText="10 May 2024"
                                                        minDate={new Date()}
                                                    />
                                                    <span className='date_icon'>
                                                        <img src={calendar} alt="img" />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} md={4} className='mb-3'>
                                                    <Form.Label>Caution Deposit<span>*</span></Form.Label>
                                                    <Form.Control type="email" placeholder="Refundable" />
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container >
            </main >
        </Layout >
    )
}

export default AddNewProperties
