import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { pink } from '@mui/material/colors';
import { BORDER_GREY } from '../../styles/colors';


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Rating() {
    return (
        <div>
            <Checkbox style={{ backgroundColor: '#FFFFFF', width:'30px', height:'30px' }} {...label} icon={<FavoriteBorder sx={{ color: BORDER_GREY }} />} checkedIcon={<Favorite />} sx={{
                color: pink[800],
                '&.Mui-checked': {
                    color: pink[600],
                },
            }} />
        </div>
    );
}