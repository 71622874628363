import React, { useState } from "react";
import Layout from "../../../layout/layout";
import { Table, Dropdown, Container, Form, Image } from "react-bootstrap";
import { agentBookingActive } from "../../../../../common/constant/constant";
import Button from "../../../../../common/Button/Button";
import line from "../../../../../images/customer/line.png";
import view from "../../../../../images/customer/view.png";
import ReportModal from "../../../../../modal/reportModal/ReportModal";
import AgentBookingDetails from "../../../../../modal/agentBookingDetails/AgentBookingDetails";
import DeleteModal from "../../../../../modal/deleteModal/DeleteModal";
import BookingArchiveDetails from "../../../../../modal/archiveModal/BookingArchiveDetails";

const ArchiveLandload = () => {
    const [sortBy, setSortBy] = useState<string>("Name A - Z");
    const [selectedTab, setSelectedTab] = useState("All");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalBooking, setShowModalBooking] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showArchiveModalBooking, setShowArchiveModalBooking] = useState<boolean>(false);
    const pendinButtonIndices = [0];
    const acceptedButtonIndices = [1, 3];
    const rejectButtonIndices = [2];
    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(event.target.value);
    };

    const handleTabSelect = (tab: string) => {
        setSelectedTab(tab);
    };
    return (
        <Layout>
            <main className="main users tabel_page">
                <Container>
                    <div>
                        <ul className="nav nav-underline gap-4">
                            {[
                                "All",
                                "Pending",
                                "Approved",
                                "Reserved",
                            ].map((tab) => (
                                <li className="nav-item" key={tab}>
                                    <button
                                        className={`nav-link hide-link ${selectedTab === tab ? " active" : ""
                                            }`}
                                        onClick={() => handleTabSelect(tab)}
                                    >
                                        {tab.replace("-", " ")}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="table_block">
                        <div className="card shadow mb-4">
                            <div className="table_handle ">
                                <div className="table_input_block">
                                    <div className="search_table search_table_landload position-relative">
                                        <input
                                            type="search"
                                            className="form-control form-control-landload rounded"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="search-addon"
                                        />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M16.6667 16.6667L13.1694 13.1694M15 8.75C15 5.29822 12.2017 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2017 5.29822 15 8.75 15C12.2017 15 15 12.2017 15 8.75Z"
                                                stroke="#808282"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="btns_sec btn_sec_landload">
                                    <div>
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"
                                                fill="none">
                                                <path
                                                    d="M14.2673 0.36339C9.9321 -0.12113 5.55644 -0.12113 1.22127 0.36339C0.976329 0.390625 0.743237 0.483286 0.546451 0.631647C0.349665 0.780008 0.196439 0.978601 0.102852 1.20659C0.00926425 1.43457 -0.0212341 1.68354 0.0145567 1.92738C0.0503475 2.17121 0.151108 2.40092 0.306265 2.59239L4.07527 7.25139C5.15493 8.58605 5.74405 10.2507 5.74427 11.9674V15.1094C5.74418 15.2272 5.77184 15.3433 5.825 15.4484C5.87816 15.5535 5.95534 15.6447 6.05027 15.7144L8.82127 17.7464C8.90772 17.8098 9.01005 17.848 9.1169 17.8567C9.22375 17.8655 9.33094 17.8445 9.42656 17.796C9.52218 17.7475 9.60249 17.6735 9.65857 17.5821C9.71466 17.4907 9.74432 17.3856 9.74427 17.2784V11.9674C9.74453 10.2502 10.334 8.58519 11.4143 7.25039L15.1823 2.59039C15.3372 2.39891 15.4377 2.16929 15.4733 1.9256C15.509 1.68191 15.4784 1.43312 15.3849 1.2053C15.2913 0.977484 15.1382 0.779025 14.9415 0.630724C14.7449 0.482423 14.512 0.390741 14.2673 0.36339Z"
                                                    fill="#163873" />
                                            </svg></span>
                                        </div>
                                        <Form.Label className="form-label m-0">Sort By:</Form.Label>
                                        <Form.Select
                                            className="form-select landload-select"
                                            aria-label="Default select example"
                                            value={sortBy}
                                            onChange={handleSortChange}
                                        >
                                            <option value="Name A - Z">Name A - Z</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table striped id="dataTable" responsive>
                                        <thead>
                                            <tr>
                                                <th>Picture</th>
                                                <th className="border-th">Booked By</th>
                                                <th className="border-th">Property</th>
                                                <th className="border-th">Agent Code</th>
                                                <th className="border-th">Check In</th>
                                                <th className="border-th">Check Out</th>
                                                <th className="border-th">Actual Price</th>
                                                <th className="border-th">Negotiable Price</th>
                                                <th className="border-th">Status</th>
                                                <th className="border-th">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {agentBookingActive.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <img src={item.picture} alt="img" />
                                                    </td>
                                                    <td>{item.bookedBy}</td>
                                                    <td>{item.property}</td>
                                                    <td>{item.agentCode}</td>
                                                    <td>{item.checkIn}</td>
                                                    <td>{item.checkIn}</td>
                                                    <td>{item.actualPrice}</td>
                                                    <td>{item.negotiablePrice}</td>
                                                    <td>
                                                    {pendinButtonIndices.includes(index) && (
                              <Button
                                variant="variant"
                                className="btn_pending_customer_a btn-variant btn_pending_customer m-auto"
                                type="button"
                                onClick={() => console.log("pending button")}
                              >
                                Pending
                              </Button>
                            )}

                            {acceptedButtonIndices.includes(index) && (
                              <Button
                                variant="variant"
                                className="btn_accept_customer_a btn-variant btn_pending_customer m-auto"
                                type="button"
                                onClick={() => console.log("pending button")}
                              >
                                Accept
                              </Button>
                            )}

                            {rejectButtonIndices.includes(index) && (
                              <Button
                                variant="variant"
                                className="btn_reject_customer btn-variant btn_pending_customer m-auto"
                                type="button"
                                onClick={() => console.log("pending button")}
                              >
                                Reject
                              </Button>
                            )}
                                                    </td>
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="bg-none border-0 w-0">
                                                                <Image src={line} alt="line" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="customer_dropdown_tabal">
                                                                <Dropdown.Item onClick={() => setShowModalBooking(true)}>
                                                                    <img src={view} className="px-1" alt="View" />
                                                                    View
                                                                </Dropdown.Item>
                                                                {/* <Dropdown.Item onClick={() => setShowArchiveModalBooking(true)}>
                                                                    <img
                                                                        src={archive}
                                                                        className="px-1"
                                                                        alt="Edit"
                                                                    />{" "}
                                                                    Archive
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className="text-danger" onClick={() => setShowDeleteModal(true)}>
                                                                    <img
                                                                        src={bin}
                                                                        className="px-1"
                                                                        alt="Delete"
                                                                    />
                                                                    Delete
                                                                </Dropdown.Item> */}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </main>
            {showModal && (
                <ReportModal show={showModal} onHide={() => setShowModal(false)} />
            )}
            {showModalBooking && (
                <AgentBookingDetails
                    show={showModalBooking}
                    onHide={() => setShowModalBooking(false)}
                />
            )}
            {showArchiveModalBooking && (
                <BookingArchiveDetails
                    show={showArchiveModalBooking}
                    onHide={() => setShowArchiveModalBooking(false)}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                />
            )}
        </Layout>
    );
};
export default ArchiveLandload;
