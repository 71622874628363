// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-generate {
    border: none;
    display: flex;
    width: 100%;
    min-width: 123px !important;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #163873;
    color: var(--White, #fff);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/components/agentpanel/styles/common.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;IACX,2BAA2B;IAC3B,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".btn-generate {\n    border: none;\n    display: flex;\n    width: 100%;\n    min-width: 123px !important;\n    height: 40px;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n    border-radius: 8px;\n    background: #163873;\n    color: var(--White, #fff);\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
