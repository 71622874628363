import React, { useState } from "react";
import { PropertyList } from "../../../../common/constant/constant";
import Layout from "../../layout/layout";
import { Table, Dropdown, Container, Form, Image } from "react-bootstrap";
import DeleteModal from "../../../../modal/deleteModal/DeleteModal";
import line from "../../../../images/customer/line.png";
import view from "../../../../images/customer/view.png";
import Button from "../../../../common/Button/Button";
import boost from "../../../../images/boost.png";
import AsignModal from "../../../../modal/asignModal/AsignModal";
import BootsModal from "../../../../modal/bootsModal/BootsModal";
import { useNavigate, Link } from "react-router-dom";
const AssignedPropertiesAgent: React.FC = () => {
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState<string>("Name A - Z");
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showAgentModal, setShowAgentModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(event.target.value);
    };
    return (
        <Layout>
            <main className="main users tabel_page">
                <Container>
                    <div className="table_block">
                        <div className="card shadow mb-4">
                            <div className="table_handle ">
                                <div className="table_input_block">
                                    <div className="search_table search_table_landload position-relative">
                                        <input
                                            type="search"
                                            className="form-control form-control-landload rounded"
                                            placeholder="Search"
                                            aria-label="Search"
                                            aria-describedby="search-addon"
                                        />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                d="M16.6667 16.6667L13.1694 13.1694M15 8.75C15 5.29822 12.2017 2.5 8.75 2.5C5.29822 2.5 2.5 5.29822 2.5 8.75C2.5 12.2017 5.29822 15 8.75 15C12.2017 15 15 12.2017 15 8.75Z"
                                                stroke="#808282"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        {/* add classname for manage add button dimension */}
                                        <div className="direction_f gen_btn">
                                            <Button
                                                variant="variant"
                                                className=" btn-generate d-flex"
                                                type="button"
                                                onClick={() => navigate('/agent-add-new-properties')}
                                            >
                                                <i className="bi bi-plus-lg" style={{ minHeight: "24px", minWidth: "24px" }}></i>Add
                                            </Button>
                                        </div>
                                    </div>
                                    {/* add classname for manage sortby and namea-z one line dimension */}
                                    <div className="btns_sec btns_section d-flex align-items-baseline">
                                        {/* add filter icon */}
                                          <div>
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"
                                                fill="none">
                                                <path
                                                    d="M14.2673 0.36339C9.9321 -0.12113 5.55644 -0.12113 1.22127 0.36339C0.976329 0.390625 0.743237 0.483286 0.546451 0.631647C0.349665 0.780008 0.196439 0.978601 0.102852 1.20659C0.00926425 1.43457 -0.0212341 1.68354 0.0145567 1.92738C0.0503475 2.17121 0.151108 2.40092 0.306265 2.59239L4.07527 7.25139C5.15493 8.58605 5.74405 10.2507 5.74427 11.9674V15.1094C5.74418 15.2272 5.77184 15.3433 5.825 15.4484C5.87816 15.5535 5.95534 15.6447 6.05027 15.7144L8.82127 17.7464C8.90772 17.8098 9.01005 17.848 9.1169 17.8567C9.22375 17.8655 9.33094 17.8445 9.42656 17.796C9.52218 17.7475 9.60249 17.6735 9.65857 17.5821C9.71466 17.4907 9.74432 17.3856 9.74427 17.2784V11.9674C9.74453 10.2502 10.334 8.58519 11.4143 7.25039L15.1823 2.59039C15.3372 2.39891 15.4377 2.16929 15.4733 1.9256C15.509 1.68191 15.4784 1.43312 15.3849 1.2053C15.2913 0.977484 15.1382 0.779025 14.9415 0.630724C14.7449 0.482423 14.512 0.390741 14.2673 0.36339Z"
                                                    fill="#163873" />
                                            </svg></span>
                                           </div>
                                        <Form.Label className="form-label m-0">Sort By:</Form.Label>
                                        <Form.Select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={sortBy}
                                            onChange={handleSortChange}
                                        >
                                            <option value="Name A - Z">Name A - Z</option>
                                            <option value="A">A</option>
                                            <option value="B">B</option>
                                            <option value="C">C</option>
                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive">
                                    <Table striped id="dataTable" responsive>
                                        <thead>
                                            <tr>
                                                <th>Picture</th>
                                                <th className="border-th">Title</th>
                                                <th className="border-th">Country</th>
                                                <th className="border-th">State</th>
                                                <th className="border-th">City</th>
                                                <th className="border-th">Category</th>
                                                <th className="border-th">Amenities</th>
                                                <th className="border-th">Features</th>
                                                <th className="border-th">Ad Boost</th>
                                                <th className="border-th">Price</th>
                                                <th className="border-th">Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {PropertyList.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <img src={item.picture} alt="img" />
                                                    </td>
                                                    <td>{item.title}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.state}</td>
                                                    <td className="text_green">{item.city}</td>
                                                    <td className="text_green">{item.category}</td>
                                                    <td className="text_green">{item.amenities}</td>
                                                    <td className="text_green">{item.features}</td>
                                                    <td className="text_green">
                                                        <Button
                                                            className="btn_boost border-0"
                                                            type="button"
                                                            onClick={() => setShowModal(true)}
                                                        >
                                                            <img src={boost} alt="Boost" /> Boost
                                                        </Button></td>
                                                    <td className="text_green">{item.price}</td>
                                                    <td>
                                                        {/* add new property_details className for view button  */}
                                                        <Dropdown>
                                                            <Dropdown.Toggle className="bg-none border-0 w-0">
                                                                <Image src={line} alt="line" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="customer_dropdown_tabal">
                                                                <Dropdown.Item as={Link} to="/agent-property-details" className="property_details">
                                                                    <img src={view} className="px-1" alt="View" />
                                                                    View
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </main>
            {showModal && (
                <BootsModal
                    show={showModal}
                    onHide={() => setShowModal(false)} />
            )}
            {showDeleteModal && (
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                />
            )}
            {showAgentModal && (
                <AsignModal
                    show={showAgentModal}
                    onHide={() => setShowAgentModal(false)}
                />
            )}
        </Layout>
    );
};

export default AssignedPropertiesAgent;