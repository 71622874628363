import React from "react";
import { Modal, Image } from "react-bootstrap";
import close from "../../images/customer/close.png";
import alert from "../../images/customer/deleteIcon.png";
import "../modal.css";
import Button from "../../common/Button/Button";

interface DeleteModalProps {
    show: boolean;
    onHide: () => void;
}
const DeleteModal: React.FC<DeleteModalProps> = ({ show, onHide, }) => {
    const handleClose = () => {
        onHide();
    };

    const handleDelete = () => {
        onHide();
    }
    return (
        <Modal show={show} onHide={handleClose} className="modal-delete" centered>
            <div className="close-icon">
                <Image
                    src={close}
                    onClick={handleClose}
                    className="img-close"
                    alt="img"
                />
            </div>

            <Modal.Header className="border-bottom-0 justify-content-center">
                <Image src={alert} alt="img-add" />
            </Modal.Header>
            <Modal.Body className="text-center justify-content-center">
                <div className="mb-4">
                    <h2 className="text-center mb-2 text__modal">Are you Sure?</h2>
                </div>
                <div>
                    <p className="text-center mb-0 text_modal_para">You want to delete this property?</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0 justify-content-center">
                <Button
                    variant="outline"
                    className="delete__btn"
                    type="button"
                    onClick={handleDelete}                >
                    Delete
                </Button>
                <Button
                    variant="variant"
                    className="cancel__btn"
                    type="button"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
