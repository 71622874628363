import React from "react";
import Rating from "../../../../common/rating/Rating";
import { Carousel, Col, Container, Image } from "react-bootstrap";
import card_user from "../../../../images/card-user.png";
import card_bed from "../../../../images/card-bed.png";
import card_location from "../../../../images/card-location.png";
import wifi from "../../../../images/wifi.png";
import icon_p from "../../../../images/icon-p.png";
import dotimg from "../../../../images/dotimg.png";
import { cardData } from "../../../../common/userCard/UserCard";
import Button from "../../../../common/Button/Button";

const RelatedProperties = () => {
  return (
    <section className="my_booking_properties py-3 py-lg-5">
      <Container>
        <div className="my_booking_properties_header d-flex align-items-center justify-content-between flex-wrap py-3 py-lg-5">
          <h2 className="h2_tag_related">Related Properties</h2>
          <p>
            <a
              href="/"
              style={{
                color: "#163873",
                textDecoration: "underline",
                fontWeight: 700,
              }}
            >
              View All
            </a>
          </p>
        </div>
        <div className="row-card-booking row row-cols-12 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
          {cardData.map((item, index) => (
            <Col>
              <div key={index} className="card home_card ">
                <div className="image_body ">
                  <div className="pic_item position-relative">
                    <Carousel>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img}
                          alt="img"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img02}
                          alt="img"
                        />
                      </Carousel.Item>
                      <Carousel.Item interval={1000}>
                        <Image
                          className="mx-auto w-100"
                          src={item.img03}
                          alt="img"
                        />
                      </Carousel.Item>
                    </Carousel>

                    <div className="image_header d-flex justify-content-between">
                      <span>
                        <Rating />
                      </span>
                      <div className="quantity_box d-flex justify-content-between dot_center">
                        <div className="box-quant">
                          <Image src={card_user} alt="img" />
                          <span>{item.cardUser}</span>
                        </div>
                        <Col xs="auto" className="media">
                          <img src={dotimg} alt="img" />
                        </Col>

                        <div className="box-quant">
                          <Image src={card_bed} alt="img" />
                          <span>{item.badNumber}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="badge_btn">
                    <p>{item.cardtitle}</p>
                  </div>
                  <div className="card_heading_block">
                    <h2>{item.cardHeading}</h2>
                    <span>${item.cardRate}</span>
                  </div>

                  <div className="card_location">
                    <span>
                      <Image
                        src={card_location}
                        alt="img"
                        className="card_location_a border-bottom-0"
                      />
                    </span>
                    <p className="p-tag-cards">{item.locationApt}</p>
                  </div>

                  <div className="card_desc">
                    <p>{item.discription}</p>
                    <div className="card_bottom_icon">
                      <span>
                        <Image src={wifi} alt="img" />
                      </span>
                      <span>
                        <Image src={icon_p} alt="img" />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card_btn">
                  <Button
                    type="button"
                    variant="variant"
                    className="btn btn_primary btn-common"
                    onClick={() => console.log("Button clicked")}
                  >
                    More Info
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default RelatedProperties;
