import React from "react";
import BookingListing from "./bookingListing/BookingListing";

const AllBooking = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <div>
      <BookingListing />
    </div>
  );
};

export default AllBooking;
