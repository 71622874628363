import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Image } from "react-bootstrap";
import banner from "../../../images/my-booking-banner.png";
import card_user from "../../../images/card-user.png";
import card_bed from "../../../images/card-bed.png";
import dotimg from "../../../images/dotimg.png";
import start from "../../../images/star.png";
import Button from "../../../common/Button/Button";
import { useNavigate } from "react-router";
import chatIcon from "../../../images/chatIcon.png";
import DatePicker from "react-datepicker";

const MyBooking: React.FC = () => {
  const navigate = useNavigate();
  const [selectedChcekInDate, setSelectedChcekInDate] = useState<Date | null>(
    null
  );
  const [selectedChcekOutDate, setSelectedChcekOutDate] = useState<Date | null>(
    null
  );

  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const handleCheckInDate = (date: Date | null) => {
    setSelectedChcekInDate(date);
  };

  const handleCheckOutDateChange = (date: Date | null) => {
    setSelectedChcekOutDate(date);
  };
  return (
    <section
      id="detail_banner"
      className="detail_banner my_booking_banner pb-3 pb-lg-5"
    >
      <Container>
        <Row className="g-3">
          <Col lg={7}>
            <div className="bg-detail" style={{ position: "relative" }}>
              <img
                src={banner}
                alt="Booking Banner"
                className="booking-banner-img"
              />
              <div className="image_header_booking d-flex justify-content-end px-4">
                <div className="quantity_box d-flex justify-content-between align-items-center">
                  <div className="box-quant">
                    <Image
                      className="img_dimension"
                      src={card_user}
                      alt="img"
                    />
                    <span className="span-tag-card">{"10"}</span>
                  </div>
                  <Col xs="auto" className="media">
                    <img src={dotimg} alt="img" />
                  </Col>

                  <div className="box-quant">
                    <Image
                      src={card_bed}
                      alt="img"
                      className="img_bed_dimension"
                    />
                    <span className="span-tag-card">{"5"}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <Card className="booking_card py-2 my-2">
              <Card.Body>
                <div className="my_booking_header d-flex align-items-center justify-content-between flex-wrap">
                  <h4 className="h4-tab-booking">2 Bedroom Apartment</h4>
                  <Button
                    type="button"
                    variant="outline"
                    className="btn_chat"
                    onClick={() => navigate("/chat")}
                  >
                    <img src={chatIcon} alt="Chat Icon" className="icon-chat" />
                    Chat with Support
                  </Button>
                </div>
                <div className="booking_card_header">
                  <div className="booking_dollar d-flex align-items-center justify-content-between">
                    <p>
                      $ 250<span> / Mon</span>
                    </p>
                    <p>
                      <img className="star" src={start} alt="Star" />
                      <span style={{ fontWeight: 500, fontSize: 24 }}>
                        4.6 (456)
                      </span>
                    </p>
                  </div>
                  <div className="booking_card_content border-bottom d-flex align-items-center justify-content-between py-3">
                    <div className="input_group">
                      <Form.Label>Check In</Form.Label>
                      <DatePicker
                        selected={selectedChcekInDate}
                        onChange={handleCheckInDate}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="06 May, 2023"
                        className="border-0 date_input_field"
                        minDate={new Date()}
                      />
                    </div>
                    <div className="input_group">
                      <Form.Label>Check Out</Form.Label>
                      <DatePicker
                        selected={selectedChcekOutDate}
                        onChange={handleCheckOutDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="border-0 date_input_field"
                        placeholderText="06 May, 2023"
                        minDate={new Date()}
                      />
                    </div>
                  </div>

                  <div className="booking_card_content py-3">
                    <div className="agent_code">
                      <p>Nights</p>
                      <h3>2</h3>
                    </div>
                  </div>
                  <div className="booking_card_content border-bottom d-flex align-items-center justify-content-between py-3">
                    <div className="input_group">
                      <Form.Label>Cleaning Fee</Form.Label>
                      <h6>$ 10</h6>
                    </div>
                    <div className="input_group">
                      <Form.Label>Taxes & Fee</Form.Label>
                      <h6>$ 10</h6>
                    </div>
                  </div>
                  <div className="booking_card_content d-flex align-items-center justify-content-between py-3">
                    <div className="total" style={{ width: "48%" }}>
                      <Form.Label>Total</Form.Label>
                    </div>
                    <div className="total-a" style={{ width: "48%" }}>
                      <Form.Label>$ 270</Form.Label>
                    </div>
                  </div>
                  <div className="booking_card_content border-top d-flex align-items-center justify-content-between py-3 ">
                    <p className="text-center font-size-16 mb-0 p_tag_text">
                      Thank you for your booking, please proceed to payment to
                      confirm your booking.
                    </p>
                  </div>
                  <div className="booking_card_content">
                    <div className="reserve">
                      <Button
                        type="button"
                        variant="primary"
                        className="btn btn_primary border-0"
                        onClick={() => console.log("Button clicked")}
                      >
                        Proceed to Payment
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MyBooking;
