import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import rating_01 from "../../../../images/rating-1.png";
import rating_02 from "../../../../images/rating-2.png";
import rating_03 from "../../../../images/rating_3.png";
import rating_04 from "../../../../images/rating_4.png";
import rating_05 from "../../../../images/rating_5.png";
import PropertiesCard from "./PropertiesCard";
import RangeSlider from "../../../../common/slider/RangeSlider";
import Searchbar from "../../../../common/searchbar/Searchbar";
import { maxValue, minValue } from "../SearchBox/SearchBox";

const PropertiesFilters = () => {
  const [visibleItems, setVisibleItems] = useState<number>(5);
  const cities = [
    "New York, USA",
    "Sydney, Australia",
    "Mumbai, India",
    "Moscow, Russia",
    "Vancouver, Canada",
    "Karachi, Pakistan",
    "Barcelona, Spain",
    "Reykjavik, Iceland",
    "Delhi, India",
    "Colombo, Sri Lanka",
  ];

  // Next 5 cities showing onclick event added.
  const handleMoreClick = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5);
  };

  const handleCustomSearch = () => {
    console.log("submit");
  };

  return (
    <div>
      <section className="properties_filter">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="filter_sidebar_header pb-3 pb-lg-5">
                <h4 className="fliter-tag-css">Filters</h4>
              </div>
              <div className="filter_block">
                <Row className="filter_side">
                  <div>
                    <h5 className="filter-left-h4-tag">Categories</h5>
                    <div className="side_items">
                      <Form.Check
                        type="checkbox"
                        id="shortLet"
                        label="Short Let (125)"
                      />
                      <Form.Check
                        type="checkbox"
                        id="longLease"
                        label="Medium Lease (300)"
                      />
                      <Form.Check
                        type="checkbox"
                        id="mediumLease"
                        label="Long Lease (250)"
                      />
                      <Form.Check
                        type="checkbox"
                        id="rentFinancing"
                        label="Rent Financing (500)"
                      />
                    </div>
                  </div>
                  <div>
                    <h5 className="filter-left-h4-tag">Location</h5>
                    <div className="side_items">
                      <Searchbar
                        handleSearch={handleCustomSearch}
                        placeholder="Search"
                        inputColor="#B8B8B8 !important"
                        buttonColor="#808282"
                        customStyles={{
                          border: "1px solid #E5E6E7",
                          borderRadius: "10px",
                          fontWeight: "500",
                          fontSize: "14px !important",
                          maxWidth: "314px !important",
                          maxHeight: "40px !important",
                        }}
                      />
                    </div>
                  </div>

                  <div className="side_items">
                    <div className="side_items">
                      {cities.slice(0, visibleItems).map((city, index) => (
                        <Form.Check key={index} type="checkbox" label={city} />
                      ))}
                      {cities.length > visibleItems && (
                        <div>
                          <p className="more-text" onClick={handleMoreClick}>
                            {`+${cities.length - visibleItems} more`}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <h5 className="filter-left-h4-tag">Price</h5>
                    <div className="side_items">
                      <div className="tab_range range-div">
                        <div className="value-label py-2">
                          <span>Min</span>
                          <span>Max</span>
                        </div>
                        <div className="row">
                          <RangeSlider min={minValue} max={maxValue} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className="filter-left-h4-tag">Rating</h5>
                    <div className="side_items">
                      <Form.Check
                        type="checkbox"
                        id="rating1"
                        label={<img src={rating_01} alt="img" />}
                      />
                      <Form.Check
                        type="checkbox"
                        id="rating2"
                        label={<img src={rating_02} alt="img" />}
                      />
                      <Form.Check
                        type="checkbox"
                        id="rating3"
                        label={<img src={rating_03} alt="img" />}
                      />
                      <Form.Check
                        type="checkbox"
                        id="rating4"
                        label={<img src={rating_04} alt="img" />}
                      />
                      <Form.Check
                        type="checkbox"
                        id="rating5"
                        label={<img src={rating_05} alt="img" />}
                      />
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col>
              <PropertiesCard />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PropertiesFilters;
