import React, { useState } from 'react';
import { Container, Form, Image, } from 'react-bootstrap';
import password from "../../../../images/login/password-login.png";
import Button from '../../../../common/Button/Button';
import { useNavigate } from 'react-router-dom';
import close from "../../../../images/customer/hide.svg";
import open from "../../../../images/customer/open.svg";



const ResetPassword: React.FC = () => {
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);


    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/forgot-password')
    }
    return (
        <Container>
            <div className='d-flex p-4 back-div' onClick={handleBack}>
                <i className="bi bi-arrow-left"></i>
                <span className='px-2'>Back</span>
            </div>
            <div className='forgot-pass-div'>
                <h6 className='forgot-p text-center'>Reset Password</h6>
                <div>
                    <p className='forgot-para'>
                        Follow the prompts on the screen to create a new password and regain access to your account. We take your security seriously, so be sure to choose a strong and unique password to protect your account information.
                        If you need further assistance, our <a className="text-decoration-underline" href="">Support team</a> is always here to help.
                    </p>
                </div>
                <div>
                    <Form.Group className="my-4 owner_input position-relative">
                        <Form.Control
                            className='form-control-forgot'
                            type={showPassword ? 'text' : 'password'}
                            placeholder="New Password"
                        />
                        <span className='password-icon'>
                            <Image src={password} alt="img" />
                        </span>
                        <Image
                            src={showPassword ? open : close}
                            className='show-password'
                            onClick={() => setShowPassword(!showPassword)}
                            alt="password"
                        />
                    </Form.Group>

                    <Form.Group className="my-4 owner_input position-relative">
                        <Form.Control
                            className='form-control-forgot'
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password" />
                        <span className='password-icon'>
                            <Image src={password} alt="img" />
                        </span>
                        <Image
                            src={showConfirmPassword ? open : close}
                            className='show-password'
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            alt="password"
                        />
                    </Form.Group>
                </div>
                <div className="owner_btn btn-width mx-auto ">
                    <Button
                        type="submit"
                        className="w-100 btn btn-primary"
                        onClick={() => navigate("/customer-login")}
                    >
                        Save Changes
                    </Button>
                </div>


            </div>
            <p className="register_login text-center ">
                Not you? <a href="/login">Login</a>
            </p>
        </Container>
    )
}

export default ResetPassword;
