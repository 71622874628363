import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ChatSidebar from "./chatSidebar/ChatSidebar";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatSection from "./chatSection/ChatSection";
import ChatLeftComponent from "./chatLeftComponent/ChatLeftComponent";

const ChatComponent: React.FC = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
  return (
    <div>
      <main style={{ background: "#FAFAFA" }}>
        <section className="chat_sec">
          <Container>
            <Row className="overflow-hidden">
              <Col lg={3}>
                <ChatSidebar />
              </Col>
              <Col lg={6}>
                <div className="agent_chatbot">
                  <div className="col-12">
                    <ChatHeader />
                    <ChatSection />
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <ChatLeftComponent />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </div>
  );
};
export default ChatComponent;
