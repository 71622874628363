import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import quality1 from "../../../../images/quality-1.png";
import quality2 from "../../../../images/quality-2.png";
import quality3 from "../../../../images/quality-3.png";
import quality4 from "../../../../images/quality-4.png";
import Button from "../../../../common/Button/Button";
import "../../../userpenal/styles/styles.css";
const QualityListing: React.FC = () => {
  return (
    <section className="quality_sec">
      <Container>
        <Row>
          <Col lg={8}>
            <div className="quality_content">
              <h2 style={{color:"#010404"}}>
                We provide the highest
                <br />
                <span>Quality Listings</span>
              </h2>
              <p>
                Here at MyHomesNG, we are committed to offering quality listings
                with the best service offering to our client base. Our hosts
                have been verified through a rigorous process that ensures the
                safety of all customers. Our listings are equipped with
                state-of-the-art amenities.
                <br />
                <br />
                We have taken time to physically inspect all listed property to
                ensure they uphold the high standards of service MyHomesNG is
                known for. Seeing will indeed be believing
              </p>
              <div className="btn_sec py-3">
                <Button
                  type="button"
                  variant="primary"
                  className="btn btn_primary border-0"
                  onClick={() => console.log("Button clicked")}
                >
                  Explore Properties
                </Button>

                <Button
                  type="button"
                  variant="outline"
                  className="btn btn_secondary"
                  onClick={() => console.log("Button clicked")}
                >
                  More Info
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={4}>
            <Row className="homeb">
              <Col sm={6} className="pt-5">
                <div className="quality_pic">
                  <img className="quality_img" src={quality1} alt="Short Let" />
                  <div className="quality_caption">
                    <h5>Short Let</h5>
                    <p>250,000+</p>
                  </div>
                </div>
                <div className="quality_pic">
                  <img
                    className="quality_img"
                    src={quality3}
                    alt="Medium Lease"
                  />
                  <div className="quality_caption">
                    <h5>Medium Lease</h5>
                    <p>250,000+</p>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="quality_pic">
                  <img
                    className="quality_img"
                    src={quality2}
                    alt="Long Lease"
                  />
                  <div className="quality_caption">
                    <h5>Long Lease</h5>
                    <p>250,000+</p>
                  </div>
                </div>
                <div className="quality_pic">
                  <img
                    className="quality_img"
                    src={quality4}
                    alt="Rent Financing"
                  />
                  <div className="quality_caption">
                    <h5>Rent Financing</h5>
                    <p>250,000+</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default QualityListing;
