import Layout from "../../layout/layout";
import { Container, Image } from "react-bootstrap";
import { agentwalletdata, images } from '../../../../common/constant/constant';
import { useNavigate } from "react-router-dom";
import left from "../../../../images/customer/left.png";

interface PropertyItem {
    title: string;
    label: string;
}

interface AssignedPropertyDetailsProps {
    data: PropertyItem[];
}


const AssignedPropertyDetails: React.FC = () => {
    const navigate = useNavigate();

    const PropertyDetailsSection: React.FC<AssignedPropertyDetailsProps> = ({ data }) => {
        return (
            <div className="addproperty_form">
                <div className="agent_property_details">
                    {data.map((item, index) => (
                        <div key={index} className="details_item">
                            <label>{item.title}</label>
                            <p>{item.label}</p>
                        </div>
                    ))}
                </div>
            </div>
        );
    };



    const renderPropertyDetails = (data: any[]) => {
        return data.map((item, index) => (
            <div key={index} className="details_item">
                <label>{item.title}</label>
                <p>{item.label}</p>
            </div>
        ));
    };


    return (
        <Layout>
            <main className="main users">
                <Container>
                    <div className='mb-4'>
                        <Image onClick={() => navigate('/agent-property')} className='img-click' src={left} alt="img" />
                    </div>
                    <div className="add_property_sec py-3">
                        <div className="property_wrap">
                            <div className="heading_add py-3 py-lg-5">
                                <div className="heading_content">
                                    <h3>Property Details</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <section className="img__container">
                                        <h4>Gallery</h4>

                                        <div className="upload_sec py-3">
                                            {images.map((item, index) => (
                                                <div key={index} className="upload_images">
                                                    <Image src={item.img} alt="img" />
                                                </div>
                                            ))}
                                        </div>
                                    </section>
                                </div>

                                <div className="col-lg-9">
                                    <div className="addproperty_form">
                                        <div className="agent_property_details">
                                            {agentwalletdata.data.map((item, index) => (
                                                <div key={index} className="details_item">
                                                    <label>{item.title}</label>
                                                    <p>{item.label}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="addproperty_form">
                                        <PropertyDetailsSection data={agentwalletdata.datatwo} />
                                    </div>
                                    <div className="addproperty_form">
                                        <div className="agent_property_details">
                                            {agentwalletdata.datathree && renderPropertyDetails(agentwalletdata.datathree)}
                                        </div>
                                    </div>

                                    <div className="addproperty_form">
                                        <div className="agent_property_details">
                                            {agentwalletdata.datafour.map((item, index) => (
                                                <div key={index} className="details_item">
                                                    <label>{item.title}</label>
                                                    {item.labels && (
                                                        <ul className='p-0'>
                                                            {item.labels.map((label, i) => (
                                                                <li key={i} style={{color:"#010404"}}>
                                                                    {label.name}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="addproperty_form">
                                            <div className="agent_property_details">
                                                {agentwalletdata.datafive.map((item, index) => (
                                                    <div key={index} className="details_item">
                                                        <label>{item.title}</label>
                                                        <p>{item.label}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="regi_agent py-3">
                                            <label>Assigned to a Registered Agent</label>
                                            {agentwalletdata.datasix.map((item, index) => (
                                                <div key={index} className="py-3 agent_register">
                                                    <Image src={item.image} alt="img" />
                                                    <div className="olivia_name">
                                                        <span>{item.title}</span>
                                                        <p>{item.label}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </main>
        </Layout>
    );
};

export default AssignedPropertyDetails;