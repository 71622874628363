import React from 'react';
import { Container, Image } from 'react-bootstrap';
import Layout from '../../layout/layout';
import { tokenData, walletdata } from '../../../../common/constant/constant';
import added from '../../../../images/agent/added.png';

interface TokenItem {
    title: string;
    number: string;
    img: string;
}

interface WalletItem {
    reward: string;
    rewardData: string;
    activity: string;
    activeData: string;
    number: string;
}

const WalletComponent: React.FC = () => {
    return (
        <div>
            <Layout>
                <main className="main users tabel_page">
                    <Container>
                        <div className="wallet_sec">
                            <div className="tocken_block landload_tocken_block">
                                {tokenData.map((item: TokenItem, index: number) => (
                                    <div key={index} className="tocken_card">
                                        <div className="tocken_content">
                                            <p>{item.title}</p>
                                            <h3>{item.number}</h3>
                                        </div>
                                        <Image src={item.img} alt="tocken pic" />
                                    </div>
                                ))}

                                <p className="buy_more border-0" data-toggle="modal" data-target="#tocken_modal">
                                    <Image src={added} alt="img" />Buy More Tokens
                                </p>
                            </div>

                            <div className="history_sec">
                                <h6 style={{ color: "#5C677D" }}>History</h6>
                                <div className="history_block">
                                    {walletdata.map((item: WalletItem, index: number) => (
                                        <div key={index} className="history_card history_card_landload">
                                            <div className="h-box-1">
                                                <h5>{item.reward}</h5>
                                                <p>{item.rewardData}</p>
                                            </div>
                                            <div className="h-box-1">
                                                <h5>{item.activity}</h5>
                                                <p>{item.activeData}</p>
                                            </div>
                                            <div className="h-box-1">
                                                <h3 className={index === 2 ? 'text-red' : ''}> {item.number}</h3>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Container>
                </main>
            </Layout>

        </div>
    );
};

export default WalletComponent;
