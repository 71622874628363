import React, { useState } from "react";
import Sidebar from "./Sidebar";
import "../../agentpanel/styles/common.css"
import "../../customerpenal/styles/customer-sidebar.css";
import "../../agentpanel/styles/agent-style.css";
import "../../agentpanel/styles/agent-responsive.css";
import Header from "./Header";
import { Context } from "../../context/Context";


type ChildrenContainer = Pick<JSX.IntrinsicElements["div"], "children">;

function Layout({ children }: ChildrenContainer) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(false);

  const handleToggleClick = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Context.Provider value={{ sidebarCollapsed, handleToggleClick }}>
      <div className="page-flex">
        <Sidebar />
        <div className="main-wrapper">
          <Header />
          <main>{children}</main>
        </div>
      </div>
    </Context.Provider>
  );
}

export default Layout;
