import React from "react";
import { Row, Col, Card, Form, Image } from "react-bootstrap";
import graph from "../../../../images/customer/graph.png";
import { agentCardData, agentDashboardCard } from "../../../../common/constant/constant";
import blue from "../../../../images/customer/blue-dot.png";
import black from "../../../../images/customer/black-dot.png";
import Layout from "../../layout/layout";
import DateRange from "../../../../common/dateRangePicker/DateRange";

const AgentDashboard: React.FC = () => {
  return (
    <div>
      <Layout>
        <main className="main users px-4">
            <Row className="admin_dashboard">
              <Col className="dash_user_sec py-3 pb-3 pb-lg-5">
                {agentCardData.map((item, index) => (
                  <Card key={index} className="card_single border-0">
                    <Card.Body className="card_block p-0">
                      <div className="icon_sec">
                        <Image src={item.cardImg} alt="img" />
                        <p>{item.cardTitle}</p>
                      </div>
                      <h3>{item.cardNumber}</h3>
                    </Card.Body>
                    <div className="card_pic">
                      <Image src={item.img} alt="img" />
                      <p>
                        <span>{item.number}+</span> {item.discription}
                      </p>
                    </div>
                  </Card>
                ))}
                {agentDashboardCard.map((item, index) => (
                  <Card key={index} className="card_single border-0">
                    <Card.Body className="card_block p-0">
                      <div className="icon_sec">
                        <p>{item.cardTitle}</p>
                      </div>
                    </Card.Body>
                    <div className="card_pic">
                      <p>
                        <span className="span-card-agent">10,500</span>
                      </p>
                      <Image src={item.img} alt="img" />

                    </div>
                  </Card>
                ))}
              </Col>
            </Row>
          <div className="dash_graph px-3">
            <Row className="calender_c direction_f row">
              <Col>
                <h6 className="text-h6 text-tablet">Analytics</h6>
              </Col>
              <Col className="facility_dash direction_f col dimens-p ">
                <p className="text-p texts-p text-tablet">
                  Assigned Properties{" "}
                  <span>
                    <Image src={blue} alt="img" />
                  </span>
                </p>
                <p className="text-p texts-p  text-tablet">
                  Bookings{" "}
                  <span>
                    <Image src={black} alt="img" />
                  </span>
                </p>
              </Col>
              <Col className="customs_dash d-flex gap-3 range_landloard">
                <div>
                  <DateRange />
                </div>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    className="rounded-pill w-50 float-end drop-down-text drop-down-min select-text select_dropdown_landload"
                  >
                    <option value="1">Weekly</option>
                    <option value="2">Monthly</option>
                    <option value="3">Annually</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>

            <Image className="w-100" src={graph} alt="img" />
          </div>
          {/* add banner */}
          <div className="inner_banner_dash my-3 mx-3 ">
            <p>
              Ad <br />Banner
            </p>
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default AgentDashboard;
